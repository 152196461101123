import React, { useState, useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip, Modal, Form, Row, Col, Tab, Tabs } from 'react-bootstrap';
import Select from 'react-select';
import { IcoCanc, IcoEdit, IcoPlus, IcoMinusCircled, TooltipInfoIcon } from '@icons';
import {
   editCompany,
   deleteCompany,
   getCompanyBusinessLines,
   getCompanyBusinessAreas,
   createCompanyBusinessLine,
   createCompanyBusinessArea,
   editCompanyBusinessLine,
   editCompanyBusinessArea,
   deleteCompanyBusinessLine,
   deleteCompanyBusinessArea,
} from '@lib/api';
import UploadDocument from 'components/form/upload/uploadDocuments';
import OeApiResponseRenderer from '@components/OeApiResponseRenderer';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const ActionBtnRendererCompanies = ({ data, setRefresh, optionsGroups, optionsSizes, optionsRegions }) => {
   const id = data.id;
   const [company, setCompany] = useState({});
   const [businessLines, setBusinessLines] = useState([]);
   const [clusters, setClusters] = useState([]);
   const [originBusinessLines, setOriginBusinessLines] = useState([]);
   const [originClusters, setOriginClusters] = useState([]);
   const [selectedGroups, setSelectedGroups] = useState([]);
   const [selectedSize, setSelectedSize] = useState([]);
   const [showModalEdit, setShowModalEdit] = useState(false);
   const [showModalDelete, setShowModalDelete] = useState(false);
   const [sbShow, setSbShow] = useState(<></>);
   const [activeKey, setActiveKey] = useState('registry');
   const [pecIsInvalid, setPecIsInvalid] = useState(false);
   const pecRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/; // eslint-disable-line
   //console.log('company', company);

   useEffect(() => {
      const { groups, ...restData } = data;
      const newData = { groupids: groups, ...restData };
      setCompany(newData);

      if (showModalEdit) {
         getCompanyBusinessLines(id).then(retrievedBusinessLines => {
            setBusinessLines(retrievedBusinessLines.data);
            setOriginBusinessLines(retrievedBusinessLines.data);
         });

         getCompanyBusinessAreas(id).then(retrievedClusters => {
            let tempClusters = retrievedClusters.data.map(cluster => ({
               id: cluster.id,
               name: cluster.name,
               regions: cluster.regions.map(region => region.codRegione),
            }));

            setClusters(tempClusters);
            setOriginClusters(tempClusters);
         });

         setSelectedGroups(
            optionsGroups.filter(optionGroup => {
               return newData.groupids.some(group => {
                  return Number(optionGroup.value) === Number(group.id);
               });
            })
         );
      }
   }, [data, id, showModalEdit, optionsGroups]);

   useEffect(() => {
      setSelectedSize(optionsSizes.filter(option => option.value === company.sizeCode)
         .map(filteredOpt => ({
            value: filteredOpt.value,
            label: filteredOpt.label,
         }))
      );
   }, [company.sizeCode, optionsGroups, optionsSizes]);

   const handleCloseModal = (setShowModal) => {
      setCompany(data);
      setSbShow(<></>);
      setRefresh(true);
      setActiveKey('registry');
      setShowModal(false);
   };
   const handleShowModal = (setShowModal) => setShowModal(true);

   const handleStatusBar = (dataToHandle) => {
      setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
   };

   const onChange = (e, fieldName, setSelect, elIndex, list) => {
      console.log('e', e);

      let tempCompany = { ...company };
      let value;

      switch (fieldName) {
         case 'name':
         case 'vat':
         case 'pec':
         case 'address':
         case 'legalRepresentative':
         case 'attorney':
            value = e.target.value;

            tempCompany[fieldName] = value;
            break;
         case 'groupids':
         case 'sizeCode':
            if (fieldName === 'groupids') {
               value = e ?? [];
            } else {
               value = e.value ?? [];
            }

            tempCompany[fieldName] = value;

            setSelect(value);
            break;
         case 'businessLines':
         case 'clusters':
            let tempList = [...list];

            let tempElement = {
               ...tempList[elIndex],
               [e.target ? e.target.name : 'regions']: e.target ? e.target.value : (e.map(e => e.value) ?? []),
            };

            tempList[elIndex] = tempElement;

            setSelect(tempList); //as setList
            break;
         case 'powerOfAttorney':
            value = e.target.checked;

            tempCompany[fieldName] = value;

            if (e.target.checked === false) {
               tempCompany.attorney = '';
            }
            break;
         case 'docs':

            break;
         default:
            value = e.target.value;
            tempCompany[fieldName] = value;
      }

      setCompany(tempCompany);
   };

   const onBlur = (value, regex, setState) => {
      if (!value || (value && value.match(regex))) {
         setState(false);
      } else {
         setState(true);
      }
   };

   const handleEdit = async (e) => {
      e?.preventDefault();

      setSbShow(<></>);

      if (!company.code || !company.name || !company.vat || selectedGroups.length === 0 || 
         (company.powerOfAttorney && !company.attorney)) {
         handleStatusBar({
            success: false,
            message: translate('common.error.requiredFields', 'Compila i campi obbligatori'),
         });
         return;
      }

      if (company.pec && !company.pec.match(pecRegex)) {
         handleStatusBar({
            success: false,
            message: translate('common.companies.validpec', 'Inserisci un indirizzo pec valido'),
         });
         return;
      }

      if (company.groupids.length > 0) {
         company.groupids = company.groupids.map(group => group.value || group.id);
      }

      const { createdAt, createdBy, updatedAt, updatedBy, code, ...restCompany } = company;

      let result = await editCompany(restCompany);

      if (result.success) {
         let resultOfActions = [true];
         let resultOfMessages = [];

         let [businessLinesResult, clustersResult] = await Promise.all([
            handleItemList('businessLines', originBusinessLines, businessLines, deleteCompanyBusinessLine, editCompanyBusinessLine, createCompanyBusinessLine),
            handleItemList('clusters', originClusters, clusters, deleteCompanyBusinessArea, editCompanyBusinessArea, createCompanyBusinessArea),
         ]);

         const resultOfAll = resultOfActions.concat(businessLinesResult ? businessLinesResult.resultOfActionsTemp : [], clustersResult ? clustersResult.resultOfActionsTemp : []);
         const resultOfAllMessages = resultOfMessages.concat(businessLinesResult ? businessLinesResult.messageResult : [], clustersResult ? clustersResult.messageResult : []);
         console.log('resultOfAll', resultOfAll);
         console.log('resultOfAllMessages', resultOfAllMessages);

         if (resultOfAll.length === 0 || (resultOfAll.length > 0 && resultOfAll.every((el) => el === true))) {
            setTimeout(() => {
               getCompanyBusinessLines(id).then(retrievedBusinessLines => {
                  setBusinessLines(retrievedBusinessLines.data);
                  setOriginBusinessLines(retrievedBusinessLines.data);
               });

               getCompanyBusinessAreas(id).then(retrievedClusters => {
                  let tempClusters = retrievedClusters.data.map(cluster => ({
                     id: cluster.id,
                     name: cluster.name,
                     regions: cluster.regions.map(region => region.codRegione),
                  }));

                  setClusters(tempClusters);
                  setOriginClusters(tempClusters);
               });
            }, 500);

            handleStatusBar({
               success: true,
               message: translate('common.message.edited', 'Elemento modificato con successo'),
            });
         } else {
            handleStatusBar(resultOfAllMessages);
         }

         const { groups, ...restResult } = result.data;
         const newResult = { groupids: groups, ...restResult };

         //console.log('newResult', newResult);
         setCompany(newResult);
      } else {
         handleStatusBar(result);
      }
   };

   const handleItemList = async (entityType, originItemList, itemList, deleteAPI, editAPI, createAPI) => {
      let resultOfActionsTemp = [];

      const oldItemsDeleted = originItemList.filter(object1 => {
         return !itemList.some(object2 => {
            return Number(object1.id) === Number(object2.id);
         });
      });

      if (oldItemsDeleted.length > 0) {
         oldItemsDeleted.forEach(async item => {
            const deleteResult = await deleteAPI(id, item.id);
            resultOfActionsTemp.push(deleteResult.success);
         });
      }

      let validationFields = [];

      switch (entityType) {
         case 'businessLines':
            validationFields = ['name']
            break;
         default:
            validationFields = [];
      }

      let result;
      let messageResult = [];

      await Promise.all(itemList.map(async item => {
         const resultOfEvery = validationFields.every(field => item[field] || (Array.isArray(item[field]) && item[field].every(el => el)));
         //console.log('resultOfEvery', resultOfEvery);

         if (resultOfEvery) {
            /* if (entityType === 'clusters') {
               item.regions = item.regions.map(region => region.value)
            } */

            if (item.id) {
               const { createdAt, createdBy, updatedAt, updatedBy, notice, ...restItem } = item;
               result = await editAPI(id, restItem);
               //console.log('result edit:', result);
               if (result.success === false) messageResult = result;
               resultOfActionsTemp.push(result.success);
            } else {
               result = await createAPI(id, item);
               if (result.success === false) messageResult = result;
               resultOfActionsTemp.push(result.success);
            }
         } else {
            //console.log('non riempiti');
            messageResult = { success: false, data: { validation: [`${entityType} ${translate('common.error.notsaved', 'non salvati')}`] } };
            resultOfActionsTemp.push(false);
            return;
         }
      }));

      //console.log('messageResult', messageResult);
      return { resultOfActionsTemp, messageResult };
   };

   const handleDelete = async () => {
      const deleteResult = await deleteCompany(id);

      if (deleteResult.success) {
         handleStatusBar({
            success: true,
            message: translate('common.message.deleted', 'Elemento eliminato correttamente'),
         });

         setTimeout(() => {
            setShowModalDelete(false);
            setRefresh(true);
         }, 2000);
      } else {
         handleStatusBar(deleteResult);
      }
   };

   const editTooltip = (props) => (
      <Tooltip {...props}>
         <OETrans code='common.tooltip.edit' fallback={'Modifica'}/>
      </Tooltip>
   );
   const deleteTooltip = (props) => (
      <Tooltip {...props}>
         <OETrans code='common.tooltip.delete' fallback={'Elimina'}/>
      </Tooltip>
   );

   const addElement = (section, typeArray, setTypeArray) => {
      let tempTypeArray = [...typeArray];

      let objTypeArray = {};

      switch (section) {
         case 'businessLines':
            objTypeArray = { name: '' }
            break;
         case 'cluster':
            objTypeArray = { name: '', regions: [] }
            break;
         default:
            objTypeArray = {};
      }

      tempTypeArray.push(objTypeArray);

      setTypeArray(tempTypeArray);
   };

   const removeElement = (typeArray, setTypeArray, index) => {
      let tempTypeArray = [...typeArray];

      tempTypeArray.splice(index, 1);

      setTypeArray(tempTypeArray);
   };

   const businessLinesRows = (businessLines, setBusinessLines) => {
      let rows = [];

      if (businessLines) {
         if (businessLines.length === 0) {
            businessLines = [{ name: '' }];
         }

         //console.log('businessLines', businessLines);
         let lastIndex = businessLines.length - 1;

         rows = businessLines.map((el, i) => {
            const isThisRowFilled = el.name ? true : false;

            return (
               <Row className='align-items-center mb-3' key={'item-' + i}>
                  <Col className='col-9'>
                     <Form.Control
                        type='text'
                        name='name'
                        placeholder={translate('common.placeholder.businesslines', 'Business lines')}
                        value={el.name ?? ''}
                        onChange={e => onChange(e, 'businessLines', setBusinessLines, i, businessLines)}
                     />
                  </Col>
                  <Col className="ps-1">
                     {i === lastIndex ? (
                        <Button
                           variant='link'
                           onClick={() => addElement('businessLines', businessLines, setBusinessLines)}
                           disabled={!isThisRowFilled}
                        >
                           <IcoPlus className='second-color ico-small indicators' />
                        </Button>
                     ) : null}
                     {businessLines.length !== 1 ? (
                        <Button
                           variant='link'
                           className='me-1'
                           onClick={() => removeElement(businessLines, setBusinessLines, i)}>
                           <IcoMinusCircled className='red-color ico-small indicators' />
                        </Button>
                     ) : null}
                  </Col>
               </Row>
            );
         });
      }

      return rows;
   };

   const clusterRows = (clusters, setClusters, options) => {
      let rows = [];

      if (clusters) {
         if (clusters.length === 0) {
            clusters = [{ name: '', regions: [] }];
         }

         //console.log('clusters', clusters);
         let lastIndex = clusters.length - 1;

         rows = clusters.map((el, i) => {
            const isThisRowFilled = el.name && el.regions?.length > 0 ? true : false;

            return (
               <Row className='align-items-center mb-3' key={i}>
                  <Col className="col-5 align-self-start">
                     <Form.Control
                        type='text'
                        name='name'
                        placeholder={translate('common.placeholder.denomination', 'Denominazione')}
                        value={el.name ?? ''}
                        onChange={e => onChange(e, 'clusters', setClusters, i, clusters)}
                     />
                  </Col>
                  <Col className=''>
                     <Select
                        components={{
                           IndicatorSeparator: () => null,
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        closeMenuOnSelect={false}
                        name="regions"
                        placeholder={translate('common.placeholder.selectregions', 'Seleziona regioni') + '...'}
                        value={options.filter(opt => {
                           return el.regions?.some(region => {
                              return opt.value === region;
                           });
                        }) ?? []}
                        options={options}
                        isMulti
                        isClearable
                        onChange={e => onChange(e, 'clusters', setClusters, i, clusters)}
                     />
                  </Col>
                  <Col className='col-auto p-0' style={{ width: '5rem' }}>
                     {clusters.length !== 1 ? (
                        <Button
                           variant='link'
                           className='me-1'
                           onClick={() => removeElement(clusters, setClusters, i)}>
                           <IcoMinusCircled className='red-color ico-small indicators' />
                        </Button>
                     ) : null}

                     {i === lastIndex ? (
                        <Button
                           variant='link'
                           onClick={() => addElement('clusters', clusters, setClusters)}
                           disabled={!isThisRowFilled}
                        >
                           <IcoPlus className='second-color ico-small indicators' />
                        </Button>
                     ) : null}
                  </Col>
               </Row>
            );
         });
      }

      return rows;
   };

   return (
      <div>
         <OverlayTrigger placement='top' overlay={editTooltip}>
            <Button variant='link' className='me-3' onClick={() => handleShowModal(setShowModalEdit)}>
               <IcoEdit className='second-color ico-small edit' />
            </Button>
         </OverlayTrigger>
         <OverlayTrigger placement='top' overlay={deleteTooltip}>
            <Button
               variant='link'
               className='me-0'
               onClick={() => handleShowModal(setShowModalDelete)}>
               <IcoCanc className='second-color ico-small cancel' />
            </Button>
         </OverlayTrigger>

         {showModalEdit &&
            <Modal size='xl' dialogClassName="modal-75w" className='modal-with-nav' show={showModalEdit} onHide={() => handleCloseModal(setShowModalEdit)} centered>
               <Modal.Header closeButton>
                  <Modal.Title>
                     <OETrans code='common.companies.editcompany' fallback={'Modifica società'}/>
                  </Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <Tabs className='mb-4' activeKey={activeKey} onSelect={(k) => setActiveKey(k)} fill>
                     {/* Anagrafica */}
                     <Tab eventKey='registry' title={translate('common.registry', 'Anagrafica')} className='px-5'>
                        <Form onSubmit={handleEdit} id='form-new-company'>
                           <Row className='mb-3' style={{ marginTop: '2rem' }}>
                              <Form.Group as={Col} className='pe-2' style={{ maxWidth: '10rem' }}>
                                 <Form.Label className='mb-3'>
                                    <OETrans code='companies.code' fallback={'Codice'}/>
                                    <OverlayTrigger
                                       placement='top'
                                       delay={{ show: 200, hide: 400 }}
                                       overlay={
                                          <Tooltip id='button-tooltip'>
                                             <Tooltip id='button-tooltip'>
                                                <OETrans code='common.codemustcontain' fallback={'Il codice deve rispettare la seguente forma'} />:   
                                                <ul style={{ textAlign: 'left', paddingLeft: '20px', margin: 'auto' }}>
                                                   <li><OETrans code='common.minimumcharacters' fallback={'un minimo di 4 ed un massimo di 10 caratteri'}/>,</li>
                                                   <li><OETrans code='common.nospace' fallback={'nessuno spazio'}/>,</li>
                                                   <li><OETrans code='common.nospecialcharacters' fallback={'nessun carattere speciale'}/>.</li>
                                                </ul>
                                             </Tooltip>
                                          </Tooltip>
                                       }>
                                       <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                                    </OverlayTrigger>
                                    &nbsp;<span className='required-field'>*</span>
                                 </Form.Label>
                                 <Form.Control
                                    type='text'
                                    name='code'
                                    maxLength={10}
                                    minLength={4}
                                    value={company.code ?? ''}
                                    onChange={e => onChange(e, 'code')}
                                    disabled={true}
                                 />
                              </Form.Group>

                              <Form.Group as={Col} className='pe-2'>
                                 <Form.Label className='mb-3'>
                                    <OETrans code='companies.name' fallback={'Nome'}/>
                                    &nbsp;<span className='required-field'>*</span>
                                 </Form.Label>
                                 <Form.Control
                                    type='text'
                                    name='name'
                                    value={company.name}
                                    onChange={e => onChange(e, 'name')}
                                 />
                              </Form.Group>

                              <Form.Group as={Col} className='pe-2' style={{ maxWidth: '16.5rem' }}>
                                 <Form.Label className='mb-3'>
                                    <OETrans code='companies.vat' fallback={'Partita IVA'}/>
                                    &nbsp;<span className='required-field'>*</span>
                                 </Form.Label>
                                 <Form.Control
                                    type='text'
                                    name='vat'
                                    value={company.vat}
                                    onChange={e => onChange(e, 'vat')}
                                 />
                              </Form.Group>

                              <Form.Group as={Col} className='pe-2' style={{ maxWidth: '16.5rem' }}>
                                 <Form.Label className='mb-3'>
                                    <OETrans code='companies.groupids' fallback={'Gruppi'}/>
                                    &nbsp;<span className='required-field'>*</span>
                                 </Form.Label>
                                 <Select
                                    components={{
                                       IndicatorSeparator: () => null,
                                    }}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    closeMenuOnSelect={false}
                                    name="groupids"
                                    placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                    value={selectedGroups}
                                    options={optionsGroups}
                                    isMulti
                                    isClearable
                                    onChange={e => onChange(e, 'groupids', setSelectedGroups)}
                                 />
                              </Form.Group>

                              <Form.Group as={Col} className='pe-2' style={{ maxWidth: '16.5rem' }}>
                                 <Form.Label className='mb-3'>
                                    <OETrans code='companies.sizeCode' fallback={'Dimensione'}/>
                                 </Form.Label>
                                 <Select
                                    components={{
                                       IndicatorSeparator: () => null,
                                    }}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="sizeCode"
                                    placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                    value={selectedSize}
                                    options={optionsSizes}
                                    onChange={e => onChange(e, 'sizeCode', setSelectedSize)}
                                 />
                              </Form.Group>
                           </Row>

                           <Row className='mt-5 mb-3'>
                              <Form.Group as={Col} className='pe-2'>
                                 <Form.Label className='mb-3'>
                                    <OETrans code='companies.pec' fallback={'Pec'}/>
                                 </Form.Label>
                                 <Form.Control
                                    type='text'
                                    name='pec'
                                    value={company.pec ?? ''}
                                    onChange={e => onChange(e, 'pec')}
                                    onBlur={(e) => onBlur(e.target.value, pecRegex, setPecIsInvalid)}
                                    isInvalid={pecIsInvalid}
                                 />
                                 <Form.Control.Feedback type="invalid" className="ps-1 position-absolute">
                                    {translate('common.error.pec', 'pec non valida').toLowerCase()}
                                 </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group as={Col} className='pe-2'>
                                 <Form.Label className='mb-3'>
                                    <OETrans code='companies.address' fallback={'Sede legale'}/>
                                 </Form.Label>
                                 <Form.Control
                                    type='text'
                                    name='address'
                                    value={company.address ?? ''}
                                    onChange={e => onChange(e, 'address')}
                                 />
                              </Form.Group>

                              <Form.Group as={Col} className='pe-2'>
                                 <Form.Label className='mb-3'>
                                    <OETrans code='companies.legalRepresentative' fallback={'Rappresentante legale'}/>
                                 </Form.Label>
                                 <Form.Control
                                    type='text'
                                    name='legalRepresentative'
                                    placeholder="nome cognome"
                                    value={company.legalRepresentative ?? ''}
                                    onChange={e => onChange(e, 'legalRepresentative')}
                                 />
                              </Form.Group>

                              <Form.Group as={Col} className='pe-2'>
                                 <Form.Label className='mb-3'>
                                    <OETrans code='companies.powerOfAttorney' fallback={'Procuratore'}/>
                                    <Form.Check
                                       inline
                                       style={{ marginTop: '0.2rem' }}
                                       className='ms-3'
                                       type='checkbox'
                                       name='powerOfAttorney'
                                       defaultChecked={company.powerOfAttorney}
                                       onClick={e => onChange(e, 'powerOfAttorney')}
                                    />
                                 </Form.Label>
                                 <Form.Control
                                    type='text'
                                    name='attorney'
                                    value={company.attorney ?? ''}
                                    placeholder={company.powerOfAttorney ? translate('common.namesurname', 'Nome Cognome') : ''}
                                    onChange={e => onChange(e, 'attorney')}
                                    disabled={!company.powerOfAttorney}
                                 />
                              </Form.Group>
                           </Row>

                           <Row>
                              <Form.Group as={Col} className='px-3 col-4 border-tab right'>
                                 <Form.Label className='mt-4 mb-3 me-1'>
                                    <OETrans code='companies.businessLines' fallback={'Business lines'}/>
                                 </Form.Label>
                                 {businessLinesRows(businessLines, setBusinessLines)}
                              </Form.Group>

                              <Form.Group as={Col} className='px-3 col-8'>
                                 <Form.Label className='mt-4 mb-3 me-1'>
                                    <OETrans code='companies.geographicClusters' fallback={'Cluster geografici'}/>
                                 </Form.Label>
                                 {clusterRows(clusters, setClusters, optionsRegions)}
                              </Form.Group>
                           </Row>
                        </Form>
                        {sbShow}
                     </Tab>

                     {/* Documenti */}
                     <Tab eventKey='docs' title={<OETrans code='common.documents' fallback={'Documenti'} />} className='px-5'>
                        <UploadDocument entity='companies' entityId={company.id} />
                        {sbShow}
                     </Tab>
                  </Tabs>

                  <Row className='mt-5 mb-2'>
                     <span className='mx-5'>
                        <span className='required-field'>*</span>
                        &nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/>
                     </span>
                  </Row>
               </Modal.Body>

               <Modal.Footer>
                  <Button variant='secondary' onClick={() => handleCloseModal(setShowModalEdit)}>
                     <OETrans code='common.cancel' fallback={'Annulla'}/>
                  </Button>
                  <Button variant='primary' onClick={e => handleEdit(e)} disabled={activeKey === 'docs'}>
                     <OETrans code='common.edit' fallback={'Modifica'}/>
                  </Button>
               </Modal.Footer>
            </Modal>
         }

         <Modal show={showModalDelete} onHide={() => handleCloseModal(setShowModalDelete)} centered>
            <Modal.Header closeButton>
               <Modal.Title>
                  <OETrans code='common.warning' fallback={'Attenzione'}/>
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className='my-3'>
                  <span>
                     <OETrans code='common.warning.delete' fallback={'Sei sicuro di voler eliminare questo elemento?'} />
                  </span>
               </div>
               {sbShow}
            </Modal.Body>
            <Modal.Footer>
               <Button variant='secondary' onClick={() => handleCloseModal(setShowModalDelete)}>
                  <OETrans code='common.cancel' fallback={'Annulla'}/>
               </Button>
               <Button variant='danger' onClick={() => handleDelete()}>
                  <OETrans code='common.delete' fallback={'Elimina'}/>
               </Button>
            </Modal.Footer>
         </Modal>
      </div>
   );
};

export default ActionBtnRendererCompanies;