import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  ButtonToolbar,
  Spinner,
  Button,
  Modal,
  Row,
  Col,
  Form,
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import { InputNumber } from "primereact";
import { IcoPlus, IcoMinusCircled } from "@icons";
import {
  getTenders,
  getCompanies,
  getTenderProcedures,
  getTenderClassifications,
  getTenderCriteria,
  createTender,
  editTender,
  getTenderCigs,
  createTenderCig,
  editTenderCig,
  deleteTenderCig,
  downloadTendersDocument,
  downloadTendersChecklistsDocument,
  downloadTendersChecklistsCQDocument,
  getQuestionSets,
  editQuestionSetByEntity,
} from "@lib/api";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import AG_GRID_LOCALE_IT from "@components/ag-grid/locale-it";
import ActionBtnRendererTenders from "components/actionBtnRenderer/ActionBtnRendererTenders";
import CheckList from "@components/checklist/checkList";
import UploadDocument from "components/form/upload/uploadDocuments";
import MassiveUpload from "components/form/upload/massiveUpload";
import MassiveUploadTendersList from "components/form/upload/massiveUploadTendersList";
import { dateFormatterInput } from "@lib/helpers/formatting";
import OeApiResponseRenderer from "components/OeApiResponseRenderer";
import DownloadExcelButtons from "@components/form/DownloadExcelButtons";
import CustomPaginationMui from "components/materialComponents/CustomPaginationMui";
import CustomQuickFilterBox from "components/ag-grid/CustomQuickFilterBox";
import { translate } from "components/translation/translation";
import { OETrans } from "components/translation/OETrans";

const initialState = {
  companyId: "",
  tenderNumber: "",
  subject: "",
  import: "",
  callDate: "",
  assignmentDate: "",
  officialGazettePubItaly: false,
  officialGazettePubEurope: false,
  dedicatedPnrr: false,
  tenderClassification: "",
  assignmentProcedureCode: "",
  assignmentCriteriaCode: "",
  qualificationSystem: "",
  referenceAct: "",
  auctionImport: "",
  totalImport: "",
  questionsetId: null,
};

const TendersList = () => {
  const [tenders, setTenders] = useState(null);
  const [tendersMeta, setTendersMeta] = useState({});
  const [newTender, setNewTender] = useState(initialState);
  const [cigs, setCigs] = useState([]);
  const [originCigs, setOriginCigs] = useState([]);
  const [optionsCompanies, setOptionsCompanies] = useState([]);
  const [optionsProcedures, setOptionsProcedures] = useState([]);
  const [optionsClassifications, setOptionsClassifications] = useState([]);
  const [optionsCriteria, setOptionsCriteria] = useState([]);
  const [optionsQuestionSet, setOptionsQuestionSet] = useState([]);
  const [selectedQuestionSet, setSelectedQuestionSet] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState([]);
  const [selectedClassification, setSelectedClassification] = useState([]);
  const [selectedCriteria, setSelectedCriteria] = useState([]);
  const [activeKey, setActiveKey] = useState("tenders");
  const [subActiveKey, setSubActiveKey] = useState("registry");
  const [columnDefs, setColumnDefs] = useState([]);
  const [showModalNew, setShowModalNew] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isDownloadingTenders, setIsDownloadingTenders] = useState(false);
  const [isDownloadingCL, setIsDownloadingCL] = useState(false);
  const [isDownloadingCLCq, setIsDownloadingCLCq] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isFirstResearch, setIsFirstResearch] = useState(true);
  const gridRef = useRef();
  const localeText = AG_GRID_LOCALE_IT;
  const localLang = `${localStorage
    .getItem("userLanguage")
    .toLowerCase()}-${localStorage.getItem("userLanguage")}`;
  //console.log('tenders', tenders);
  //console.log('originCigs', originCigs);

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    setSubActiveKey("registry");
    setShowModal(false);
    setRefresh(true);
    setNewTender(initialState);
    setCigs([]);
    setOriginCigs([]);
    setSelectedCompany([]);
    setSelectedProcedure([]);
    setSelectedClassification([]);
    setSelectedCriteria([]);
    setSelectedQuestionSet([]);
  };

  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (dataToHandle) => {
    setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
  };

  useEffect(() => {
    getTenders('?companyDefault=0', 'tenders').then((retrievedTenders) => {
      if (retrievedTenders.success) {
        setTenders(retrievedTenders.data);
        const { data, success, ...restResp } = retrievedTenders;
        setTendersMeta(restResp);
      }
    });

    getCompanies().then((retrievedCompanies) => {
      setOptionsCompanies(
        retrievedCompanies.data.map((company) => ({
          value: company.id,
          label: company.name,
        }))
      );
    });

    getTenderProcedures().then((retrievedProcedures) => {
      setOptionsProcedures(
        retrievedProcedures.data.map((procedure) => ({
          value: procedure.code,
          label: procedure.name,
        }))
      );
    });

    getTenderClassifications().then((retrievedClassification) => {
      setOptionsClassifications(
        retrievedClassification.data.map((classification) => ({
          value: classification.code,
          label: classification.name,
        }))
      );
    });

    getTenderCriteria().then((retrievedCriteria) => {
      setOptionsCriteria(
        retrievedCriteria.data.map((criteria) => ({
          value: criteria.code,
          label: criteria.name,
        }))
      );
    });

    getQuestionSets(undefined, false).then((retrievedQuestionsSets) => {
      if (retrievedQuestionsSets.success) {
         const questionsNoticesSets = retrievedQuestionsSets.data.filter(el => el.legacyType === 'tenders');

         setOptionsQuestionSet(
            questionsNoticesSets.map((set) => ({
              value: set.id,
              label: set.description ?? set.name,
            }))
          );
      }
    });

    //RIATTIVARE QUANDO L'ATTO SARà CONFIGURATO
    /* getTenderReferenceAct().then((retrievedReference) => {
             setOptionsReferenceActs(
                retrievedReference.data.map((reference) => ({
                   value: reference.code,
                   label: reference.name,
                }))
             );
          }); */

    /* setOptionsPagination([
           <Fragment key='options'>
              <option defaultValue='100' key='100'>
                 100
              </option>
              <option value='500' key='500'>
                 500
              </option>
              <option value='1000' key='1000'>
                 1000
              </option>
           </Fragment>,
        ]); */
  }, []);

  useEffect(() => {
    if (refresh) {
      getTenders('?companyDefault=0', 'tenders').then((retrievedTenders) => {
        if (retrievedTenders.success) {
          setTenders(retrievedTenders.data);
          const { data, success, ...restResp } = retrievedTenders;
          setTendersMeta(restResp);
        }
      });

      setRefresh(false);
    }
  }, [refresh]);

  const onGridReady = useCallback(() => {
    getTenders('?companyDefault=0', 'tenders').then((retrievedTenders) => {
      if (retrievedTenders.success) {
        setTenders(retrievedTenders.data);
        const { data, success, ...restResp } = retrievedTenders;
        setTendersMeta(restResp);
      }
    });
  }, []);

  const defaultColDef = {
    resizable: true,
    wrapText: true,
    autoHeight: true,
    flex: 1,
    suppressMovable: true,
    cellStyle: { padding: "0 1rem" },
  };

  const ChecklistCellRenderer = useCallback((params) => {
    const stoplightColor = {
      red: "#C94723",
      yellow: "#D4BE31",
      green: "#689689",
      white: "#ffffff",
      gray: "#C4C4C4",
      grey: "#C4C4C4",
      green_white: "#689689",
    };

    let circleStyle;
    let message = "";

    if (!params?.value?.color) {
      circleStyle = {
        display: "inherit",
        width: "1.2em",
        height: "1.2em",
        borderRadius: "2em",
        MozBorderRadius: "2em",
        WebkitBorderRadius: "2em",
        backgroundColor: "#ffffff",
        border: `2px solid ${"#C4C4C4"}`,
      };

      message = translate('common.stoplight.towork', 'Da lavorare');
    } else {
      if (params?.value?.color === "green_white") {
        circleStyle = {
          display: "inherit",
          width: "23px",
          height: "23px",
          border: "4px solid #689689",
          borderRadius: "50%",
          padding: "0px",
          background: "linear-gradient(to right, #689689 50%, transparent 0)",
          boxSizing: "border-box",
        };

        message = translate('common.stoplight.compliantremedied', 'Conforme - sanata');
      } else {
        circleStyle = {
          display: "inherit",
          width: "1.2em",
          height: "1.2em",
          borderRadius: "2em",
          MozBorderRadius: "2em",
          WebkitBorderRadius: "2em",
          backgroundColor: stoplightColor[params?.value?.color],
          border: `2px solid ${
            params?.value?.color === "white"
              ? stoplightColor.grey
              : stoplightColor[params?.value?.color]
          }`,
        };

        switch (params?.value?.color) {
          case "white":
            message = translate('common.stoplight.towork', 'Da lavorare');
            break;
          case "grey":
          case "gray":
            message = translate('common.stoplight.inprogress', 'In lavorazione');
            break;
          case "red":
            message = translate('common.stoplight.notcompliant', 'Non conforme');
            break;
          case "yellow":
            message = translate('common.stoplight.incomplete', 'Incompleta');
            break;
          case "green":
            message = translate('common.stoplight.compliant', 'Conforme');
            break;
          default:
            break;
        }
      }
    }

    return (
      <Row className="align-items-center justify-content-center h-100">
        <Col className="col-auto">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{message}</Tooltip>}
          >
            <i style={{ ...circleStyle }} />
          </OverlayTrigger>
        </Col>
      </Row>
    );
  }, []);

  useEffect(() => {
    setColumnDefs([
      {
        headerName: translate('common.tenders.basicinfo', 'Informazioni di base'),
        marryChildren: true,
        children: [
          {
            field: "tenderNumber",
            headerName: translate('tenders.tenderNumber', 'Nr. Gara'),
            filter: "agTextColumnFilter",
            resizable: false,
          },
          {
            field: "companyId",
            headerName: translate('tenders.companyId', 'Società'),
            filter: "agTextColumnFilter",
            valueGetter: (params) => {
              return optionsCompanies
                .filter((company) => company.value === params.data.companyId)
                .map((company) => company.label);
            },
            filterValueGetter: (params) => {
              return optionsCompanies
                .filter((company) => company.value === params.data.companyId)
                .map((company) => company.label);
            },
          },
        ],
      },
      {
        headerName: translate('tenders.assignmentProcedureCode', 'Tipologia procedura'),
        marryChildren: true,
        resizable: false,
        children: [
          {
            field: "assignmentProcedureCode",
            headerName: "",
            filter: "agTextColumnFilter",
            valueGetter: (params) => {
              return optionsProcedures
                .filter(
                  (optProcedure) =>
                    optProcedure.value === params.data.assignmentProcedureCode
                )
                .map((procedure) => procedure.label);
            },
            filterValueGetter: (params) => {
              return optionsProcedures
                .filter(
                  (optProcedure) =>
                    optProcedure.value === params.data.assignmentProcedureCode
                )
                .map((procedure) => procedure.label);
            },
          },
        ],
      },
      {
        headerName: translate('tenders.tenderclassification', 'Classificazione gara'),
        marryChildren: true,
        resizable: false,
        children: [
          {
            field: "tenderClassification",
            headerName: "",
            filter: "agTextColumnFilter",
            valueGetter: (params) => {
              return optionsClassifications
                .filter(
                  (optionClassification) =>
                    optionClassification.value ===
                    params.data.tenderClassification
                )
                .map((classification) => classification.label);
            },
            filterValueGetter: (params) => {
              return optionsClassifications
                .filter(
                  (optionClassification) =>
                    optionClassification.value ===
                    params.data.tenderClassification
                )
                .map((classification) => classification.label);
            },
          },
        ],
      },
      /* {
                  headerName: 'Nr. Gara',
                  field: 'tenderNumber',
                  filter: 'agTextColumnFilter',
               },
               {
                  headerName: 'Società',
                  field: 'companyId',
                  filter: 'agTextColumnFilter',
                  valueGetter: (params) => {
                     return optionsCompanies
                        .filter((company) => company.value === params.data.companyId)
                        .map((company) => company.label);
                  },
               }, */
      {
        headerName: "",
        resizable: false,
        field: "checkliststatus",
        filter: "agTextColumnFilter",
        cellRenderer: ChecklistCellRenderer,
        maxWidth: 70,
        cellStyle: { display: "flex", justifyContent: "center" },
        filterValueGetter: (params) => {
          //console.log("params", params.data.checkliststatus);
          let result = "";

          if (params.data.checkliststatus) {
            switch (params.data.checkliststatus.color) {
              case "red":
                result = "rosso";
                break;
              case "yellow":
                result = "giallo";
                break;
              case "green":
                result = "verde";
                break;
              case "gray":
              case "grey":
                result = "grigio";
                break;
              case "white":
                result = "bianco";
                break;
              default:
                result = "bianco";
                break;
            }
          } else {
            result = "rosso";
          }

          return result;
        },
      },
      {
        headerName: "",
        resizable: false,
        minWidth: 450,
        maxWidth: 450,
        cellStyle: { display: "flex", justifyContent: "center", padding: 0 },
        cellRenderer: ActionBtnRendererTenders,
        cellRendererParams: {
          setRefresh: setRefresh,
          optionsCompanies: optionsCompanies,
          optionsProcedures: optionsProcedures,
          optionsClassifications: optionsClassifications,
          optionsCriteria: optionsCriteria,
          optionsQuestionSet: optionsQuestionSet,
          localLang: localLang,
        },
      },
    ]);
  }, [
    optionsCompanies,
    optionsProcedures,
    optionsCriteria,
    optionsClassifications,
    optionsQuestionSet,
    ChecklistCellRenderer,
    localLang,
  ]);

  const onChange = (e, fieldName, setSelect, elIndex, list) => {
    //console.log('e:', e);

    let tempNewTender = { ...newTender };
    let value;

    switch (fieldName) {
      case "tenderNumber":
      case "subject":
      case "qualificationSystem":
      case "callDate":
      case "assignmentDate":
      case "referenceAct":
        value = e.target.value;

        tempNewTender[fieldName] = value;
        break;
      case "companyId":
      case "assignmentProcedureCode":
      case "tenderClassification":
      case "assignmentCriteriaCode":
        value = e ? e.value : "";

        tempNewTender[fieldName] = value;

        setSelect(e);

        tempNewTender[fieldName] = value;
        break;
      case "import":
      case "auctionImport":
      case "totalImport":
        value = Number(e.value);

        tempNewTender[fieldName] = value;
        break;
      case "cigs":
        let tempList = [...list];

        let tempElement = {
          ...tempList[elIndex],
          [e.target.name]: e.target.value,
          tenderId: newTender.id,
        };

        tempList[elIndex] = tempElement;

        //console.log('tempList', tempList);

        setSelect(tempList); //as setList
        break;
      case "dedicatedPnrr":
      case "officialGazettePubItaly":
      case "officialGazettePubEurope":
        value = e.target.checked;
        tempNewTender[fieldName] = value;

        break;
      case "questionsetId":
         value = e ? e.value : null;
         tempNewTender[fieldName] = value;

         setSelect(e);
         break;
      default:
        value = e.target.value;
        tempNewTender[fieldName] = value;
    }

    setNewTender(tempNewTender);
  };

  const handleSave = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    const {
      callDate,
      assignmentDate,
      dnsh,
      genderEquality,
      conflictOfInterest,
      other,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      ...restNewTender
    } = newTender;

    if (!restNewTender.companyId || !restNewTender.tenderNumber || !restNewTender.questionsetId) {
      handleStatusBar({
        success: false,
        message: translate('common.error.requiredFields', 'Compila i campi obbligatori'),
      });
      return;
    }

    const isCigsFilled = cigs.length > 0 ? true : false;

    const set = new Set();
    const isNotNameCigsUnique = cigs.some(
      (object) => set.size === (set.add(object.cig), set.size)
    );
    //console.log('isNotNameCigsUnique', isNotNameCigsUnique);
    //console.log('restNewTender', restNewTender);

    if (!isCigsFilled) {
      handleStatusBar({
        success: false,
        message: translate('common.error.cigbepresent', 'Deve essere presente almeno un CIG'),
      });
      return;
    }

    if (isNotNameCigsUnique) {
      handleStatusBar({
        success: false,
        message: translate('common.error.notsameCIG', 'I codici CIG non possono essere uguali'),
      });
      return;
    }

    let result;

    setShowSpinner(true);

    if (newTender.id) {
      const { createdAt, createdBy, updatedAt, updatedBy, ...restTender } =
        newTender;
      result = await editTender(restTender);
      //console.log('result', result);

      if (result.success) {
         let resultOfActions = [true];

         let questionSetsResult;
         let questionSetsResultBool;

         if (selectedQuestionSet.length > 0 && Number(selectedQuestionSet.map(el => el.value).join())) {
            questionSetsResult = await editQuestionSetByEntity('tenders', newTender.id, newTender.questionsetId);
            questionSetsResultBool = questionSetsResult.success;

            if (!questionSetsResultBool) {
               handleStatusBar(questionSetsResult);
               return
            }
         }
         
        setNewTender(result.data);

        let [cigsResults] = await Promise.all([
          handleItemList(
            "cigs",
            originCigs,
            cigs,
            deleteTenderCig,
            editTenderCig,
            createTenderCig,
            newTender.id
          ),
        ]);
        //console.log("cigsResults", cigsResults);

        const resultOfAll = resultOfActions.concat(
          resultOfActions,
          cigsResults.resultOfActions,
          [questionSetsResultBool]
        );
        //console.log('resultOfAll', resultOfAll);

        if (resultOfAll.length > 0 && resultOfAll.every((el) => el === true)) {
          getTenderCigs(result.data.id).then((retrievedCigs) => {
            setCigs(retrievedCigs.data);
            setOriginCigs(retrievedCigs.data);
          });

          handleStatusBar({
            success: true,
            message: translate('common.message.edited', 'Elemento modificato con successo'),
          });
        } else {
          handleStatusBar(cigsResults.messageResult);
        }
      } else {
        handleStatusBar(result);
      }
    } else {
      result = await createTender(newTender);

      if (result.success) {
         let questionSetsResult;
         let questionSetsResultBool;

         if (selectedQuestionSet.length > 0 && Number(selectedQuestionSet.map(el => el.value).join())) {
            questionSetsResult = await editQuestionSetByEntity('tenders', result.data.id, newTender.questionsetId);
            questionSetsResultBool = questionSetsResult.success;

            if (!questionSetsResultBool) {
               handleStatusBar(questionSetsResult);
               return
            }
         }

        handleItemList(
          "cigs",
          originCigs,
          cigs,
          deleteTenderCig,
          editTenderCig,
          createTenderCig,
          result.data.id
        ).then((cigsResults) => {
          //console.log('cigsResults', cigsResults);
          setNewTender(result.data);
          //console.log("cigResu", cigsResults);
          if (
            cigsResults.resultOfActions.length > 0 &&
            cigsResults.resultOfActions.every((el) => el === true) &&
            questionSetsResultBool
          ) {
            setTimeout(() => {
              getTenderCigs(result.data.id).then((retrievedCigs) => {
                setCigs(retrievedCigs.data);
                setOriginCigs(retrievedCigs.data);
              });
            }, 500);

            handleStatusBar({
              success: true,
              message: translate('common.message.created', 'Elemento creato con successo'),
            });
          } else {
            // handleStatusBar(
            //   'error',
            //   cigsResults.messageResult?.success === false && cigsResults.messageResult?.message
            //     ? cigsResults.messageResult?.message
            //     : typeof cigsResults.messageResult.data === 'object'
            //     ? Object.values(cigsResults.messageResult.data).join()
            //     : 'Si è verificato un errore durante il salvataggio dei CIG.',
            //   false
            // );
            if (
              cigsResults.messageResult?.success === false &&
              cigsResults.messageResult?.message
            )
              handleStatusBar(cigsResults.messageResult);
          }
        });
      } else {
        handleStatusBar(result);
      }
    }

    setShowSpinner(false);
  };

  const handleItemList = async (
    entityType,
    originItemList,
    itemList,
    deleteAPI,
    editAPI,
    createAPI,
    entityId
  ) => {
    let resultOfActions = [];

    const oldItemsDeleted = originItemList.filter((object1) => {
      return !itemList.some((object2) => {
        return object1.id === object2.id;
      });
    });

    if (oldItemsDeleted.length > 0) {
      oldItemsDeleted.forEach(async (item) => {
        const deleteResult = await deleteAPI(entityId, item.id);
        resultOfActions.push(deleteResult.success);
      });
    }

    let validationFields = [];

    switch (entityType) {
      case "cigs":
        validationFields = ["cig"];
        break;
      default:
        validationFields = [];
    }

    let result;
    let messageResult;

    await Promise.all(
      itemList.map(async (item) => {
        const resultOfEvery = validationFields.every((field) => item[field]);
        //console.log('resultOfEvery', resultOfEvery);

        if (resultOfEvery) {
          if (item.id) {
            const { createdAt, createdBy, updatedAt, updatedBy, ...restItem } =
              item;
            result = await editAPI(entityId, restItem);
            if (result.success === false) messageResult = result;
            resultOfActions.push(result.success);
          } else {
            item.tenderId = entityId;
            result = await createAPI(entityId, item);
            //console.log('resultNew', resultNew);
            if (result.success === false) messageResult = result;
            resultOfActions.push(result.success);
          }
        } else {
          messageResult = {
            success: false,
            data: { validation: [translate('common.error.fillfields', 'Azione non eseguita. Riempi tutti i campi')] },
          };
          resultOfActions.push(false);
          return;
        }
      })
    );

    //console.log('messageResult:', messageResult);
    return { resultOfActions, messageResult };
  };

  const addElement = (typeArray, setTypeArray) => {
    let tempTypeArray = [...typeArray];

    tempTypeArray.push({ cig: "", lot: "", tenderId: newTender.id });

    setTypeArray(tempTypeArray);
  };

  const removeElement = (typeArray, setTypeArray, index) => {
    let tempTypeArray = [...typeArray];

    tempTypeArray.splice(index, 1);

    setTypeArray(tempTypeArray);
  };

  const cigsRows = (cigs, setCigs) => {
    let rows = [];

    if (cigs) {
      if (cigs.length === 0) {
        cigs = [{ cig: "", lot: "", tenderId: newTender.id }];
      }

      //console.log('cigs', cigs);
      let lastIndex = cigs.length - 1;

      rows = cigs.map((el, i) => {
        const isThisRowFilled = el.cig ? true : false;

        return (
          <Row key={"cig-" + i} style={{ marginBottom: "40px" }}>
            <Col className="col-6">
              <Form.Control
                type="text"
                name="cig"
                placeholder={translate('common.placeholder.tenders.cigcode', 'codice CIG')}
                value={el.cig ?? ""}
                onChange={(e) => onChange(e, "cigs", setCigs, i, cigs)}
              />
            </Col>
            <Col className="col-4">
              <Form.Control
                type="text"
                name="lot"
                placeholder={translate('common.placeholder.tenders.lot', 'lotto (opzionale)')}
                value={el.lot ?? ""}
                onChange={(e) => onChange(e, "cigs", setCigs, i, cigs)}
              />
            </Col>
            <Col className="col-2">
              {i === lastIndex ? (
                <Button
                  variant="link"
                  className="me-3"
                  onClick={() => addElement(cigs, setCigs)}
                  disabled={!isThisRowFilled}
                >
                  <IcoPlus className="second-color ico-small indicators" />
                </Button>
              ) : null}
              {cigs.length !== 1 ? (
                <Button
                  variant="link"
                  onClick={() => removeElement(cigs, setCigs, i)}
                >
                  <IcoMinusCircled className="red-color ico-small indicators" />
                </Button>
              ) : null}
            </Col>
          </Row>
        );
      });
    }

    return rows;
  };

  return (
    <>
      <div className="simple-box scenaritab candidacy">
        <Tabs
          activeKey={activeKey}
          variant='pills'
          className="mb-3"
          onSelect={(k) => setActiveKey(k)}
        >
          <Tab eventKey="tenders" title={translate('common.tenders', 'Gare')}>
            <div className="row d-flex align-items-center mb-3">
              <div className="col-4">
                {/* <h5 className='title-simple-box px-3'>Lista gare</h5> */}
              </div>
              <div className="col-auto text-right ms-auto px-0 py-1">
                <ButtonToolbar className="justify-content-end">
                  <DownloadExcelButtons
                    textHeader={translate('common.downloadexcel', 'Scarica Excel')}
                    dropdownItems={[
                      {
                        text: translate('common.tenderslist', 'Elenco Gare'),
                        isDownloadingButton: isDownloadingTenders,
                        setIsDownloadingButton: setIsDownloadingTenders,
                        apiDownload: downloadTendersDocument,
                        apiProps: [],
                      },
                      {
                        text: translate('common.checklistslist', 'Elenco Checklists'),
                        isDownloadingButton: isDownloadingCL,
                        setIsDownloadingButton: setIsDownloadingCL,
                        apiDownload: downloadTendersChecklistsDocument,
                        apiProps: [],
                      },
                      {
                        text: translate('common.checklistslistcq', 'Elenco Checklists Contratti Aperti'),
                        isDownloadingButton: isDownloadingCLCq,
                        setIsDownloadingButton: setIsDownloadingCLCq,
                        apiDownload: downloadTendersChecklistsCQDocument,
                        apiProps: [],
                      },
                    ]}
                  />

                  <Button
                    variant="success"
                    onClick={() => handleShowModal(setShowModalNew)}
                  >
                    <IcoPlus className="light-color ico-small plus new-button" />
                    <span className="d-md-inline"><OETrans code='common.tenders.addtender' fallback={'Nuova gara'}/></span>
                  </Button>
                  <CustomQuickFilterBox
                     tableRef={gridRef}
                     dataMeta={tendersMeta}
                     setData={setTenders}
                     dataLength={tenders?.length}
                     isFirstResearch={isFirstResearch} 
                     setIsFirstResearch={setIsFirstResearch}
                     refTable='tenders'
                  />
                </ButtonToolbar>
              </div>
            </div>
              <div className="row">
               {Array.isArray(tenders) ? (
                <div className="col-12" id="grid-wrapper">
                    <div className="ag-theme-alpine list-table">
                      <AgGridReact
                        ref={gridRef}
                        columnDefs={columnDefs}
                        rowData={tenders}
                        defaultColDef={defaultColDef}
                        domLayout="autoHeight"
                        tooltipShowDelay={0}
                        tooltipHideDelay={2000}
                        tooltipMouseTrack={true}
                        onGridReady={onGridReady}
                        //onFirstDataRendered={onFirstDataRendered}
                        //onModelUpdated={onModelUpdated}
                        headerHeight={50}
                        rowHeight={50}
                        localeText={localeText}
                        ariaDescription="ag-grid-table"
                        suppressCellFocus={true}
                      />
                      {Object.keys(tendersMeta).length > 0 && (
                        <CustomPaginationMui
                           dataMeta={tendersMeta} 
                           setData={setTenders} 
                           setDataMeta={setTendersMeta}
                           refTable='tenders'
                           setIsFirstResearch={setIsFirstResearch}
                        />
                     )}
                    </div>
                </div>
                ) : (
                  <div className="text-center my-4">
                    <Spinner animation="border" role="status">
                      <span style={{ visibility: "hidden" }}>
                        <OETrans code='common.loading' fallback={'Caricamento'}/>...
                      </span>
                    </Spinner>
                  </div>
                )}
              </div>
          </Tab>
          <Tab eventKey="massive-upload" title={translate('common.massiveuploaddocuments','Upload massivo e documenti')}>
            {activeKey === "massive-upload" && <MassiveUploadTendersList />}
          </Tab>
        </Tabs>
      </div>

      <Modal
        show={showModalNew}
        className="modal-with-nav"
        size="xl"
        onHide={() => handleCloseModal(setShowModalNew)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title><OETrans code='common.tenders.createtender' fallback={'Nuova gara'}/></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            className="mb-4"
            activeKey={subActiveKey}
            onSelect={(k) => setSubActiveKey(k)}
            variant='pills'
            fill
          >
            {/* Anagrafica */}
            <Tab eventKey="registry" title={translate('common.registry', 'Anagrafica')}>
              <Form onSubmit={handleSave} id="form-new-tender">
                <Row style={{ marginBottom: "40px" }}>
                  <Col className="col-4">
                    <Form.Group as={Col}>
                      <Form.Label>
                        <OETrans code='tenders.companyId' fallback={'Società'}/>
                        &nbsp;<span className="required-field">*</span>
                      </Form.Label>
                      <Select
                        components={{
                           IndicatorSeparator: () => null,
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="companyId"
                        placeholder={translate('common.placeholder.select', 'Seleziona...')}
                        value={selectedCompany}
                        options={optionsCompanies}
                        onChange={(e) =>
                          onChange(e, "companyId", setSelectedCompany)
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-4">
                    <Form.Group as={Col}>
                      <Form.Label>
                        <OETrans code='tenders.tenderNumber' fallback={'Nr. gara'}/>
                        &nbsp;<span className="required-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="tenderNumber"
                        value={newTender.tenderNumber}
                        onChange={(e) => onChange(e, "tenderNumber")}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-4">
                    <Form.Group as={Col}>
                      <Form.Label><OETrans code='tenders.tenderclassification' fallback={'Classificazione gara'}/></Form.Label>
                      <Select
                        components={{
                           IndicatorSeparator: () => null,
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="tenderClassification"
                        placeholder={translate('common.placeholder.select', 'Seleziona...')}
                        value={selectedClassification}
                        options={optionsClassifications}
                        onChange={(e) =>
                          onChange(
                            e,
                            "tenderClassification",
                            setSelectedClassification
                          )
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "40px" }}>
                  <Col className="col-4">
                    <Form.Group as={Col}>
                      <Form.Label><OETrans code='tenders.assignmentProcedureCode' fallback={'Tipologia procedura'}/></Form.Label>
                      <Select
                        components={{
                           IndicatorSeparator: () => null,
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="assignmentProcedureCode"
                        placeholder={translate('common.placeholder.select', 'Seleziona...')}
                        value={selectedProcedure}
                        options={optionsProcedures}
                        onChange={(e) =>
                          onChange(
                            e,
                            "assignmentProcedureCode",
                            setSelectedProcedure
                          )
                        }
                        isClearable
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-4">
                    <Form.Group as={Col}>
                      <Form.Label><OETrans code='tenders.qualificationSystem' fallback={'Sistema di qualificazione'}/></Form.Label>
                      <Form.Control
                        type="text"
                        name="qualificationSystem"
                        value={newTender.qualificationSystem ?? ""}
                        onChange={(e) => onChange(e, "qualificationSystem")}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-4">
                    <Form.Group as={Col}>
                      <Form.Label><OETrans code='tenders.assignmentCriteriaCode' fallback={'Criterio di aggiudicazione'}/></Form.Label>
                      <Select
                        components={{
                           IndicatorSeparator: () => null,
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="assignmentCriteriaCode"
                        placeholder={translate('common.placeholder.select', 'Seleziona...')}
                        value={selectedCriteria}
                        options={optionsCriteria}
                        isClearable
                        onChange={(e) =>
                          onChange(
                            e,
                            "assignmentCriteriaCode",
                            setSelectedCriteria
                          )
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "40px" }}>
                  <Col className="col-3">
                    <Form.Group as={Col}>
                      <Form.Label><OETrans code='tenders.callDate' fallback={'Data indizione'}/></Form.Label>
                      <Form.Control
                        type="date"
                        name="callDate"
                        value={
                          newTender.callDate
                            ? dateFormatterInput(newTender.callDate)
                            : ""
                        }
                        onChange={(e) => onChange(e, "callDate")}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-3">
                    <Form.Group as={Col}>
                      <Form.Label><OETrans code='tenders.assignmentDate' fallback={'Data avviso aggiudicazione'}/></Form.Label>
                      <Form.Control
                        type="date"
                        name="assignmentDate"
                        value={
                          newTender.assignmentDate
                            ? dateFormatterInput(newTender.assignmentDate)
                            : ""
                        }
                        onChange={(e) => onChange(e, "assignmentDate")}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-3">
                    <Form.Group as={Col}>
                      <Form.Label><OETrans code='tenders.officialGazettePubItaly' fallback={'Pubblicazione gazzetta (GURI)'}/></Form.Label>
                      <Form.Check
                        className="text-center"
                        type="checkbox"
                        name="officialGazettePubItaly"
                        defaultChecked={newTender.officialGazettePubItaly}
                        onClick={(e) => onChange(e, "officialGazettePubItaly")}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-3">
                    <Form.Group as={Col}>
                      <Form.Label><OETrans code='tenders.officialGazettePubEurope' fallback={'Pubblicazione gazzetta (GUUE)'}/></Form.Label>
                      <Form.Check
                        className="text-center"
                        type="checkbox"
                        name="officialGazettePubEurope"
                        defaultChecked={newTender.officialGazettePubEurope}
                        onClick={(e) => onChange(e, "officialGazettePubEurope")}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "40px" }}>
                  <Col className="col-4">
                    <Form.Group as={Col}>
                      <Form.Label><OETrans code='tenders.auctionImport' fallback={'Importo base d’asta'}/></Form.Label>
                      <InputNumber
                        name="auctionImport"
                        min={0}
                        step={1}
                        minFractionDigits={0}
                        maxFractionDigits={2}
                        onChange={(e) => onChange(e, "auctionImport")}
                        mode="decimal"
                        locale={localLang}
                        className="md"
                        value={newTender.auctionImport ?? ""}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-4">
                    <Form.Group as={Col}>
                      <Form.Label><OETrans code='tenders.totalImport' fallback={'Importo complessivo gara'}/></Form.Label>
                      <InputNumber
                        name="totalImport"
                        min={0}
                        step={1}
                        minFractionDigits={0}
                        maxFractionDigits={2}
                        onChange={(e) => onChange(e, "totalImport")}
                        mode="decimal"
                        locale={localLang}
                        className="md"
                        value={newTender.totalImport ?? ""}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-4">
                    <Form.Group as={Col}>
                      <Form.Label><OETrans code='tenders.import' fallback={'Importo base'}/></Form.Label>
                      <InputNumber
                        name="import"
                        min={0}
                        step={1}
                        minFractionDigits={0}
                        maxFractionDigits={2}
                        onChange={(e) => onChange(e, "import")}
                        mode="decimal"
                        locale={localLang}
                        className="md"
                        value={newTender.import ?? ""}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "40px" }}>
                  <Col className="col-4">
                    <Form.Group as={Col}>
                      <Form.Label><OETrans code='tenders.referenceAct' fallback={'Atto'}/></Form.Label>
                      <Form.Control
                        type="text"
                        name="referenceAct"
                        value={newTender.referenceAct ?? ""}
                        onChange={(e) => onChange(e, "referenceAct")}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-4">
                    <Form.Group as={Col}>
                      <Form.Label>
                        <OETrans code='common.setchecklist' fallback={'Set Domande'}/>
                        &nbsp;<span className="required-field">*</span>
                     </Form.Label>
                      <Select
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        name="questionsetId"
                        placeholder={translate('common.placeholder.select', 'Seleziona...')}
                        value={selectedQuestionSet}
                        options={optionsQuestionSet}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        onChange={(e) =>
                          onChange(e, "questionsetId", setSelectedQuestionSet)
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-4">
                    <Form.Group as={Col}>
                      <Form.Label><OETrans code='tenders.subject' fallback={'Oggetto'}/></Form.Label>
                      <Form.Control
                        type="text"
                        name="subject"
                        value={newTender.subject ?? ""}
                        onChange={(e) => onChange(e, "subject")}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "40px" }}>
                  <Col className="col-auto">
                    <Form.Group as={Col}>
                      <Form.Label><OETrans code='tenders.dedicatedPnrr' fallback={'Dedicata'}/></Form.Label>
                      <Form.Check
                        className="ms-3"
                        inline
                        type="checkbox"
                        name="dedicatedPnrr"
                        defaultChecked={newTender.dedicatedPnrr}
                        onClick={(e) => onChange(e, "dedicatedPnrr")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              {sbShow}
            </Tab>

            {/* CIG */}
            <Tab eventKey="cig" title={translate('common.tenders.cig', 'CIG')}>
              <Form onSubmit={handleSave} id="form-new-tender">
                <Row style={{ marginBottom: "40px" }}>
                  <Form.Group as={Col}>
                    <Form.Label>
                        <OETrans code='common.tenders.cig' fallback={'CIG'}/>
                        &nbsp;<span className="required-field">*</span>
                    </Form.Label>
                    {cigsRows(cigs, setCigs)}
                  </Form.Group>
                </Row>
              </Form>
              {sbShow}
            </Tab>

            {/* Documenti */}
            <Tab eventKey="docs" title={<OETrans code='common.documents' fallback={'Documenti'} />} disabled={!newTender.id}>
              <UploadDocument entity="tenders" entityId={newTender.id} groupedBySection={true} />
              {sbShow}
            </Tab>

            {/* Contratti aperti */}
            <Tab
              eventKey="frameworkContracts"
              title={translate('common.frameworkcontracts', 'Contratti aperti')}
              disabled={!newTender.id}
            >
              <MassiveUpload entityId={newTender.id} doDownload={true} />
              {sbShow}
            </Tab>

            {/* Checklist */}
            <Tab
              eventKey="checklist"
              title={translate('common.checklist', 'Checklist')}
              disabled={!newTender.id}
            >
              {subActiveKey === "checklist" && (
                <CheckList entityType="tenders" entityId={newTender.id} />
              )}
            </Tab>
          </Tabs>

          {(subActiveKey === "registry") && (
            <Row>
              <Col className="col-12">
                <span className="label">
                  <span className="required-field">*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/>
                </span>
              </Col>
            </Row>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseModal(setShowModalNew)}
          >
            <OETrans code='common.cancel' fallback={'Annulla'}/>
          </Button>
          <Button
            variant="primary"
            onClick={(e) => handleSave(e)}
            disabled={
              subActiveKey === "docs" || subActiveKey === "frameworkContracts"
            }
          >
            {newTender.id ? <OETrans code='common.save' fallback={'Salva'}/> : <OETrans code='common.create' fallback={'Crea'}/>}

            {showSpinner && (
              <Spinner
                as="span"
                className="ms-2"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default TendersList;
