import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, OverlayTrigger, Tooltip, Accordion } from 'react-bootstrap';
import { formatToDecimals } from '@lib/helpers/formatting';
import ProjectDossier from 'components/projectDossier/ProjectDossier';
import DashboardWheelsSection from 'components/charts/DashboardWheelsSection';
import { IcoBag, IcoInfoSerif, IcoCoins, IcoTiming } from '@icons';
import { OETrans } from 'components/translation/OETrans';

const TriggerTooltip = ({ parentRef, textValue }) => {
   const [overflowActive, setOverflowActive] = useState(false);
   //const [style, setStyle] = useState(null);
   const childRef = useRef(null);
   const childRefInnerHTML = childRef.current?.innerHTML;

   useEffect(() => {
      if (parentRef.current && childRef.current && childRefInnerHTML) {
         //console.log('childRef offsetWidth', childRef.current.offsetWidth);
         //console.log('parentRef offsetWidth', parentRef.current.offsetWidth);

         setOverflowActive(parentRef.current.offsetWidth < childRef.current.offsetWidth);
         //setStyle({parent: parentRef.current.offsetWidth, child: childRef.current.offsetWidth})
      }
   }, [childRef, childRefInnerHTML, parentRef]);

   const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;

   return (
      <ConditionalWrapper
         condition={overflowActive}
         wrapper={(children) => (
            <OverlayTrigger placement="top-start" overlay={<Tooltip className="">{textValue}</Tooltip>}>
               {children}
            </OverlayTrigger>
         )}
      >
         <span ref={childRef}>{textValue}</span>
      </ConditionalWrapper>
   );
};

const Summary = ({ project, userRole, summaryWheelsValues, setSummaryWheelsValues }) => {
   const [thisProject, setThisProject] = useState({});
   const [finalizedAmount, setFinalizedAmount] = useState(0);
   const ref1 = useRef(null);
   const ref2 = useRef(null);
   const ref3 = useRef(null);
   const ref4 = useRef(null);
   const ref5 = useRef(null);
   const ref6 = useRef(null);
   const ref7 = useRef(null);
   const ref8 = useRef(null);
   const ref9 = useRef(null);
   const ref10 = useRef(null);
   const ref11 = useRef(null);
   const ref12 = useRef(null);
   //console.log('thisProject', thisProject);

   useEffect(() => {
      setThisProject(project);
   }, [project]);

   //   useEffect(() => {
   //     if (summaryWheelsValues.length > 0) {
   //       let result = 0;
   //       const totalAmount = summaryWheelsValues
   //         .filter((el) => el.propertyName === 'totalAmount')
   //         .map((el) => el.propertyValue);
   //       const notReportableNotReportedAmount = summaryWheelsValues
   //         .filter((el) => el.propertyName === 'notReportableNotReportedAmount')
   //         .map((el) => el.propertyValue);
   //       //importo consuntivato = totale - in lavorazione
   //       result = totalAmount - notReportableNotReportedAmount;

   //       setFinalizedAmount(result);
   //     }
   //   }, [summaryWheelsValues]);

   useEffect(() => {
      if (summaryWheelsValues.length > 0) {
         setFinalizedAmount(
            summaryWheelsValues.filter((el) => el.propertyName === 'finalAmount')?.[0]?.realValue
         );
      }
   }, [summaryWheelsValues]);

   return (
      <>
         <DashboardWheelsSection
            projectId={project.id}
            setSummaryWheelsValues={setSummaryWheelsValues}
            userRole={userRole}
         />

         <div className='simple-box scenaritab'>
            <div className='row d-flex align-items-center mb-4'>
               <div className='col-4 ps-0'>
                  <h5 className='title-box'><OETrans code='common.registry' fallback={'Anagrafica'}/></h5>
               </div>
            </div>

            <Row id="registry-summary" className="">
               <Col className="div1 mt-5">
                  <Row /* className="justify-content-between" */>
                     {/* TITOLARI */}
                     <Col /* className="col-5" */>
                        <Row className="mb-3">
                           <Col className="col-auto">
                              <Row className="align-items-center flex-nowrap">
                                 <Col className="col-auto px-0"><IcoBag className='ico-registry-project' /></Col>
                                 <Col className="col-auto ps-2"><span className='second-color f-600'>Titolari</span></Col>
                              </Row>
                           </Col>
                        </Row>

                        <Row className="py-3 border-bottom-grey">
                           <Col className="ps-0 col-auto"><span className='f-600 no-to-head'>Beneficiario</span></Col>
                           <Col className='text-end one-line-text pe-0' ref={ref1}>
                              <TriggerTooltip parentRef={ref1} textValue={thisProject.company?.name} />
                           </Col>
                        </Row>

                        <Row className="py-3 border-bottom-grey">
                           <Col className="ps-0 col-auto"><span className='f-600 no-to-head'>PM Beneficiario</span></Col>
                           <Col className='text-end one-line-text pe-0' ref={ref2}>
                              <TriggerTooltip parentRef={ref2} textValue={thisProject.manager ?? <>N.D.</>} />
                           </Col>
                        </Row>

                        <Row className="py-3 border-bottom-grey">
                           <Col className="ps-0 col-auto"><span className='f-600 no-to-head'>PM OE</span></Col>
                           <Col className='text-end one-line-text pe-0' ref={ref3}>
                              <TriggerTooltip parentRef={ref3} textValue={thisProject.oeManagerId ? thisProject.oemanager?.firstName + ' ' +
                                 thisProject.oemanager?.lastName : <>N.D.</>} />
                           </Col>
                        </Row>
                     </Col>
                  </Row>
               </Col>

               <Col className="div2 mt-5">
                  <Row /* className="justify-content-between" */>                 
                     {/* DETTAGLI */}
                     <Col /* className="col-6" */>
                        <Row className="mb-3">
                           <Col className="col-auto">
                              <Row className="align-items-center flex-nowrap">
                                 <Col className="col-auto px-0"><IcoInfoSerif className='ico-registry-project' /></Col>
                                 <Col className="col-auto ps-2"><span className='second-color f-600'>Dettagli</span></Col>
                              </Row>
                           </Col>
                        </Row>

                        <Row className="py-3 border-bottom-grey">
                           <Col className="ps-0 col-auto"><span className='f-600 no-to-head'>Localizzazione</span></Col>
                           <Col className='text-end one-line-text pe-0' ref={ref4}>
                              <TriggerTooltip 
                                 parentRef={ref4} 
                                 textValue={thisProject.network ? thisProject.networkNote : (thisProject.location?.nomeComune ?? <>N.D.</>)} 
                              />
                           </Col>
                        </Row>

                        <Row className="py-3 border-bottom-grey">
                           <Col className="ps-0 col-auto"><span className='f-600 no-to-head'>Fondo</span></Col>
                           <Col className='text-end one-line-text pe-0' ref={ref5}>
                              <TriggerTooltip parentRef={ref5} textValue={thisProject?.notice?.fund?.name} />
                           </Col>
                        </Row>

                        <Row className="py-3 border-bottom-grey">
                           <Col className="ps-0 col-auto"><span className='f-600 no-to-head'>Bando</span></Col>
                           <Col className='text-end one-line-text pe-0' ref={ref6}>
                              <TriggerTooltip parentRef={ref6} textValue={thisProject?.notice?.call} />
                           </Col>
                        </Row>
                     </Col>
                  </Row>
               </Col>

               <Col className="div3 mt-5">
                  <Row /* className="justify-content-between" */>
                     {/* DIMENSIONE ECONOMICA */}
                     <Col /* className="col-5" */>
                        <Row className="mb-3">
                           <Col className="col-auto">
                              <Row className="align-items-center flex-nowrap">
                                 <Col className="col-auto px-0"><IcoCoins className='ico-registry-project' /></Col>
                                 <Col className="col-auto ps-2"><span className='second-color f-600'>Dimensione economica (€)</span></Col>
                              </Row>
                           </Col>
                        </Row>

                        <Row className="py-3 border-bottom-grey">
                           <Col className="ps-0 col-auto"><span className='f-600 no-to-head'>Costo progetto</span></Col>
                           <Col className='text-end one-line-text pe-0' ref={ref7}>
                              <TriggerTooltip parentRef={ref7} textValue={formatToDecimals(
                                 summaryWheelsValues.filter((el) => el.propertyName === 'totalAmount').map((el) => el.propertyValue ?? 0).pop()
                              )} />
                           </Col>
                        </Row>

                        <Row className="py-3 border-bottom-grey">
                           <Col className="ps-0 col-auto"><span className='f-600 no-to-head'>Contributo concesso</span></Col>
                           <Col className='text-end one-line-text pe-0' ref={ref8}>
                              <TriggerTooltip parentRef={ref8} textValue={formatToDecimals(
                                 summaryWheelsValues.filter((el) => el.propertyName === 'totalApprovedAmount').map((el) => el.propertyValue ?? 0).pop()
                              )} />
                           </Col>
                        </Row>

                        <Row className="py-3 border-bottom-grey">
                           <Col className="ps-0 col-auto"><span className='f-600 no-to-head'>Spesa effettuata</span></Col>
                           <Col className='text-end one-line-text pe-0' ref={ref9}>
                              <TriggerTooltip parentRef={ref9} textValue={formatToDecimals(finalizedAmount)} />
                           </Col>
                        </Row>
                     </Col>
                  </Row>
               </Col>

               <Col className="div4 mt-5">
                  <Row /* className="justify-content-between" */>
                     {/* TIMING */}
                     <Col /* className="col-6" */>
                        <Row className="mb-3">
                           <Col className="col-auto">
                              <Row className="align-items-center flex-nowrap">
                                 <Col className="col-auto px-0"><IcoTiming className='ico-registry-project' /></Col>
                                 <Col className="col-auto ps-2"><span className='second-color f-600'>Timing</span></Col>
                              </Row>
                           </Col>
                        </Row>

                        <Row className="py-3 border-bottom-grey">
                           <Col className="ps-0 col-auto"><span className='f-600 no-to-head'>Durata (mesi)</span></Col>
                           <Col className='text-end one-line-text pe-0' ref={ref10}>
                              <TriggerTooltip parentRef={ref10} textValue={thisProject.durationInMonths} />
                           </Col>
                        </Row>

                        <Row className="py-3 border-bottom-grey">
                           <Col className="ps-0 col-auto"><span className='f-600 no-to-head'>Avvio</span></Col>
                           <Col className='text-end one-line-text pe-0' ref={ref11}>
                              <TriggerTooltip parentRef={ref11} textValue={thisProject.startDate ? (new Date(thisProject.startDate).toLocaleDateString('en-GB')) : (thisProject.forecastStartDate ? (new Date(thisProject.forecastStartDate).toLocaleDateString('en-GB')) : <>N.D.</>)} />
                           </Col>
                        </Row>

                        <Row className="py-3 border-bottom-grey">
                           <Col className="ps-0 col-auto"><span className='f-600 no-to-head'>Fine</span></Col>
                           <Col className='text-end one-line-text pe-0' ref={ref12}>
                              <TriggerTooltip parentRef={ref12} textValue={thisProject.endDate ? (new Date(thisProject.endDate).toLocaleDateString('en-GB')) : (thisProject.forecastEndDate ? (new Date(thisProject.forecastEndDate).toLocaleDateString('en-GB')) : <>N.D.</>)} />
                           </Col>
                        </Row>
                     </Col>
                  </Row>
               </Col>
            </Row>

            {thisProject.description && (
               <Row>
                  <div id='accordion-summary' className='mt-5 px-0'>
                     <Accordion flush>
                        <Accordion.Item eventKey="0">
                           <Accordion.Header>
                              <span className='f-600' style={{ fontSize: '1.125rem' }}>Descrizione</span>
                           </Accordion.Header>
                           <Accordion.Body>
                              {thisProject.description}
                           </Accordion.Body>
                        </Accordion.Item>
                     </Accordion>
                  </div>
               </Row>
            )}
         </div>

         <div className='simple-box scenaritab' style={{ marginTop: '2.5rem' }}>
            <div className='row d-flex align-items-center mb-4'>
               <div className='col-4 ps-0'>
                  <h5 className='title-box'>Fascicolo di progetto</h5>
               </div>
            </div>

            <Row className='mt-3 px-3'>
               <ProjectDossier entityObj={thisProject} />
            </Row>
         </div>
      </>
   );
};
export default Summary;
