import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { getProject } from '@lib/api';
import ProjectHeader from '@components/project/ProjectHeader';
import Summary from '@components/project/Summary';
import Candidacy from './Candidacy';
import Planning from './Planning';
import Execution from './Execution';
import Advancement from './Advancement';
import Dnsh from './Dnsh';
import ExecutionSummary from './ExecutionSummary';
import Reporting from './Reporting';
import NotFoundPage from 'pages/404';
import PhysicalExecution from './PhysicalExecution';
import ProjectNavHorizontal from '@components/nav/projectNavHorizontal';
import { LevelContext } from "@components/contexts/LevelContext";

const ProjectWizard = () => {
  const { userRole } = useContext(LevelContext);
  const { projectId } = useParams();
  const { tab, submenu } = useParams();
  const [project, setProject] = useState({});
  const [summaryWheelsValues, setSummaryWheelsValues] = useState([]);
  //console.log('tab:', tab);
  //console.log('submenu:', submenu);

  const fetchDataProject = useCallback(() => {
      getProject(projectId).then((retrievedProject) => {
         setProject(retrievedProject.data);
      });
   }, [projectId]);

  useEffect(() => {
   fetchDataProject();
  }, [fetchDataProject]);

  return (
    <>
      {!project ? (
        <NotFoundPage />
      ) : (
        <>
          <ProjectNavHorizontal />

          <ProjectHeader project={project} setProject={setProject} />

          {(tab === 'dashboard' || !tab) && <Summary project={project} userRole={userRole} summaryWheelsValues={summaryWheelsValues} setSummaryWheelsValues={setSummaryWheelsValues} />}
          {tab === 'candidacy' && <Candidacy project={project} summaryWheelsValues={summaryWheelsValues} fetchDataProject={fetchDataProject} />}
          {tab === 'planning' && <Planning project={project} />}

          {tab === 'execution' && (submenu === 'economic' || !submenu) && (
            <Execution project={project} />
          )}
          {tab === 'execution' && submenu === 'physical' && <PhysicalExecution project={project} />}

          {tab === 'summary' && <ExecutionSummary project={project} />}
          {tab === 'advancement' && <Advancement project={project} />}
          {tab === 'dnsh' && <Dnsh project={project} />}
          {tab === 'reporting' && <Reporting project={project} />}
        </>
      )}
    </>
  );
};
export default ProjectWizard;
