const determineMimeType = (document) => {
   const fileName = document?.fileName || "";
   const mimeType = document?.mimeType || "";
   const fileExtension = document?.fileExtension || "";
   if (fileName.endsWith(".p7m")) {
      const fileWithoutP7m = fileName.replace(/\.p7m$/, "").toLowerCase();
      const extension = fileExtension.toLowerCase();
      if (fileWithoutP7m === extension) { 
         //Se non c'è un'altra estensione
         return 'application/pdf';
      } else {
         //Se c'è un'altra estensione
         if (extension === "pdf") {
            return "application/pdf";
         } else {
            return mimeType;
         }
      }
   } else {
      return mimeType;
   }
};
export { determineMimeType };