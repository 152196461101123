let baseUrl = '';
baseUrl = process.env.REACT_APP_BASE_URL;
/*if (process.env.NODE_ENV !== 'production') {
  //LOCAL DEV:
  //baseUrl =  process.env.REACT_APP_APIURL //'https://api-ics.externalytics.com/api' //https://ics.stage2.openeconomics.ovh/api
  //baseUrl = 'http://127.0.0.1/api'
} else {
  //baseUrl =  window.icsApiUrl || 'https://api-ics.externalytics.com/api'; //'http://localhost/api' //https://ics.stage2.openeconomics.ovh/api
}*/
//baseUrl =  window.icsApiUrl || 'https://api-ics.externalytics.com/api'; //'http://localhost/api' //https://ics.stage2.openeconomics.ovh/api

const v0Url = baseUrl + '/v0';

const credentials = {
  token: localStorage.getItem('token') || '',
};

const handleErrors = (response) => {
  if (!response.ok) {
    const responseError = {
      statusText: response.statusText,
      status: response.status,
    };
    throw responseError;
  }
  //console.log(response)
  return response.json();
};

export const getAuth = () => {
  //console.log('getAuth', credentials.token )
  return credentials.token;
};

export const getDefaultHeaders = () => {
  const token = getAuth();
  //console.log('token', token)
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
};

export const getNoJsonHeaders = () => {
  const token = getAuth();
  //console.log('token', token)
  return {
    Authorization: `Bearer ${token}`,
  };
};

export const getNoAuthorizationHeaders = () => {
  return {
    'Content-Type': 'application/json',
  };
};

export const sendRequest = async (url, method, data = null) => {
  try {
    const params = {
      method: method,
      headers: {
        ...getDefaultHeaders(),
      },
    };
    if (data) {
      params.body = JSON.stringify(data);
    }
    const response = await fetch(url, params);
    return handleIsUnauthenticated(response);
  } catch (err) {
    return err;
  }
};

export const sendUnauthRequest = async (url, method, data) => {
  const response = await fetch(url, {
    method: method,
    headers: {
      ...getNoAuthorizationHeaders(),
    },
    body: JSON.stringify(data),
  });
  return await response.json();
};

export const handleIsUnauthenticated = async (resp) => {
  // if (resp.ok) {
  const respCode = resp.status;
  //console.log('respCode', respCode);

  if (respCode === 401 || respCode === 429) {
    if (respCode === 429) {
      localStorage.setItem('error', 'Too many attemps! Wait a few seconds.');
    }

    window.location.assign('/');

    return;
  } else {
    const response = await resp.json();

    return response;
  }
  // }
  // throw new Error('Error:' + resp.statusText);
};

export const handleLoadPerPageSaved = (refTable, fullUrl) => {
   let rowsPerTable = localStorage.getItem('rowsPerTable');
   rowsPerTable = rowsPerTable ? JSON.parse(rowsPerTable) : rowsPerTable;

   //console.log('rowsPerTable localStorage:', rowsPerTable);

   if (refTable && Array.isArray(rowsPerTable) && rowsPerTable.some(el => el.refTable === refTable)) {
      const perPage = Number(rowsPerTable.filter(el => el.refTable === refTable).map(el => el.perPage).join());

      const withPerPageUrl = fullUrl + (fullUrl.includes('?') ? '&' : '?') + `perPage=${perPage}`;
      //console.log('withPerPageUrl', withPerPageUrl);

      return withPerPageUrl;
   } else {
      //console.log('fullUrl', fullUrl);

      return fullUrl;
   }
};

/**
 *
 * @param {*} param0
 * @returns
 */
export const checkLogin = async function (email, password, loginType) {
  //console.log('checkLogin', baseUrl)
  //console.log('checkLogin', email, password, loginType)

  var formBody = `email=${email}&password=${password}&loginType=${loginType}`;
  var returnCode = 200;
  await fetch(`${baseUrl}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: formBody,
  })
    .then(handleErrors)
    .then((r) => {
      //console.log('mm', r, loginType, email)
      //console.log('r role', r.data.role)
      //console.log('r projectId', r.data.projectId)

      credentials.token = r.accessToken;
      localStorage.setItem('token', credentials.token);
      localStorage.setItem('project_id', null);
      localStorage.setItem('userEmail', null);

      if (Number(r.data.role) === 1) {
        localStorage.setItem('client_id', email);
        localStorage.setItem('request_id', password);
        localStorage.setItem('project_id', r.data.projectId);
      } else {
        localStorage.setItem('userEmail', email);
      }
    })
    .catch((err) => {
      returnCode = err.status;
      return err.status;
    });
  /*
    const respJson = response.json()
    console.log(respJson)
    if (response.status === 200) {
      credentials.token = respJson.accessToken
      localStorage.setItem('token', credentials.token)
    } else {
      credentials.token = null
      localStorage.setItem('token', credentials.token)
    }*/
  return returnCode;
};

export const checkAuth = async () => {
  try {
    const resp = await fetch(`${baseUrl}/test/${credentials.token}`, {
      //method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      //body: JSON.stringify(survey),
    }).then((response) => response);

    //console.log('resp', resp);
    const response = resp.status < 300 && resp.status >= 200 ? 200 : resp.status;
    //console.log('response', response);

    return response;
  } catch (err) {
    //console.log('err', err);
    return null;
  }
};

export const getUserInfo = async () => {
  return sendRequest(`${baseUrl}/userinfo`, 'GET', null);

  /*
    return fetch(`${baseUrl}/userinfo`, {
       method: 'GET',
       headers: {
          ...getDefaultHeaders(),
          'Accept': 'application/json',
       },
    }).then(resp => handleIsUnauthenticated(resp));
    */
};

export const forgotPassword = async (email) => {

  return await fetch(`${baseUrl}/forgot-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: `email=${email}`,
  })
    .then((resp) => {
      // Set local storage for email only if res is 200
      if (resp.status === 200) {
        localStorage.setItem('emailForPasswordReset', email);
      }
      return resp.json();
    })
    .catch((err) => {
      return
    });
};

export const validateOtp = async (email, otp) => {
  return await fetch(`${baseUrl}/validate-otp`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: `email=${email}&otp=${otp}`,
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => {
      return
    });
};

export const resetPassword = async (email, password, passwordConfirmation, otp) => {
  return await fetch(`${baseUrl}/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: `email=${email}&password=${password}&passwordConfirmation=${passwordConfirmation}&otp=${otp}`,
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => {
      return
    });
};

export const login = async (email, password, otp = null) => {
  let formBody = `email=${email}&password=${password}`;

  let url = `${baseUrl}/login`;

  if (otp !== null && otp !== '') {
    formBody += `&otp=${otp}`;
    url = `${baseUrl}/login-otp`;
  }

  try {
      const response = await fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
          body: formBody,
      });
      const res = await response.json();
      if (!response.ok) {
        return { code: response.status, data: res.data || null, message: res.message || null };
      }
      if (res.data && res.data.token) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userEmail", res.data.email);
        localStorage.setItem("userFirstname", res.data.firstName);
        localStorage.setItem("userLastname", res.data.lastName);
        localStorage.setItem("error", "");
        localStorage.removeItem('rowsPerTable'); //da rimuovere dopo installazione
        if (!localStorage.getItem("userLanguage")) {
          localStorage.setItem("userLanguage", "IT");
        }
        credentials.token = res.data.token;
      }
      return { code: response.status, data: res.data || null, message: res.message || null };
  } catch (error) {
      return { code: error.message.includes('Network response was not ok') ? parseInt(error.message.replace('Network response was not ok ', '')) : 500, data: null };
  }
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userRoles');
  localStorage.removeItem('userRole');
  localStorage.removeItem('userEmail');
  localStorage.removeItem('userFirstname');
  localStorage.removeItem('userLastname');
  localStorage.removeItem('userIsOE');
  localStorage.removeItem('userLanguage');

  localStorage.removeItem('rowsPerTable'); //da rimuovere dopo installazione
  //localStorage.removeItem('translations');

  credentials.username = '';
  credentials.password = '';
  credentials.token = '';
};

export const getLanguages = async () => {
  return fetch(`${baseUrl}/oelanguages`, {
    method: 'GET',
    headers: {
      ...getNoAuthorizationHeaders(),
    },
  })
    .then((resp) => handleIsUnauthenticated(resp))
    .catch((err) => {
      console.error('Error with fetch:', err);
      throw err;
    });
};

export const getDeliverables = async (projectId) => {
  return fetch(`${v0Url}/projects/${projectId}/projectdeliverables`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp)).catch((err) => {
    console.error('Error with fetch:', err);
    throw err;
  });
};

export const storeDeliverable = async (projectId, data) => {
  return fetch(`${v0Url}/projects/${projectId}/projectdeliverables`, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp)).catch((err) => {
    console.error('Error with fetch:', err);
    throw err;
  });
};

export const updateDeliverable = async (projectId, data) => {
  return fetch(`${v0Url}/projects/${projectId}/projectdeliverables/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp)).catch((err) => {
    console.error('Error with fetch:', err);
    throw err;
  });
};

export const deleteDeliverable = async (projectId, deliverableId) => {
  return fetch(`${v0Url}/projects/${projectId}/projectdeliverables/${deliverableId}`, {
    method: 'DELETE',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp)).catch((err) => {
    console.error('Error with fetch:', err);
    throw err;
  });
};


export const getRisks = async (projectId) => {
  return fetch(`${v0Url}/projects/${projectId}/projectrisks`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp)).catch((err) => {
    console.error('Error with fetch:', err);
    throw err;
  });
};

export const storeRisk = async (projectId, data) => {
  return fetch(`${v0Url}/projects/${projectId}/projectrisks`, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp)).catch((err) => {
    console.error('Error with fetch:', err);
    throw err;
  });
};

export const updateRisk = async (projectId, data) => {
  return fetch(`${v0Url}/projects/${projectId}/projectrisks/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp)).catch((err) => {
    console.error('Error with fetch:', err);
    throw err;
  });
};

export const deleteRisk = async (projectId, riskId) => {
  return fetch(`${v0Url}/projects/${projectId}/projectrisks/${riskId}`, {
    method: 'DELETE',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp)).catch((err) => {
    console.error('Error with fetch:', err);
    throw err;
  });
};

export const getTranslations = async () => {
  return fetch(`${baseUrl}/oetranslations`, {
    method: 'GET',
    headers: {
      ...getNoAuthorizationHeaders(),
    },
  })
    .then((resp) => handleIsUnauthenticated(resp))
    .catch((err) => {
      console.error('Error with fetch:', err);
      throw err;
    });
};

export const storeTranslation = async (data) => {
  return fetch(`${baseUrl}/oetranslations`, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((resp) => handleIsUnauthenticated(resp))
    .catch((err) => {
      console.error('Error with fetch:', err);
      throw err;
    });
};

export const updateTranslation = async (data) => {
  return fetch(`${baseUrl}/oetranslations/${data.code}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    // remove code from data
    body: JSON.stringify({ ...data }),
  })
    .then((resp) => handleIsUnauthenticated(resp))
    .catch((err) => {
      console.error('Error with fetch:', err);
      throw err;
    });
};

export const deleteTranslation = async (code) => {
  return fetch(`${baseUrl}/oetranslations/${code}`, {
    method: 'DELETE',
    headers: {
      ...getDefaultHeaders(),
    },
  })
    .then((resp) => handleIsUnauthenticated(resp))
    .catch((err) => {
      console.error('Error with fetch:', err);
      throw err;
    });
};


export const exportTranslations = async () => {
  try {
    const response = await fetch(`${baseUrl}/oetranslations/export`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const filenameString = response?.headers?.get('Content-Disposition');
    let fileName = '';
    const parts = filenameString.split('filename=');

    if (parts.length > 1) {
      fileName = parts?.[1];
    }

    const blob = await response.blob();
    var a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
    return { success: true };
  } catch (error) {
    console.error('Error downloading project document:', error);
    return { success: false, message: error.message };
  }
};

export const importTranslations = async (file) => {
  let formData = new FormData();
  formData.append('file', file);
  formData.append('fileType', 'xlsx');
  try {
    const resp = await fetch(`${baseUrl}/oetranslations/import`, {
      method: 'POST',
      headers: {
        ...getNoJsonHeaders(),
      },
      body: formData,
    }).then((resp) => handleIsUnauthenticated(resp));
    return resp;
  } catch (err) {
    return err;
  }
};

export const getRoles = async () => {
  return sendRequest(`${baseUrl}/roles`, 'GET', null);
  /*
    return fetch(`${baseUrl}/roles`, {
       method: 'GET',
       headers: {
          ...getDefaultHeaders(),
       },
    }).then(resp => handleIsUnauthenticated(resp));
    */
};

export const fetchForPagination = async (path) => {
  try {
    const resp = await fetch(path, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
    }).then((resp) => handleIsUnauthenticated(resp));
    return resp;
  } catch (err) {
    return err;
  }
};

export const fetchForPaginationWithQueryParams = async (path, queryParams) => {
  try {
    const resp = await fetch(path + queryParams, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
    }).then((resp) => handleIsUnauthenticated(resp));
    return resp;
  } catch (err) {
    return err;
  }
};

//GROUPS
export const getGroups = async (queryParamsString, refTable) => {
   queryParamsString = queryParamsString ?? '';

  return sendRequest(handleLoadPerPageSaved(refTable, `${v0Url}/groups${queryParamsString}`), 'GET', null);
  /*
    return fetch(`${v0Url}/groups`, {
       method: 'GET',
       headers: {
          ...getDefaultHeaders(),
       },
    }).then(resp => handleIsUnauthenticated(resp));
    */
};

export const createGroup = async (data) => {
  try {
    const resp = await fetch(`${v0Url}/groups`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editGroup = async (data) => {
  return fetch(`${v0Url}/groups/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteGroup = async (groupId) => {
  try {
    const resp = await fetch(`${v0Url}/groups/${groupId}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

//COMPANIES
export const getCompanies = async (queryParamsString, refTable) => {
   queryParamsString = queryParamsString ?? '';

  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/companies${queryParamsString}`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getVatRates = async () => {
  return fetch(`${baseUrl}/oevatrates`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};


export const createCompany = async (data) => {
  try {
    const resp = await fetch(`${v0Url}/companies`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editCompany = async (data) => {
  return fetch(`${v0Url}/companies/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteCompany = async (companyId) => {
  try {
    const resp = await fetch(`${v0Url}/companies/${companyId}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

//BUSINESS LINES
export const getBusinessLines = async () => {
   return fetch(`${v0Url}/businessareas`, {
     method: 'GET',
     headers: {
       ...getDefaultHeaders(),
     },
   }).then((resp) => handleIsUnauthenticated(resp));
};

//COMPANY BUSINESS LINES
export const getCompanyBusinessLines = async (companyId) => {
  return fetch(`${v0Url}/companies/${companyId}/companybusinesslines`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const createCompanyBusinessLine = async (companyId, data) => {
  try {
    const resp = await fetch(`${v0Url}/companies/${companyId}/companybusinesslines`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editCompanyBusinessLine = async (companyId, data) => {
  return fetch(`${v0Url}/companies/${companyId}/companybusinesslines/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteCompanyBusinessLine = async (companyId, itemId) => {
  try {
    const resp = await fetch(`${v0Url}/companies/${companyId}/companybusinesslines/${itemId}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

//COMPANY BUSINESS AREAS
export const getCompanyBusinessAreas = async (companyId) => {
  return fetch(`${v0Url}/companies/${companyId}/companybusinessareas`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const createCompanyBusinessArea = async (companyId, data) => {
  try {
    const resp = await fetch(`${v0Url}/companies/${companyId}/companybusinessareas`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editCompanyBusinessArea = async (companyId, data) => {
  return fetch(`${v0Url}/companies/${companyId}/companybusinessareas/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteCompanyBusinessArea = async (companyId, itemId) => {
  try {
    const resp = await fetch(`${v0Url}/companies/${companyId}/companybusinessareas/${itemId}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

//FUNDS
export const getFunds = async (refTable) => {
  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/funds`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const editFund = async (fundId, data) => {
  return fetch(`${v0Url}/funds/${fundId}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getFundTaxonomies = async () => {
  return fetch(`${baseUrl}/oefundtaxonomy`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getFundDocs = async () => {
  return fetch(`${baseUrl}/oefunddocuments`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

//CHECKLIST QUESTION SETS
export const getQuestionSets = async (refTable, isPerPage = true) => {
   if (isPerPage) {
      return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/questionsets`), {
         method: 'GET',
         headers: {
           ...getDefaultHeaders(),
         },
       }).then((resp) => handleIsUnauthenticated(resp));
   } else {
      return fetch(`${v0Url}/questionsets?perPage=false`, {
         method: 'GET',
         headers: {
           ...getDefaultHeaders(),
         },
      }).then((resp) => handleIsUnauthenticated(resp));
   }
};

export const getQuestionSet = async (questionsetId) => {
   return fetch(`${v0Url}/questionsets/${questionsetId}`, {
     method: 'GET',
     headers: {
       ...getDefaultHeaders(),
     },
   }).then((resp) => handleIsUnauthenticated(resp));
};

export const createQuestionSet = async (data) => {
   try {
     const resp = await fetch(`${v0Url}/questionsets`, {
       method: 'POST',
       headers: {
         ...getDefaultHeaders(),
         accept: 'application/json',
       },
       body: JSON.stringify(data),
     }).then((resp) => handleIsUnauthenticated(resp));
 
     //console.log('resp', resp);
     return resp;
   } catch (err) {
     return err;
   }
};

export const editQuestionSet = async (questionsetId, data, isEditingArray) => {
   return fetch(`${v0Url}/questionsets/${questionsetId}${isEditingArray ? '/sync' : ''}`, {
     method: 'PUT',
     headers: {
       ...getDefaultHeaders(),
     },
     body: JSON.stringify(data),
   }).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteQuestionSet = async (questionsetId) => {
   try {
     const resp = await fetch(`${v0Url}/questionsets/${questionsetId}`, {
       method: 'DELETE',
       headers: {
         ...getDefaultHeaders(),
       },
     }).then((resp) => handleIsUnauthenticated(resp));
 
     return resp;
   } catch (err) {
     return err;
   }
};

//EDIT QUESTION SET FOR NOTICE AND TENDER
export const editQuestionSetByEntity = async (entityType, entityId, questionSetId) => {
   //notices or tenders
   return fetch(`${v0Url}/${entityType}/${entityId}/questionsets/${questionSetId}/attach`, {
     method: 'PUT',
     headers: {
       ...getDefaultHeaders(),
     },
     //body: JSON.stringify(data),
   }).then((resp) => handleIsUnauthenticated(resp));
};

//OECOMPANY
export const getCompanySizes = async () => {
  return fetch(`${baseUrl}/oecompanysizes`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

//GROUPS FOR PROJECTS
export const getProjectGroups = async (companyId) => {
   return fetch(`${v0Url}/companies/${companyId}/groups`, {
     method: 'GET',
     headers: {
       ...getDefaultHeaders(),
     },
   }).then((resp) => handleIsUnauthenticated(resp));
 };

//REGIONS
export const getRegions = async () => {
  return fetch(`${baseUrl}/oeregions`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

//COMUNI
export const getLocations = async () => {
  return fetch(`${baseUrl}/oelocations`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

//INSTRUMENTS
export const getInstruments = async (refTable) => {
  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/instruments`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const createInstrument = async (data) => {
  try {
    const resp = await fetch(`${v0Url}/instruments`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editInstrument = async (data) => {
  return fetch(`${v0Url}/instruments/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteInstrument = async (instrumentId) => {
  try {
    const resp = await fetch(`${v0Url}/instruments/${instrumentId}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

export const getInstrumentDocs = async () => {
  const r = await fetch(`${baseUrl}/oeinstrumentdocuments`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  });
  return await handleIsUnauthenticated(r);
};

//INSTITUTIONS
export const getInstitutions = async (refTable) => {
  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/institutions`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const createInstitution = async (data) => {
  try {
    const resp = await fetch(`${v0Url}/institutions`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editInstitution = async (data) => {
  return fetch(`${v0Url}/institutions/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteInstitution = async (institutionId) => {
  try {
    const resp = await fetch(`${v0Url}/institutions/${institutionId}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

//NOTICES
export const getNotices = async (refTable) => {
  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/notices`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getNotice = async (id) => {
  return fetch(`${v0Url}/notices/${id}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const createNotice = async (data) => {
  try {
    const resp = await fetch(`${v0Url}/notices`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editNotice = async (data) => {
  return fetch(`${v0Url}/notices/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteNotice = async (noticeId) => {
  try {
    const resp = await fetch(`${v0Url}/notices/${noticeId}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

//NOTICE CONTACT PERSON
export const getNoticesContactPerson = async (noticeId) => {
  return fetch(`${v0Url}/notices/${noticeId}/noticecontactpersons`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const createNoticeContactPerson = async (noticeId, data) => {
  try {
    const resp = await fetch(`${v0Url}/notices/${noticeId}/noticecontactpersons`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editNoticeContactPerson = async (noticeId, data) => {
  return fetch(`${v0Url}/notices/${noticeId}/noticecontactpersons/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteNoticeContactPerson = async (noticeId, contactPersonId) => {
  try {
    const resp = await fetch(
      `${v0Url}/notices/${noticeId}/noticecontactpersons/${contactPersonId}`,
      {
        method: 'DELETE',
        headers: {
          ...getDefaultHeaders(),
        },
      }
    ).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

// Documenti del Rendiconto
export const getNoticesDocumentsOfAccountability = async (noticeId) => {
  return fetch(`${v0Url}/notices/${noticeId}/noticestatementdocuments`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
}

export const createNoticesDocumentsOfAccountability = async (noticeId, data) => {
  try {
    const resp = await fetch(`${v0Url}/notices/${noticeId}/noticestatementdocuments`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
}

export const editNoticesDocumentsOfAccountability = async (noticeId, data) => {
  return fetch(`${v0Url}/notices/${noticeId}/noticestatementdocuments/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  })
  .then((resp) => handleIsUnauthenticated(resp));
}

export const deleteNoticesDocumentsOfAccountability = async (noticeId, documentId) => {
  try {
    const resp = await fetch(`${v0Url}/notices/${noticeId}/noticestatementdocuments/${documentId}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    })
    .then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
}


//NOTICE TARGETS
export const getNoticesTargets = async (noticeId) => {
  return fetch(`${v0Url}/notices/${noticeId}/noticetargets`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const createNoticeTarget = async (noticeId, data) => {
  try {
    const resp = await fetch(`${v0Url}/notices/${noticeId}/noticetargets`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editNoticeTarget = async (noticeId, data) => {
  return fetch(`${v0Url}/notices/${noticeId}/noticetargets/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteNoticeTarget = async (noticeId, targetId) => {
  try {
    const resp = await fetch(`${v0Url}/notices/${noticeId}/noticetargets/${targetId}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

//NOTICE EXPENSE ITEMS
export const getNoticesExpenseItems = async (noticeId) => {
  return fetch(`${v0Url}/notices/${noticeId}/noticeexpenseitems`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const createNoticeExpenseItems = async (noticeId, data) => {
  try {
    const resp = await fetch(`${v0Url}/notices/${noticeId}/noticeexpenseitems`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editNoticeExpenseItems = async (noticeId, data) => {
  return fetch(`${v0Url}/notices/${noticeId}/noticeexpenseitems/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteNoticeExpenseItems = async (noticeId, itemId) => {
  try {
    const resp = await fetch(`${v0Url}/notices/${noticeId}/noticeexpenseitems/${itemId}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

//NOTICE SUB EXPENSE ITEMS
export const getNoticesSubExpenseItems = async (noticeId, expenseItemId) => {
  return fetch(
    `${v0Url}/notices/${noticeId}/noticeexpenseitems/${expenseItemId}/noticesubexpenseitems`,
    {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
      },
    }
  ).then((resp) => handleIsUnauthenticated(resp));
};

export const createNoticeSubExpenseItems = async (noticeId, expenseItemId, data) => {
  try {
    const resp = await fetch(
      `${v0Url}/notices/${noticeId}/noticeexpenseitems/${expenseItemId}/noticesubexpenseitems/`,
      {
        method: 'POST',
        headers: {
          ...getDefaultHeaders(),
          accept: 'application/json',
        },
        body: JSON.stringify(data),
      }
    ).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editNoticeSubExpenseItems = async (noticeId, expenseItemId, data) => {
  return fetch(
    `${v0Url}/notices/${noticeId}/noticeexpenseitems/${expenseItemId}/noticesubexpenseitems/${data.id}`,
    {
      method: 'PUT',
      headers: {
        ...getDefaultHeaders(),
      },
      body: JSON.stringify(data),
    }
  ).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteNoticeSubExpenseItems = async (noticeId, expenseItemId, subExpenseItemId) => {
  try {
    const resp = await fetch(
      `${v0Url}/notices/${noticeId}/noticeexpenseitems/${expenseItemId}/noticesubexpenseitems/${subExpenseItemId}`,
      {
        method: 'DELETE',
        headers: {
          ...getDefaultHeaders(),
        },
      }
    ).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

//NOTICE ENGAGEMENT RULES
export const getNoticesEngagementRules = async (noticeId) => {
  return fetch(`${v0Url}/notices/${noticeId}/noticeengagementrules`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const createNoticeEngagementRules = async (noticeId, data) => {
  try {
    const resp = await fetch(`${v0Url}/notices/${noticeId}/noticeengagementrules`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editNoticeEngagementRules = async (noticeId, data) => {
  return fetch(`${v0Url}/notices/${noticeId}/noticeengagementrules/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteNoticeEngagementRules = async (noticeId, ruleId) => {
  try {
    const resp = await fetch(`${v0Url}/notices/${noticeId}/noticeengagementrules/${ruleId}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

export const getModalData = async (url) => {
  return fetch(`${url}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};
//TENDERS
export const getTenders = async (queryParamsString, refTable) => {
  queryParamsString = queryParamsString ?? '';

  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/tenders${queryParamsString}`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getTender = async (id) => {
   return fetch(`${v0Url}/tenders/${id}`, {
     method: 'GET',
     headers: {
       ...getDefaultHeaders(),
     },
   }).then((resp) => handleIsUnauthenticated(resp));
};

export const createTender = async (data) => {
  try {
    const resp = await fetch(`${v0Url}/tenders`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editTender = async (data) => {
  return fetch(`${v0Url}/tenders/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteTender = async (tenderId) => {
  try {
    const resp = await fetch(`${v0Url}/tenders/${tenderId}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

export const getTenderCigs = async (tenderId) => {
  return fetch(`${v0Url}/tenders/${tenderId}/tendercigs`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const createTenderCig = async (tenderId, data) => {
  try {
    const resp = await fetch(`${v0Url}/tenders/${tenderId}/tendercigs`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editTenderCig = async (tenderId, data) => {
  return fetch(`${v0Url}/tenders/${tenderId}/tendercigs/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteTenderCig = async (tenderId, cigId) => {
  try {
    const resp = await fetch(`${v0Url}/tenders/${tenderId}/tendercigs/${cigId}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

export const getTenderProcedures = async () => {
  return fetch(`${baseUrl}/oetenderprocedures`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getTenderClassifications = async () => {
  return fetch(`${baseUrl}/oetenderclassifications`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getTenderCriteria = async () => {
  return fetch(`${baseUrl}/oetendercriteria`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

//USERS
export const getUsers = async (queryParamsString, refTable) => {
  queryParamsString = queryParamsString ?? '';

  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/users${queryParamsString}`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getUser = async (userId) => {
  return fetch(`${v0Url}/users/${userId}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const createUser = async (data) => {
  try {
    const resp = await fetch(`${v0Url}/users`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editUser = async (data) => {
  return fetch(`${v0Url}/users/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteUser = async (userId) => {
  try {
    const resp = await fetch(`${v0Url}/users/${userId}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

export const getOePnrrTaxonomyValues = async () => {
  return fetch(`${baseUrl}/oepnrrtaxonomyvalues`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

//CHECKLIST
export const getOEChecklistQuestions = async (entityType, isPerPage) => {
   let string = '';
   
   if (entityType && isPerPage) {
      string = `?entity_type=${entityType}&per_page=false`;
   } else if (entityType) {
      string = `?entity_type=${entityType}`;
   } else if (isPerPage) {
      string = '?per_page=false';
   }

  return fetch(`${baseUrl}/oechecklistquestions${string}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getOEChecklistQuestionsByEntity = async (entityType) => {
   let string = '';
   
   if (entityType === 'tenders') {
      string = `?entityType[]=tenders&entityType[]=frameworkContracts`;
   } else if (entityType === 'notices') {
      string = `?entityType[]=contracts&entityType[]=invoices&entityType[]=invoicepayments&entityType[]=timesheets`;
   }

  return fetch(`${baseUrl}/oechecklistquestions${string}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getChecklist = async (entityType, entityId) => {
  return fetch(`${v0Url}/checklists/${entityType}/${entityId}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getChecklistById = async (checklistId) => {
  return fetch(`${v0Url}/checklists/${checklistId}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const editChecklist = async (checklistId, data) => {
  return fetch(`${v0Url}/checklists/${checklistId}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp));
};

//OLD
/* export const createUserOLD = async (email, password, loginType) => {
   const data = { email, password, loginType };
   //console.log('data', data);

   try {
      const resp = await fetch(`${baseUrl}/register/`, {
         method: 'POST',
         headers: {
            ...getDefaultHeaders(),
            accept: 'application/json',
         },
         body: JSON.stringify(data),
      }).then(resp => handleIsUnauthenticated(resp));

      console.log('resp', resp);
      return resp;
   } catch (err) {
      console.log('err', err);
      return err;
   }
};
 */

export const getProjects = async (queryParamsString, refTable) => {
   queryParamsString = queryParamsString ?? '';
   //console.log('refTable', refTable);
  try {
    const resp = await fetch(handleLoadPerPageSaved(refTable, `${v0Url}/projects${queryParamsString}`), {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const getProject = async (id) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${id}`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const createProject = async (data) => {
  try {
    const resp = await fetch(`${v0Url}/projects`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editProject = async (projectId, data) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${projectId}`, {
      method: 'PUT',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const getAllStreams = async (id) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${id}/projectstreams`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
    }).then((resp) => handleIsUnauthenticated(resp));
    return resp;
  } catch (err) {
    return err;
  }
};

export const createStream = async (projectId, data) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${projectId}/projectstreams`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((r) => {
      if (!r.ok) {
        return r.status;
      }
      return handleIsUnauthenticated(r);
    });

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editStream = async (projectId, data) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${projectId}/projectstreams/${data.id}`, {
      method: 'PUT',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const deleteStream = async (projectId, streamId) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${projectId}/projectstreams/${streamId}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const getAllClusters = async (companyId) => {
   try {
     const resp = await fetch(`${v0Url}/companies/${companyId}/companybusinessareas`, {
       method: 'GET',
       headers: {
         ...getDefaultHeaders(),
         accept: 'application/json',
       },
     }).then((resp) => handleIsUnauthenticated(resp));
     return resp;
   } catch (err) {
     return err;
   }
};

export const getProjectClusters = async (projectId) => {
   try {
     const resp = await fetch(`${v0Url}/projects/${projectId}/companybusinessareas`, {
       method: 'GET',
       headers: {
         ...getDefaultHeaders(),
         accept: 'application/json',
       },
     }).then((resp) => handleIsUnauthenticated(resp));
     return resp;
   } catch (err) {
     return err;
   }
};
 
export const editProjectCluster = async (projectId, data) => {
   try {
     const resp = await fetch(`${v0Url}/projects/${projectId}/companybusinessareas`, {
       method: 'PUT',
       headers: {
         ...getDefaultHeaders(),
         accept: 'application/json',
       },
       body: JSON.stringify(data),
     }).then((resp) => handleIsUnauthenticated(resp));
 
     //console.log('resp', resp);
     return resp;
   } catch (err) {
     return err;
   }
};

export const getAllApplicationEconomicFrameworkValues = async (id) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${id}/projectapplicationeconomicframeworkvalues`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
    }).then((resp) => handleIsUnauthenticated(resp));
    return resp;
  } catch (err) {
    return err;
  }
};

export const editApplicationEconomicFrameworkValue = async (projectId, data) => {
  try {
    const resp = await fetch(
      `${v0Url}/projects/${projectId}/projectapplicationeconomicframeworkvalues/${data.id}`,
      {
        method: 'POST',
        headers: {
          ...getDefaultHeaders(),
          accept: 'application/json',
        },
        body: JSON.stringify(data),
      }
    ).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const addMonthEndToFramework = async (projectId, frameworkId) => {
  try {
    const resp = await fetch(
      `${v0Url}/projects/${projectId}/projecteconomicframeworks/${frameworkId}/monthly?to=end`,
      {
        method: 'POST',
        headers: {
          ...getDefaultHeaders(),
          accept: 'application/json',
        },
      }
    ).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};
export const addMonthStartToFramework = async (projectId, frameworkId) => {
  try {
    const resp = await fetch(
      `${v0Url}/projects/${projectId}/projecteconomicframeworks/${frameworkId}/monthly?to=start`,
      {
        method: 'POST',
        headers: {
          ...getDefaultHeaders(),
          accept: 'application/json',
        },
      }
    ).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const deleteMonthStartToFramework = async (projectId, frameworkId) => {
  try {
    const resp = await fetch(
      `${v0Url}/projects/${projectId}/projecteconomicframeworks/${frameworkId}/monthly?from=start`,
      {
        method: 'DELETE',
        headers: {
          ...getDefaultHeaders(),
          accept: 'application/json',
        },
      }
    ).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const deleteMonthEndToFramework = async (projectId, frameworkId) => {
  try {
    const resp = await fetch(
      `${v0Url}/projects/${projectId}/projecteconomicframeworks/${frameworkId}/monthly?from=end`,
      {
        method: 'DELETE',
        headers: {
          ...getDefaultHeaders(),
          accept: 'application/json',
        },
      }
    ).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const getAllProjectTarget = async (id) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${id}/projecttargets`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
    }).then((resp) => handleIsUnauthenticated(resp));
    return resp;
  } catch (err) {
    return err;
  }
};

export const createProjectTarget = async (projectId, data) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${projectId}/projecttargets`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((r) => {
      if (!r.ok) {
        return r.status;
      }
      return handleIsUnauthenticated(r);
    });

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editProjectTarget = async (projectId, data) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${projectId}/projecttargets/${data.id}`, {
      method: 'PUT',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const deleteProjectTarget = async (projectId, id) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${projectId}/projecttargets/${id}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const getOEProjectTargetReasons = async () => {
  return fetch(`${baseUrl}/oeprojecttargetreasons`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getAllProjectNoticeExpenseItems = async (id) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${id}/projectnoticeexpenseitems`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
    }).then((resp) => handleIsUnauthenticated(resp));
    return resp;
  } catch (err) {
    return err;
  }
};

export const getAllProjectExpenseItems = async (id, projectNoticeExpenseItemId) => {
  try {
    const resp = await fetch(
      `${v0Url}/projects/${id}/projectnoticeexpenseitems/${projectNoticeExpenseItemId}/projectexpenseitems`,
      {
        method: 'GET',
        headers: {
          ...getDefaultHeaders(),
          accept: 'application/json',
        },
      }
    ).then((resp) => handleIsUnauthenticated(resp));
    return resp;
  } catch (err) {
    return err;
  }
};

export const createProjectExpenseItems = async (projectId, data) => {
  console.log(data);
  try {
    const resp = await fetch(
      `${v0Url}/projects/${projectId}/projectnoticeexpenseitems/${data.projectNoticeExpenseItemId}/projectexpenseitems/`,
      {
        method: 'POST',
        headers: {
          ...getDefaultHeaders(),
          accept: 'application/json',
        },
        body: JSON.stringify(data),
      }
    ).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editProjectExpenseItems = async (projectId, data) => {
  //console.log('data', data)
  try {
    const resp = await fetch(
      `${v0Url}/projects/${projectId}/projectnoticeexpenseitems/${data.projectNoticeExpenseItemId}/projectexpenseitems/${data.id}`,
      {
        method: 'PUT',
        headers: {
          ...getDefaultHeaders(),
          accept: 'application/json',
        },
        body: JSON.stringify(data),
      }
    ).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const deleteProjectExpenseItems = async (projectId, data) => {
  try {
    const resp = await fetch(
      `${v0Url}/projects/${projectId}/projectnoticeexpenseitems/${data.projectNoticeExpenseItemId}/projectexpenseitems/${data.id}`,
      {
        method: 'DELETE',
        headers: {
          ...getDefaultHeaders(),
          accept: 'application/json',
        },
        body: JSON.stringify(data),
      }
    ).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const getProjectFrameworks = async (id) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${id}/projecteconomicframeworks`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
    }).then((resp) => handleIsUnauthenticated(resp));
    return resp;
  } catch (err) {
    return err;
  }
};

/* export const updateFrameworkValues = async (projectId, frameworkId, valueId, value) => {
   try {
      const resp = await fetch(`${v0Url}/projects/${projectId}/projecteconomicframeworks/${frameworkId}/projecteconomicframeworkvalues/${valueId}`, {
         method: 'POST',
         headers: {
            ...getDefaultHeaders(),
            accept: 'application/json',
         },
         body: JSON.stringify({ _method: 'PUT', value: value }),
      }).then(resp => handleIsUnauthenticated(resp));

      //console.log('resp', resp);
      return resp;
   } catch (err) {
      return err;
   }
}; */

export const updateFrameworkValues = async (projectId, frameworkId, data) => {
  try {
    const resp = await fetch(
      `${v0Url}/projects/${projectId}/projecteconomicframeworks/${frameworkId}/projecteconomicframeworkvalues`,
      {
        method: 'PUT',
        headers: {
          ...getDefaultHeaders(),
          accept: 'application/json',
        },
        body: JSON.stringify({ values: data }),
      }
    ).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const createProjectFrameworks = async (data) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${data.projectId}/projecteconomicframeworks/`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const getProjectCompanyTenders = async (id, isPerPage = false) => {
  try {
    const resp = await fetch(`${v0Url}/tenders?companyId=${id}${isPerPage ? '&per_page=false' : ''}`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
    }).then((resp) => handleIsUnauthenticated(resp));
    return resp;
  } catch (err) {
    return err;
  }
};

export const getDocuments = async (entityType, entityId) => {
  return fetch(`${v0Url}/documents/${entityType}/${entityId}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getDocumentsStaging = async (entityType, taskEntity, entityId, refTable) => {
  return fetch(handleLoadPerPageSaved(refTable, 
      `${v0Url}/documentsstaging?entityType=${entityType}&taskEntity=${taskEntity}${entityId ? `&entityId=${entityId}` : ''}`
   ),
    {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
      },
    }
  ).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteDocumentsStaging = async (data, entityType, taskEntity, entityId) => {
  try {
    const resp = await fetch(
      `${v0Url}/documentsstaging?entityType=${entityType}${
        taskEntity ? `&taskEntity=${taskEntity}` : ''
      }${entityId ? `&entityId=${entityId}` : ''}`,
      {
        method: 'DELETE',
        headers: {
          ...getDefaultHeaders(),
          accept: 'application/json',
        },
        body: JSON.stringify(data),
      }
    ).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const getLastBatch = async (entityType, taskEntity, taskType, entityId) => {
  return fetch(
    `${v0Url}/lastbatch?entityType=${entityType}&taskEntity=${taskEntity}${
      taskType ? `&taskType=${taskType}` : ''
    }${entityId ? `&entityId=${entityId}` : ''}`,
    {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
      },
    }
  ).then((resp) => handleIsUnauthenticated(resp));
};

export const uploadDocument = async (
  entityType,
  entityId,
  code,
  file,
  matchMissingFile = null,
  id = null
) => {
  let formData = new FormData();
  console.log('files', file);
  //let filesList = Array.from(files);
  /*
    files.forEach((file) => {
       formData.append("file", file);
     });
    */
  formData.append('file', file);
  formData.append('entityType', entityType);
  formData.append('entityId', entityId);
  formData.append('documentCode', code);
  let url = `${v0Url}/documents/${entityType}/${entityId}`;
  if (matchMissingFile) {
    url += '?matchMissingFile=true';
    formData.append('id', id);
  }
  try {
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        ...getNoJsonHeaders(),
      },
      body: formData,
    }).then((r) => {
      if (r.status === 409) {
        if (window.confirm('Il file "' + file.name + '" è già presente. Vuoi sovrascriverlo?')) {
          return fetch(url + (matchMissingFile ? '&' : '?') + 'overwrite=true', {
            method: 'POST',
            headers: {
              ...getNoJsonHeaders(),
            },
            body: formData,
          }).then((resp) => handleIsUnauthenticated(resp));
        }
      }
      if (r.status === 406) {
        return handleIsUnauthenticated(r);
        //throw new Error('Il file non ha lo stesso nome')
      }
      return handleIsUnauthenticated(r);
    });

    console.log('resp', resp);
    return resp;
  } catch (err) {
    console.log('err', err);
    return err;
  }
};

export const uploadFrameworkContract = async (tenderId, file) => {
  let formData = new FormData();
  formData.append('file', file);
  formData.append('fileType', 'xlsx');
  try {
    const resp = await fetch(`${v0Url}/tenders/${tenderId}/frameworkcontracts/import`, {
      method: 'POST',
      headers: {
        ...getNoJsonHeaders(),
      },
      body: formData,
    }).then((resp) => handleIsUnauthenticated(resp));

    console.log('resp', resp);
    return resp;
  } catch (err) {
    console.log('err', err);
    return err;
  }
};

export const uploadFrameworkContractDocuments = async (tenderId, file) => {
  let formData = new FormData();
  formData.append('file', file);
  formData.append('fileType', 'zip');
  try {
    const resp = await fetch(`${v0Url}/tenders/${tenderId}/frameworkcontracts/documents`, {
      method: 'POST',
      headers: {
        ...getNoJsonHeaders(),
      },
      body: formData,
    }).then((resp) => handleIsUnauthenticated(resp));

    console.log('resp', resp);
    return resp;
  } catch (err) {
    console.log('err', err);
    return err;
  }
};

export const getFrameworkContractsStaging = async (tenderId) => {
  return fetch(`${v0Url}/frameworkcontractsstaging?tenderId=${tenderId}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getFrameworkContractsStagingAll = async (refTable) => {
  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/allframeworkcontractsstaging`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getFrameworkContractsStagingBatches = async (batchId) => {
  return fetch(`${v0Url}/frameworkcontractsstaging?batchId=${batchId}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const downloadDocument = async (doc) => {
  var file = doc;
  return fetch(`${v0Url}/documents/${file.id}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
      //'Content-Type': 'application/vnd.ms-excel',
    },
  })
    .then((res) => res.blob())
    .then((blob) => {
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = file.fileName;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
      return { success: true };
    });
};

export const deleteDocument = async (doc) => {
  var file = doc;
  try {
    const resp = fetch(`${v0Url}/documents/${file.id}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    console.log('resp', resp);
    return resp;
  } catch (err) {
    console.log('err', err);
    return err;
  }
};

export const uploadDocumentByDocumentId = async (documentId, file) => {
  let formData = new FormData();
  formData.append('file', file);
  try {
    const resp = await fetch(`${v0Url}/documents/upload/${documentId}`, {
      method: 'POST',
      headers: {
        ...getNoJsonHeaders(),
      },
      body: formData,
    }).then((resp) => handleIsUnauthenticated(resp));

    console.log('resp', resp);
    return resp;
  } catch (err) {
    console.log('err', err);
    return err;
  }
};

export const getOeDocuments = async (entityType, projectId) => {
  return fetch(`${baseUrl}/oedocuments?entityType=${entityType}${projectId ? '&projectId=' + projectId : ''}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getProjectExpenseItemsStreamConfig = async (projectId) => {
  return fetch(`${v0Url}/projects/${projectId}/projectexpenseitemstreamconfigurations`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getProjectTenders = async (projectId) => {
  return fetch(`${v0Url}/projects/${projectId}/tenders`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const editProjectTenders = async (projectId, data) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${projectId}/tenders`, {
      method: 'PUT',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const getProjectContracts = async (projectId) => {
  return fetch(`${v0Url}/contracts?projectId=${projectId}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getFrameworkContracts = async (refTable, tenderId) => {
  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/tenders/${tenderId}/frameworkcontracts`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const projectMassiveUpload = async (entityType, projectId, file, importType) => {
  let formData = new FormData();
  formData.append('file', file);
  formData.append('fileType', 'xlsx');
  formData.append('importType', importType);
  try {
    const resp = await fetch(`${v0Url}/projects/${projectId}/${entityType}/import`, {
      method: 'POST',
      headers: {
        ...getNoJsonHeaders(),
      },
      body: formData,
    }).then((resp) => handleIsUnauthenticated(resp));

    console.log('resp', resp);
    return resp;
  } catch (err) {
    console.log('err', err);
    return err;
  }
};

export const projectMassiveUploadDocuments = async (entityType, projectId, file) => {
  let formData = new FormData();
  formData.append('file', file);
  formData.append('fileType', 'zip');
  try {
    const resp = await fetch(`${v0Url}/projects/${projectId}/${entityType}/documents`, {
      method: 'POST',
      headers: {
        ...getNoJsonHeaders(),
      },
      body: formData,
    }).then((resp) => handleIsUnauthenticated(resp));

    console.log('resp', resp);
    return resp;
  } catch (err) {
    console.log('err', err);
    return err;
  }
};

export const getEntityList = async (entityType, projectId, refTable) => {
  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/projects/${projectId}/${entityType}`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getEntity = async (entityType, projectId, entityId) => {
   return fetch(`${v0Url}/projects/${projectId}/${entityType}/${entityId}`, {
     method: 'GET',
     headers: {
       ...getDefaultHeaders(),
     },
   }).then((resp) => handleIsUnauthenticated(resp));
};

//PAYMENTS
export const getPaymentsByInfo = async (projectId, refTable) => {
  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/payments?projectId=${projectId}`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getPaymentByInfo = async (projectId, paymentId) => {
   return fetch(`${v0Url}/payments/${paymentId}?projectId=${projectId}`, {
     method: 'GET',
     headers: {
       ...getDefaultHeaders(),
     },
   }).then((resp) => handleIsUnauthenticated(resp));
};

export const getStagingTable = async (entityType, refTable) => {
  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/${entityType}staging`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getProjectStagingTable = async (entityType, projectId, tasktype) => {
  return fetch(`${v0Url}/${entityType}staging?projectId=${projectId}&taskType=${tasktype}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

// export const getProjectStagingTable = async (entityType, projectId, tasktype, queryParams = '') => {
//   return fetch(
//     `${v0Url}/${entityType}staging?projectId=${projectId}&taskType=${tasktype}${queryParams}`,
//     {
//       method: 'GET',
//       headers: {
//         ...getDefaultHeaders(),
//       },
//     }
//   ).then((resp) => handleIsUnauthenticated(resp));
// };

export const projectUploadChecklist = async (entityType, projectId, file) => {
  //console.log('test', entityType, projectId);
  let formData = new FormData();
  formData.append('file', file);
  formData.append('fileType', 'xlsx');
  try {
    // /projects/{project_id}/checklists/timesheets/import
    const resp = await fetch(`${v0Url}/projects/${projectId}/checklists/${entityType}/import`, {
      method: 'POST',
      headers: {
        ...getNoJsonHeaders(),
      },
      body: formData,
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    //console.log('err', err);
    return err;
  }
};

export const projectUploadChecklistPayments = async (file, invoiceType) => {
  let formData = new FormData();
  formData.append('file', file);
  formData.append('fileType', 'xlsx');

  try {
    const resp = await fetch(`${v0Url}/checklists/${invoiceType}/import`, {
      method: 'POST',
      headers: {
        ...getNoJsonHeaders(),
      },
      body: formData,
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    //console.log('err', err);
    return err;
  }
};

export const getProjectChecklistStagingTable = async (entityType, projectId, refTable) => {
  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/checklistsstaging?entityType=${entityType}&projectId=${projectId}`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const tendersListUploadChecklist = async (entityType, file) => {
  let formData = new FormData();
  formData.append('file', file);
  formData.append('fileType', 'xlsx');
  try {
    const resp = await fetch(`${v0Url}/checklists/${entityType}/import`, {
      method: 'POST',
      headers: {
        ...getNoJsonHeaders(),
      },
      body: formData,
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    //console.log('err', err);
    return err;
  }
};

export const getChecklistStagingTable = async (entityType, refTable) => {
  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/checklistsstaging?entityType=${entityType}`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getBatchesByInfo = async (entityType, entityId) => {
  if (entityId) {
    return fetch(`${v0Url}/batches?entityType=${entityType}&entityId=${entityId}`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));
  } else {
    return fetch(`${v0Url}/batches?entityType=${entityType}`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));
  }
};

export const uploadTendersList = async (entityType, file) => {
  //console.log('test', entityType);
  let formData = new FormData();
  formData.append('file', file);
  formData.append('fileType', 'xlsx');
  try {
    // /tenders/import
    const resp = await fetch(`${v0Url}/${entityType}/import`, {
      method: 'POST',
      headers: {
        ...getNoJsonHeaders(),
      },
      body: formData,
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    //console.log('err', err);
    return err;
  }
};

export const uploadFrameworkContracts = async (entityType, file) => {
  //console.log('test', entityType);
  let formData = new FormData();
  formData.append('file', file);
  formData.append('fileType', 'xlsx');
  try {
    // /tenders/import
    const resp = await fetch(`${v0Url}/${entityType}/import`, {
      method: 'POST',
      headers: {
        ...getNoJsonHeaders(),
      },
      body: formData,
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    //console.log('err', err);
    return err;
  }
};

export const uploadDocumentsTenders = async (entityType, file) => {
  let formData = new FormData();
  formData.append('file', file);
  formData.append('fileType', 'zip');
  try {
    const resp = await fetch(`${v0Url}/${entityType}/documents`, {
      method: 'POST',
      headers: {
        ...getNoJsonHeaders(),
      },
      body: formData,
    }).then((resp) => handleIsUnauthenticated(resp));

    console.log('resp', resp);
    return resp;
  } catch (err) {
    console.log('err', err);
    return err;
  }
};

export const getProjectResumeInvoices = async (projectId, queryParamsString, refTable) => {
  queryParamsString = queryParamsString ?? '';

  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/projects/${projectId}/resumeinvoices${queryParamsString}`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getProjectResumeTimesheets = async (projectId, queryParamsString, refTable) => {
  queryParamsString = queryParamsString ?? '';

  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/projects/${projectId}/resumetimesheets${queryParamsString}`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getProjectResumeTotals = async (projectId) => {
  return fetch(`${v0Url}/projects/${projectId}/resumetotals`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

//STATEMENTS
export const getOEStatementTypes = async () => {
  return fetch(`${baseUrl}/oestatementtypes`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getProjectStatements = async (projectId) => {
  return fetch(`${v0Url}/projects/${projectId}/statements`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getProjectStatement = async (projectId, statementId) => {
  return fetch(`${v0Url}/projects/${projectId}/statements/${statementId}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getProjectStatementsByState = async (projectId, state, refTable) => {
  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/projects/${projectId}/statements?state=${state}`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const createProjectStatement = async (projectId, data) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${projectId}/statements`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editProjectStatement = async (projectId, data) => {
  return fetch(`${v0Url}/projects/${projectId}/statements/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteStatement = async (projectId, statementId) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${projectId}/statements/${statementId}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

export const getStatementInvoicesOrTimesheets = async (projectId, statementId, type, refTable) => {
  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/projects/${projectId}/statements/${statementId}/${type}`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getStatementAnalysisInvoices = async (projectId, statementId, queryParamsString, refTable) => {
   return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/projects/${projectId}/statements/${statementId}/invoices?analysis=1&${queryParamsString}`), {
     method: 'GET',
     headers: {
       ...getDefaultHeaders(),
     },
   }).then((resp) => handleIsUnauthenticated(resp));
};

export const getToSettleChecklistFilter = async (projectId, statementId, queryParamsString) => {
   return fetch(`${v0Url}/projects/${projectId}/statements/${statementId}/analysissettlefilter?${queryParamsString}`, {
     method: 'GET',
     headers: {
       ...getDefaultHeaders(),
     },
   }).then((resp) => handleIsUnauthenticated(resp));
};

export const downloadProjectStatementInvoices = async ([projectId, statementId]) => {
   try {
     const response = await fetch(`${v0Url}/projects/${projectId}/statements/${statementId}/invoices/print`, {
       method: 'GET',
       headers: {
         ...getDefaultHeaders(),
         // 'Content-Type': 'application/vnd.ms-excel',
       },
     });
 
     if (!response.ok) {
       throw new Error('Network response was not ok');
     }
 
     const filenameString = response?.headers?.get('Content-Disposition');
 
     let fileName = filenameString.split('filename=')[1].split(';')[0];
 
     const blob = await response.blob();
     var a = document.createElement('a');
     a.href = window.URL.createObjectURL(blob);
     a.download = fileName;
     document.body.appendChild(a);
     a.click();
     a.remove();
     return { success: true };
   } catch (error) {
     console.error('Error downloading project document:', error);
     return { success: false, message: error.message };
   }
};

export const settleAnalysisInvoices = async (projectId, statementId, data) => {
   try {
     const resp = await fetch(`${v0Url}/projects/${projectId}/statements/${statementId}/invoices/settle`, {
       method: 'POST',
       headers: {
         ...getDefaultHeaders(),
         accept: 'application/json',
       },
       body: JSON.stringify(data),
     }).then((resp) => handleIsUnauthenticated(resp));
 
     //console.log('resp', resp);
     return resp;
   } catch (err) {
     return err;
   }
};

export const getToSettleChecklistDetail = async (projectId, statementId, quotaId) => {
   return fetch(`${v0Url}/projects/${projectId}/statements/${statementId}/invoices/settle/quota/${quotaId}`, {
     method: 'GET',
     headers: {
       ...getDefaultHeaders(),
     },
   }).then((resp) => handleIsUnauthenticated(resp));
};

export const settleChecklistAnswers = async (data) => {
   try {
     const resp = await fetch(`${v0Url}/checklistanswers/settle`,
       {
         method: 'POST',
         headers: {
           ...getDefaultHeaders(),
           accept: 'application/json',
         },
         body: JSON.stringify(data),
       }
     ).then((resp) => handleIsUnauthenticated(resp));
 
     //console.log('resp', resp);
     return resp;
   } catch (err) {
     return err;
   }
 };

export const refreshProjectStats = async (projectId) => {
   try {
     const resp = await fetch(`${v0Url}/projects/${projectId}/refreshstats`, {
       method: 'POST',
       headers: {
         ...getDefaultHeaders(),
         accept: 'application/json',
       },
     }).then((resp) => handleIsUnauthenticated(resp));
 
     //console.log('resp', resp);
     return resp;
   } catch (err) {
     return err;
   }
};

export const pickUnpickProjectStatement = async (
  projectId,
  statementId,
  type,
  pickUnpick,
  data
) => {
  try {
    const resp = await fetch(
      `${v0Url}/projects/${projectId}/statements/${statementId}/${type}/${pickUnpick}`,
      {
        method: 'POST',
        headers: {
          ...getDefaultHeaders(),
          accept: 'application/json',
        },
        body: JSON.stringify(data),
      }
    ).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const pickUnpickAllProjectStatement = async (projectId, statementId, type, pickUnpick) => {
  try {
    const resp = await fetch(
      `${v0Url}/projects/${projectId}/statements/${statementId}/${type}/${pickUnpick}`,
      {
        method: 'POST',
        headers: {
          ...getDefaultHeaders(),
          accept: 'application/json',
        },
      }
    ).then((resp) => handleIsUnauthenticated(resp));
    return resp;
  } catch (err) {
    return err;
  }
};

export const getProjectStatementAmounts = async (projectId, statementId) => {
  return fetch(`${v0Url}/projects/${projectId}/statements/${statementId}/total`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getProjectTotalsReportedAmount = async (projectId, queryParamsString) => {
  queryParamsString = queryParamsString ?? '';

  return fetch(`${v0Url}/projects/${projectId}/statements/totals${queryParamsString}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getProjectStatementGeneralExpenses = async (projectId, statementId) => {
  return fetch(`${v0Url}/projects/${projectId}/statements/${statementId}/expenseiteminstances`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

/* export const exportExcel = async () => {
   return fetch(`${v0Url}/projects/export`, {
      method: 'GET',
      headers: {
         ...getDefaultHeaders(),
         'Content-Type': 'application/vnd.ms-excel',
      },
   })
      .then((r) => r.blob())
      .then((blob) => {
         const file = new File([blob], 'prova');
         var fileUrl = window.URL.createObjectURL(file);
         var a = document.createElement('a');
         a.href = fileUrl;
         a.download = 'projects.xlsx';
         document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
         a.click();
         a.remove(); //afterwards we remove the element again
      });
}; */

//DNSH
export const getDnshCompassClimateMitigations = async () => {
  return fetch(`${baseUrl}/oednshcompassclimatemitigation`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};
export const getDnshCompassClimateMitigation = async (query) => {
  return fetch(`${baseUrl}/oednshcompassclimatemitigation?${query}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};
export const getDnshCompassClimateAdaptations = async () => {
  return fetch(`${baseUrl}/oednshcompassclimateadaptation`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getDnshCompassClimateAdaptation = async (query) => {
  return fetch(`${baseUrl}/oednshcompassclimateadaptation?${query}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getDnshElements = async () => {
  return fetch(`${baseUrl}/oednshelements`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};
export const getDnshChecklists = async (projectID) => {
  return fetch(`${v0Url}/dnshchecklists/projects/${projectID}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};
export const editDnshChecklistsAnswer = async (data, dnshChecklistId, dnshChecklistAnswerId) => {
  return fetch(
    `${v0Url}/dnshchecklists/${dnshChecklistId}/dnshchecklistanswers/${dnshChecklistAnswerId} `,
    {
      method: 'PUT',
      headers: {
        ...getDefaultHeaders(),
      },
      body: JSON.stringify(data),
    }
  ).then((resp) => handleIsUnauthenticated(resp));
};

export const getDnshChecklistsPhysical = async (projectID) => {
   return fetch(`${v0Url}/projects/${projectID}/dnshchecklists`, {
     method: 'GET',
     headers: {
       ...getDefaultHeaders(),
     },
   }).then((resp) => handleIsUnauthenticated(resp));
};

export const getOTPlant = async (otplantID) => {
   return fetch(`${v0Url}/otplants/${otplantID}`, {
     method: 'GET',
     headers: {
       ...getDefaultHeaders(),
     },
   }).then((resp) => handleIsUnauthenticated(resp));
};

export const getProjectStats = async (projectID, queryParamsString) => {
  return fetch(`${v0Url}/stats/projects${queryParamsString ? ('?' + queryParamsString) : (projectID ? '/' + projectID : '')}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getProjectDossierStatus = async (projectID) => {
  return fetch(`${v0Url}/projects/${projectID}/dossier?action=status`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

//AVANZAMENTO
export const getFinancialProgresses = async (projectID) => {
  return fetch(`${v0Url}/projects/${projectID}/financialprogresses`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getOEProceduralProgresses = async () => {
  return fetch(`${baseUrl}/oeproceduralprogresses`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getProceduralProgresses = async (projectID) => {
  return fetch(`${v0Url}/projects/${projectID}/proceduralprogresses`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getSectionsStatementsDossier = async (projectID) => {
   return fetch(`${v0Url}/projects/${projectID}/dossier/statements`, {
     method: 'GET',
     headers: {
       ...getDefaultHeaders(),
     },
   }).then((resp) => handleIsUnauthenticated(resp));
};

export const makeProjectDossier = async (projectID) => {
  return fetch(`${v0Url}/projects/${projectID}/dossier`, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const downloadProjectDossier = async (projectID, fileName) => {
  return fetch(`${v0Url}/projects/${projectID}/dossier`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
      //'Content-Type': 'application/vnd.ms-excel',
    },
  })
    .then((res) => res.blob())
    .then((blob) => {
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = fileName;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
      return { success: true };
    });
};

export const createProceduralProgress = async (projectID, data) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${projectID}/proceduralprogresses`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));
    return resp;
  } catch (err) {
    return err;
  }
};

export const editProceduralProgress = async (projectID, proceduralProgressID, data) => {
  return fetch(`${v0Url}/projects/${projectID}/proceduralprogresses/${proceduralProgressID}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteProceduralProgress = async (projectID, proceduralProgressID) => {
  try {
    const resp = await fetch(
      `${v0Url}/projects/${projectID}/proceduralprogresses/${proceduralProgressID}`,
      {
        method: 'DELETE',
        headers: {
          ...getDefaultHeaders(),
        },
      }
    ).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

export const getMilestones = async (projectID) => {
   return fetch(`${v0Url}/projects/${projectID}/projectmilestones`, {
     method: 'GET',
     headers: {
       ...getDefaultHeaders(),
     },
   }).then((resp) => handleIsUnauthenticated(resp));
 };

export const createMilestone = async (projectID, data) => {
   try {
     const resp = await fetch(`${v0Url}/projects/${projectID}/projectmilestones`, {
       method: 'POST',
       headers: {
         ...getDefaultHeaders(),
         accept: 'application/json',
       },
       body: JSON.stringify(data),
     }).then((resp) => handleIsUnauthenticated(resp));
     return resp;
   } catch (err) {
     return err;
   }
 };
 
 export const editMilestone = async (projectID, milestoneID, data) => {
   return fetch(`${v0Url}/projects/${projectID}/projectmilestones/${milestoneID}`, {
     method: 'PUT',
     headers: {
       ...getDefaultHeaders(),
     },
     body: JSON.stringify(data),
   }).then((resp) => handleIsUnauthenticated(resp));
 };
 
 export const deleteMilestone = async (projectID, milestoneID) => {
   try {
     const resp = await fetch(
       `${v0Url}/projects/${projectID}/projectmilestones/${milestoneID}`,
       {
         method: 'DELETE',
         headers: {
           ...getDefaultHeaders(),
         },
       }
     ).then((resp) => handleIsUnauthenticated(resp));
 
     return resp;
   } catch (err) {
     return err;
   }
};

export const createTargetProgress = async (projectID, targetId, data) => {
  try {
    const resp = await fetch(
      `${v0Url}/projects/${projectID}/projecttargets/${targetId}/projecttargetprogresses`,
      {
        method: 'POST',
        headers: {
          ...getDefaultHeaders(),
          accept: 'application/json',
        },
        body: JSON.stringify(data),
      }
    ).then((resp) => handleIsUnauthenticated(resp));
    return resp;
  } catch (err) {
    return err;
  }
};

export const editTargetProgresses = async (projectID, targetId, progressId, data) => {
  try {
    const resp = await fetch(
      `${v0Url}/projects/${projectID}/projecttargets/${targetId}/projecttargetprogresses/${progressId}`,
      {
        method: 'PUT',
        headers: {
          ...getDefaultHeaders(),
        },
        body: JSON.stringify(data),
      }
    ).then((resp) => handleIsUnauthenticated(resp));
    return resp;
  } catch (err) {
    return err;
  }
};

export const deleteTargetProgress = async (projectID, targetId, targetProgressID) => {
  try {
    const resp = await fetch(
      `${v0Url}/projects/${projectID}/projecttargets/${targetId}/projecttargetprogresses/${targetProgressID}`,
      {
        method: 'DELETE',
        headers: {
          ...getDefaultHeaders(),
        },
      }
    ).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

export const getBaseURL = () => {
  console.log('v0Url', v0Url);
  return v0Url;
};

export const documentPreview = async (doc) => {
   var file = doc;
   return fetch(`${v0Url}/documents/${file.id}/preview`, {
     method: 'GET',
     headers: {
       ...getNoJsonHeaders(),
       //'Content-Type': 'application/vnd.ms-excel',
     },
   })
     .then((res) => res.blob())
     .then((blob) => {
       console.log(blob);
       return blob;
     });
   //return res
 };

export const editFrameworkContract = async (tenderId, frameworkcontractId, data) => {
  try {
    const resp = await fetch(
      `${v0Url}/tenders/${tenderId}/frameworkcontracts/${frameworkcontractId}`,
      {
        method: 'PUT',
        headers: {
          ...getDefaultHeaders(),
        },
        body: JSON.stringify(data),
      }
    ).then((resp) => handleIsUnauthenticated(resp));
    return resp;
  } catch (err) {
    return err;
  }
};

//INVOLVED SUBJECTS
export const getAllInvolvedSubjectsByFrameworkContract = async (frameworkContractId) => {
   try {
     const resp = await fetch(`${v0Url}/frameworkcontracts/${frameworkContractId}/involvedsubjects`, {
       method: 'GET',
       headers: {
         ...getDefaultHeaders(),
         accept: 'application/json',
       },
     }).then((resp) => handleIsUnauthenticated(resp));
     return resp;
   } catch (err) {
     return err;
   }
 };
 
 export const getInvolvedSubject = async (frameworkContractId, involvedSubjectId) => {
   try {
     const resp = await fetch(`${v0Url}/frameworkcontracts/${frameworkContractId}/involvedsubjects/${involvedSubjectId}`, {
       method: 'GET',
       headers: {
         ...getDefaultHeaders(),
         accept: 'application/json',
       },
     }).then((resp) => handleIsUnauthenticated(resp));
     return resp;
   } catch (err) {
     return err;
   }
 };

 export const createInvolvedSubject = async (frameworkContractId, data) => {
   try {
     const resp = await fetch(`${v0Url}/frameworkcontracts/${frameworkContractId}/involvedsubjects`, {
       method: 'POST',
       headers: {
         ...getDefaultHeaders(),
         accept: 'application/json',
       },
       body: JSON.stringify(data),
     }).then((r) => {
       if (!r.ok) {
         return r.status;
       }
       return handleIsUnauthenticated(r);
     });
 
     //console.log('resp', resp);
     return resp;
   } catch (err) {
     return err;
   }
 };
 
 export const editInvolvedSubject = async (frameworkContractId, data) => {
   try {
     const resp = await fetch(`${v0Url}/frameworkcontracts/${frameworkContractId}/involvedsubjects/${data.id}`, {
       method: 'PUT',
       headers: {
         ...getDefaultHeaders(),
         accept: 'application/json',
       },
       body: JSON.stringify(data),
     }).then((resp) => handleIsUnauthenticated(resp));
 
     //console.log('resp', resp);
     return resp;
   } catch (err) {
     return err;
   }
 };
 
 export const deleteInvolvedSubject = async (frameworkContractId, involvedSubjectId) => {
   try {
     const resp = await fetch(`${v0Url}/frameworkcontracts/${frameworkContractId}/involvedsubjects/${involvedSubjectId}`, {
       method: 'DELETE',
       headers: {
         ...getDefaultHeaders(),
         accept: 'application/json',
       },
     }).then((resp) => handleIsUnauthenticated(resp));
 
     //console.log('resp', resp);
     return resp;
   } catch (err) {
     return err;
   }
 };

export const getOeSubjectRoles = async () => {
  return fetch(`${baseUrl}/oesubjectroles`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const downloadProjectDocument = async ([projectId, section, queryParams]) => {
  queryParams = queryParams ?? '';

  try {
    const response = await fetch(`${v0Url}/projects/${projectId}/export/${section}${queryParams}`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
        // 'Content-Type': 'application/vnd.ms-excel',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const filenameString = response?.headers?.get('Content-Disposition');
    let fileName = '';
    const parts = filenameString.split('filename=');

    if (parts.length > 1) {
      fileName = parts?.[1];
    }

    const blob = await response.blob();
    var a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    document.body.appendChild(a); //we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove(); //afterwards we remove the element again
    return { success: true };
  } catch (error) {
    console.error('Error downloading project document:', error);
    return { success: false, message: error.message };
  }
};

export const downloadProjectEntityDocument = async ([projectId, entityType]) => {
  try {
    const response = await fetch(`${v0Url}/projects/${projectId}/${entityType}/export`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
        // 'Content-Type': 'application/vnd.ms-excel',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const filenameString = response?.headers?.get('Content-Disposition');

    let fileName = filenameString.split('filename=')[1].split(';')[0];

    const blob = await response.blob();
    var a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
    return { success: true };
  } catch (error) {
    console.error('Error downloading project document:', error);
    return { success: false, message: error.message };
  }
};

export const downloadTendersDocument = async ([tenderId]) => {
  try {
    //api/v0/tenders/export
    //api/v0/tenders/${tenderId}/frameworkcontracts/export
    const path = tenderId ? `${tenderId}/frameworkcontracts/export` : 'export';

    const response = await fetch(`${v0Url}/tenders/${path}`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
        // 'Content-Type': 'application/vnd.ms-excel',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const filenameString = response?.headers?.get('Content-Disposition');
    let fileName = '';
    const parts = filenameString.split('filename=');

    if (parts.length > 1) {
      fileName = parts?.[1];
    }

    const blob = await response.blob();
    var a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
    return { success: true };
  } catch (error) {
    console.error('Error downloading project document:', error);
    return { success: false, message: error.message };
  }
};

export const downloadTendersChecklistsDocument = async ([tenderId]) => {
   try {
     //api/v0/checklists/tenders/export
     //api/v0/checklists/tenders/${tenderId}/checklists/frameworkcontracts/export
     const path = tenderId ? `${tenderId}/checklists/frameworkcontracts/export` : 'export';
 
     const response = await fetch(`${v0Url}/checklists/tenders/${path}`, {
       method: 'GET',
       headers: {
         ...getDefaultHeaders(),
         // 'Content-Type': 'application/vnd.ms-excel',
       },
     });
 
     if (!response.ok) {
       throw new Error('Network response was not ok');
     }
 
     const filenameString = response?.headers?.get('Content-Disposition');
     let fileName = '';
     const parts = filenameString.split('filename=');
 
     if (parts.length > 1) {
       fileName = parts?.[1];
     }
 
     const blob = await response.blob();
     var a = document.createElement('a');
     a.href = window.URL.createObjectURL(blob);
     a.download = fileName;
     document.body.appendChild(a);
     a.click();
     a.remove();
     return { success: true };
   } catch (error) {
     console.error('Error downloading project document:', error);
     return { success: false, message: error.message };
   }
};

export const downloadTendersChecklistsCQDocument = async () => {
   try {
     const response = await fetch(`${v0Url}/checklists/frameworkcontracts/export`, {
       method: 'GET',
       headers: {
         ...getDefaultHeaders(),
         // 'Content-Type': 'application/vnd.ms-excel',
       },
     });
 
     if (!response.ok) {
       throw new Error('Network response was not ok');
     }
 
     const filenameString = response?.headers?.get('Content-Disposition');
     let fileName = '';
     const parts = filenameString.split('filename=');
 
     if (parts.length > 1) {
       fileName = parts?.[1];
     }
 
     const blob = await response.blob();
     var a = document.createElement('a');
     a.href = window.URL.createObjectURL(blob);
     a.download = fileName;
     document.body.appendChild(a);
     a.click();
     a.remove();
     return { success: true };
   } catch (error) {
     console.error('Error downloading project document:', error);
     return { success: false, message: error.message };
   }
};

export const downloadProjectEntityChecklistsDocument = async ([projectId, entityType]) => {
   try {
      //entityType = contracts || invoices || timesheets || invoicepayments

     const response = await fetch(`${v0Url}/projects/${projectId}/checklists/${entityType}/export`, {
       method: 'GET',
       headers: {
         ...getDefaultHeaders(),
         // 'Content-Type': 'application/vnd.ms-excel',
       },
     });
 
     if (!response.ok) {
       throw new Error('Network response was not ok');
     }
 
     const filenameString = response?.headers?.get('Content-Disposition');
     let fileName = '';
     const parts = filenameString.split('filename=');
 
     if (parts.length > 1) {
       fileName = parts?.[1];
     }
 
     const blob = await response.blob();
     var a = document.createElement('a');
     a.href = window.URL.createObjectURL(blob);
     a.download = fileName;
     document.body.appendChild(a);
     a.click();
     a.remove();
     return { success: true };
   } catch (error) {
     console.error('Error downloading project document:', error);
     return { success: false, message: error.message };
   }
};

export const getInherenceChecklists = async (projectID) => {
  return fetch(`${v0Url}/projects/${projectID}/inherencechecklists`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getAdvertisingChecklists = async (projectID, refTable) => {
  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/projects/${projectID}/advertisingchecklists`),
  {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const addIndustrialAccounts = async (projectId, data) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${projectId}/industrialaccounts`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const getIndustrialAccounts = async (projectId, id = null) => {
  let url = `${v0Url}/projects/${projectId}/industrialaccounts`;
  if (id) {
    url = `${v0Url}/projects/${projectId}/industrialaccounts/${id}`;
  }
  return fetch(url, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const deleteIndustrialAccounts = async (projectId, id) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${projectId}/industrialaccounts/${id}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));

    return resp;
  } catch (err) {
    return err;
  }
};

export const setIndustrialAccounts = async (data) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${data.projectId}/industrialaccounts/${data.id}`, {
      method: 'PUT',
      headers: {
        ...getDefaultHeaders(),
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));
    return resp;
  } catch (err) {
    return err;
  }
};

export const getPlantsAndMaterials = async (projectID, refTable) => {
  return fetch(handleLoadPerPageSaved(refTable, `${v0Url}/projects/${projectID}/plantsandmaterials`), {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};
export const getExpenseItemReportability = async (projectID) => {
  return fetch(`${v0Url}/projects/${projectID}/expenseitemreportabilitycoloramounts`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((resp) => handleIsUnauthenticated(resp));
};

export const getAllProjectInternalExpenseItems = async (id) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${id}/projectinternalexpenseitems`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
    }).then((resp) => handleIsUnauthenticated(resp));
    return resp;
  } catch (err) {
    return err;
  }
};

export const getProjectInternalExpenseItem = async (projectId, expenseItemId) => {
  try {
    const resp = await fetch(`${v0Url}/${projectId}/projectinternalexpenseitems/${expenseItemId}`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
    }).then((resp) => handleIsUnauthenticated(resp));
    return resp;
  } catch (err) {
    return err;
  }
};

export const createProjectInternalExpenseItems = async (projectId, data) => {
  try {
    const resp = await fetch(`${v0Url}/projects/${projectId}/projectinternalexpenseitems`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editProjectInternalExpenseItems = async (projectId, data) => {
  //console.log('data', data)
  try {
    const resp = await fetch(
      `${v0Url}/projects/${projectId}/projectinternalexpenseitems/${data?.id}`,
      {
        method: 'PUT',
        headers: {
          ...getDefaultHeaders(),
          accept: 'application/json',
        },
        body: JSON.stringify(data),
      }
    ).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const deleteProjectInternalExpenseItems = async (projectId, expenseItemId) => {
  try {
    const resp = await fetch(
      `${v0Url}/projects/${projectId}/projectinternalexpenseitems/${expenseItemId}`,
      {
        method: 'DELETE',
        headers: {
          ...getDefaultHeaders(),
          accept: 'application/json',
        },
      }
    ).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const downloadChecklist = async ([entityType, entityId, isVerticalExport]) => {
   try {
      const response = await fetch(`${v0Url}/checklists/${entityType.toLowerCase()}/${entityId}/${isVerticalExport ? 'verticalexport' : 'export'}`, {
        method: 'GET',
        headers: {
          ...getDefaultHeaders(),
          // 'Content-Type': 'application/vnd.ms-excel',
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const filenameString = response?.headers?.get('Content-Disposition');
      let fileName = '';
      const parts = filenameString.split('filename=');
  
      if (parts.length > 1) {
        fileName = parts?.[1];
      }
  
      const blob = await response.blob();
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = fileName;
      document.body.appendChild(a); //we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
      return { success: true };
    } catch (error) {
      console.error('Error downloading project document:', error);
      return { success: false, message: error.message };
    }
};

export const downloadEntityTemplate = async (entityType) => {
   try {
     const response = await fetch(`${v0Url}/templates/${entityType}/export`, {
       method: 'GET',
       headers: {
         ...getDefaultHeaders(),
         // 'Content-Type': 'application/vnd.ms-excel',
       },
     });
 
     if (!response.ok) {
       throw new Error('Network response was not ok');
     }
 
     const filenameString = response?.headers?.get('Content-Disposition');
 
     let fileName = filenameString.split('filename=')[1].split(';')[0];
 
     const blob = await response.blob();
     var a = document.createElement('a');
     a.href = window.URL.createObjectURL(blob);
     a.download = fileName;
     document.body.appendChild(a);
     a.click();
     a.remove();
     return { success: true };
   } catch (error) {
     console.error('Error downloading project document:', error);
     return { success: false, message: error.message };
   }
};

export const downloadControlsReport = async (noticeId) => {
   try {
     const response = await fetch(`${v0Url}/notices/${noticeId}/export/report`, {
       method: 'GET',
       headers: {
         ...getDefaultHeaders(),
         // 'Content-Type': 'application/vnd.ms-excel',
       },
     });
 
     if (!response.ok) {
       throw new Error('Network response was not ok');
     }
 
     const filenameString = response?.headers?.get('Content-Disposition');
 
     let fileName = filenameString.split('filename=')[1].split(';')[0];
 
     const blob = await response.blob();
     var a = document.createElement('a');
     a.href = window.URL.createObjectURL(blob);
     a.download = fileName;
     document.body.appendChild(a);
     a.click();
     a.remove();
     return { success: true };
   } catch (error) {
     console.error('Error downloading:', error);
     return { success: false, message: error.message };
   }
};

export const getProfile = async () => {
  try {
    const response = await fetch(`${v0Url}/profile`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((resp) => handleIsUnauthenticated(resp));
    const data = await response;
    return data;
  } catch (error) {
    console.error('Error getting profile:', error);
  }
};


export const updateProfile = async (data) => {
  try {
    const resp = await fetch(`${v0Url}/profile/update`, {
      method: 'PUT',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const changePassword = async (data) => {
  try {
    const resp = await fetch(`${v0Url}/profile/change-password`, {
      method: 'PUT',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  }
  catch (err) {
    return err;
  }
};

export const impersonate = async (data) => {
  try {
    const resp = await fetch(`${v0Url}/impersonate-user`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((resp) => handleIsUnauthenticated(resp));

    //console.log('resp', resp);
    return resp;
  }
  catch (err) {
    return err;
  }
};

export const getByPath = async (path) => {
   try {
     const resp = await fetch(path, {
       method: 'GET',
       headers: {
         ...getDefaultHeaders(),
         accept: 'application/json',
       },
     }).then((resp) => handleIsUnauthenticated(resp));
     return resp;
   } catch (err) {
     return err;
   }
};

//BULLETINS
export const getBulletins = async () => {
   return fetch(`${v0Url}/bulletins`, {
     method: 'GET',
     headers: {
       ...getDefaultHeaders(),
     },
   }).then((resp) => handleIsUnauthenticated(resp));
 };
 
 export const getBulletin = async (bulletinId) => {
   return fetch(`${v0Url}/bulletins/${bulletinId}`, {
     method: 'GET',
     headers: {
       ...getDefaultHeaders(),
     },
   }).then((resp) => handleIsUnauthenticated(resp));
 };
 
 export const createBulletin = async (data) => {
   try {
     const resp = await fetch(`${v0Url}/bulletins`, {
       method: 'POST',
       headers: {
         ...getDefaultHeaders(),
         accept: 'application/json',
       },
       body: JSON.stringify(data),
     }).then((resp) => handleIsUnauthenticated(resp));
 
     //console.log('resp', resp);
     return resp;
   } catch (err) {
     return err;
   }
 };
 
 export const editBulletin = async (data) => {
   return fetch(`${v0Url}/bulletins/${data.id}`, {
     method: 'PUT',
     headers: {
       ...getDefaultHeaders(),
     },
     body: JSON.stringify(data),
   }).then((resp) => handleIsUnauthenticated(resp));
 };
 
 export const deleteBulletin = async (bulletinId) => {
   try {
     const resp = await fetch(`${v0Url}/bulletins/${bulletinId}`, {
       method: 'DELETE',
       headers: {
         ...getDefaultHeaders(),
       },
     }).then((resp) => handleIsUnauthenticated(resp));
 
     return resp;
   } catch (err) {
     return err;
   }
 };

 //TEMPLATE CONFIGURATIONS
export const getTemplateConfigurations = async (companyId) => {
   return fetch(`${v0Url}/companies/${companyId}/templateconfigurations`, {
     method: 'GET',
     headers: {
       ...getDefaultHeaders(),
     },
   }).then((resp) => handleIsUnauthenticated(resp));
};

export const getTemplateConfiguration = async (companyId, templateconfigurationId) => {
   return fetch(`${v0Url}/companies/${companyId}/templateconfigurations/${templateconfigurationId}`, {
     method: 'GET',
     headers: {
       ...getDefaultHeaders(),
     },
   }).then((resp) => handleIsUnauthenticated(resp));
};

export const generateFilesTemplates = async (
   projectId,
   configurationId,
   output,
   files,
   fileTypes,
 ) => {
   let formData = new FormData();
 
   for (let i = 0; i < files.length; i++) {
      formData.append(`files[${i}]`, files[i]);
   }

   for (let i = 0; i < fileTypes.length; i++) {
      formData.append(`fileTypes[${i}]`, fileTypes[i]);
   }

   formData.append('configurationId', configurationId);
   formData.append('output', output);

   try {
      const response = await fetch(`${v0Url}/projects/${projectId}/generatetemplates`, {
       method: 'POST',
       headers: {
         ...getNoJsonHeaders(),
       },
       body: formData,
     })
     console.log('response', response);

     const filenameString = response?.headers?.get('Content-Disposition');
     let fileName = '';
     const parts = filenameString.split('filename=');
 
     if (parts.length > 1) {
       fileName = parts?.[1];
     }
 
     const blob = await response.blob();
     var a = document.createElement('a');
     a.href = window.URL.createObjectURL(blob);
     a.download = fileName;
     document.body.appendChild(a);
     a.click();
     a.remove();
     return { success: true };
   } catch (err) {
     return err;
   }
 };
 
const api = {
  impersonate,
  resetPassword,
  validateOtp,
  forgotPassword,
  getRisks,
  storeRisk,
  updateRisk,
  deleteRisk,
  getDeliverables,
  storeDeliverable,
  updateDeliverable,
  deleteDeliverable,
  getProfile,
  updateProfile,
  changePassword,
  login,
  logout,
  getDefaultHeaders,
  getUserInfo,
  getLanguages,
  getTranslations,
  storeTranslation,
  updateTranslation,
  deleteTranslation,
  exportTranslations,
  importTranslations,
  fetchForPagination,
  fetchForPaginationWithQueryParams,
  getRoles,
  getUsers,
  getUser,
  createUser,
  editUser,
  deleteUser,
  getGroups,
  createGroup,
  editGroup,
  deleteGroup,
  getCompanies,
  getVatRates,
  createCompany,
  editCompany,
  deleteCompany,
  getBusinessLines,
  getCompanyBusinessLines,
  createCompanyBusinessLine,
  editCompanyBusinessLine,
  deleteCompanyBusinessLine,
  getCompanyBusinessAreas,
  createCompanyBusinessArea,
  editCompanyBusinessArea,
  deleteCompanyBusinessArea,
  getFunds,
  editFund,
  getFundTaxonomies,
  getFundDocs,
  getQuestionSets,
  getQuestionSet,
  createQuestionSet,
  editQuestionSet,
  deleteQuestionSet,
  editQuestionSetByEntity,
  getCompanySizes,
  getProjectGroups,
  getRegions,
  getInstruments,
  createInstrument,
  editInstrument,
  deleteInstrument,
  getInstrumentDocs,
  getInstitutions,
  createInstitution,
  editInstitution,
  deleteInstitution,
  getNotices,
  getNotice,
  createNotice,
  editNotice,
  deleteNotice,
  getNoticesContactPerson,
  createNoticeContactPerson,
  editNoticeContactPerson,
  deleteNoticeContactPerson,
  getNoticesDocumentsOfAccountability,
  createNoticesDocumentsOfAccountability,
  editNoticesDocumentsOfAccountability,
  deleteNoticesDocumentsOfAccountability,
  getNoticesTargets,
  createNoticeTarget,
  editNoticeTarget,
  deleteNoticeTarget,
  getNoticesExpenseItems,
  createNoticeExpenseItems,
  editNoticeExpenseItems,
  deleteNoticeExpenseItems,
  getNoticesSubExpenseItems,
  createNoticeSubExpenseItems,
  editNoticeSubExpenseItems,
  deleteNoticeSubExpenseItems,
  getNoticesEngagementRules,
  createNoticeEngagementRules,
  editNoticeEngagementRules,
  deleteNoticeEngagementRules,
  getModalData,
  getTenders,
  createTender,
  editTender,
  deleteTender,
  getTenderCigs,
  createTenderCig,
  editTenderCig,
  deleteTenderCig,
  getTenderProcedures,
  getTenderClassifications,
  getTenderCriteria,
  getOePnrrTaxonomyValues,
  getOEChecklistQuestions,
  getOEChecklistQuestionsByEntity,
  getChecklist,
  getChecklistById,
  editChecklist,
  getDocuments,
  getDocumentsStaging,
  deleteDocumentsStaging,
  getLastBatch,
  uploadDocument,
  getOeDocuments,
  getProjectTenders,
  editProjectTenders,
  addMonthEndToFramework,
  updateFrameworkValues,
  uploadFrameworkContract,
  getFrameworkContractsStaging,
  getFrameworkContractsStagingBatches,
  getBatchesByInfo,
  getProjectContracts,
  getProjectExpenseItemsStreamConfig,
  downloadDocument,
  getFrameworkContracts,
  uploadFrameworkContractDocuments,
  projectMassiveUpload,
  projectMassiveUploadDocuments,
  projectUploadChecklist,
  projectUploadChecklistPayments,
  getProjectStagingTable,
  getProjectChecklistStagingTable,
  getEntityList,
  getPaymentsByInfo,
  getPaymentByInfo,
  getStagingTable,
  tendersListUploadChecklist,
  getChecklistStagingTable,
  uploadTendersList,
  uploadDocumentsTenders,
  addMonthStartToFramework,
  deleteMonthStartToFramework,
  deleteMonthEndToFramework,
  getProjectResumeInvoices,
  getProjectResumeTimesheets,
  getProjectResumeTotals,
  getOEStatementTypes,
  getProjectStatements,
  getProjectStatement,
  getProjectStatementsByState,
  createProjectStatement,
  editProjectStatement,
  deleteStatement,
  getStatementInvoicesOrTimesheets,
  pickUnpickProjectStatement,
  pickUnpickAllProjectStatement,
  getProjectStatementAmounts,
  getProjectTotalsReportedAmount,
  getProjectStatementGeneralExpenses,
  getDnshCompassClimateMitigation,
  getDnshCompassClimateAdaptation,
  getDnshElements,
  getDnshChecklists,
  editDnshChecklistsAnswer,
  getProjectStats,
  getProjectDossierStatus,
  getSectionsStatementsDossier,
  makeProjectDossier,
  downloadProjectDossier,
  //exportExcel,
  getFinancialProgresses,
  getOEProceduralProgresses,
  getProceduralProgresses,
  createProceduralProgress,
  editProceduralProgress,
  deleteProceduralProgress,
  getMilestones,
  createMilestone,
  editMilestone,
  deleteMilestone,
  getOEProjectTargetReasons,
  createTargetProgress,
  editTargetProgresses,
  deleteTargetProgress,
  getBaseURL,
  documentPreview,
  editFrameworkContract,
  getOeSubjectRoles,
  downloadProjectDocument,
  downloadProjectEntityDocument,
  downloadTendersDocument,
  downloadTendersChecklistsDocument,
  downloadTendersChecklistsCQDocument,
  downloadProjectEntityChecklistsDocument,
  getFrameworkContractsStagingAll,
  uploadFrameworkContracts,
  getAdvertisingChecklists,
  getInherenceChecklists,
  getExpenseItemReportability,
  addIndustrialAccounts,
  getIndustrialAccounts,
  deleteIndustrialAccounts,
  setIndustrialAccounts,
  getPlantsAndMaterials,
  getAllProjectInternalExpenseItems,
  getProjectInternalExpenseItem,
  createProjectInternalExpenseItems,
  editProjectInternalExpenseItems,
  deleteProjectInternalExpenseItems,
  downloadChecklist,
  downloadEntityTemplate,
  downloadControlsReport,
  getAllClusters,
  getProjectClusters,
  editProjectCluster,
  uploadDocumentByDocumentId,
  getByPath,
  getBulletins,
  getBulletin,
  createBulletin,
  editBulletin,
  deleteBulletin,
  getAllInvolvedSubjectsByFrameworkContract,
  getInvolvedSubject,
  createInvolvedSubject,
  editInvolvedSubject,
  deleteInvolvedSubject,
  getTemplateConfigurations,
  getTemplateConfiguration,
  generateFilesTemplates
};
export default api;
