import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useParams, Route, Switch, NavLink } from 'react-router-dom';
import { Row, Nav, Col } from 'react-bootstrap';
import {
  getOEStatementTypes,
  getProjectStatementsByState,
  getProjectTotalsReportedAmount,
} from '@lib/api';
import { dateFormatter, formatToDecimals } from '@lib/helpers/formatting';
import { columnCentered } from '@lib/helpers/agGridHelpers';
import { LevelContext } from '@components/contexts/LevelContext';
import OETableAgGrid from '@components/oetable/OETableAgGrid';
import MakeReporting from '@components/projectStatements/MakeReporting';
import MakeReportingModal from 'components/projectStatements/MakeReportingModal';
import DataHeader from 'components/DataHeader';
import { IcoCheck } from 'icone/index';
import Dnsh from './Dnsh';

const Reporting = ({ project }) => {
  const { projectId, tab, submenu } = useParams();
  const { isOe, userRole } = useContext(LevelContext);
  const [statements, setStatements] = useState(null);
  const [validatedStatements, setValidatedStatements] = useState([]);
  const [validatedStatementsMeta, setValidatedStatementsMeta] = useState({});
  const [draftStatements, setDraftStatements] = useState(null);
  const [draftStatementsMeta, setDraftStatementsMeta] = useState({});
  const [totals, setTotals] = useState({});
  const [optionsStatementTypes, setOptionsStatementTypes] = useState([]);
  const [activeKey, setActiveKey] = useState(submenu ?? 'history');
  const [refresh, setRefresh] = useState(false);
  const [isInCreate, setIsInCreate] = useState(false);
  const localLang = `${localStorage.getItem('userLanguage').toLowerCase()}-${localStorage.getItem(
    'userLanguage'
  )}`;

  useEffect(() => {
    getOEStatementTypes().then((retrievedStatementTypes) => {
      setOptionsStatementTypes(
        retrievedStatementTypes.data.map((types) => ({
          value: types.code,
          label: types.name,
        }))
      );
    });
  }, []);

  const handleSetAllStatements = useCallback(() => {
    getProjectStatementsByState(projectId, 'VALIDATED', 'validatedStatements').then((retrievedStatements) => {
      if (retrievedStatements.success) {
         setValidatedStatements(retrievedStatements.data);
         const { data, success, ...restResp } = retrievedStatements;
         setValidatedStatementsMeta(restResp);
      }
    });

    getProjectStatementsByState(projectId, 'DRAFT', 'draftStatements').then((retrievedStatements) => {
      if (retrievedStatements.success) {
         setDraftStatements(retrievedStatements.data);
         const { data, success, ...restResp } = retrievedStatements;
         setDraftStatementsMeta(restResp);
      }
    });
  }, [projectId]);

  useEffect(() => {
    setStatements(validatedStatements.concat(draftStatements));
  }, [draftStatements, validatedStatements]);

  useEffect(() => {
    if (projectId) {
      handleSetAllStatements();
    }
  }, [projectId, handleSetAllStatements]);

  const refreshTotals = useCallback(() => {
    getProjectTotalsReportedAmount(projectId, '?state=VALIDATED').then(
      (retrievedTotalsReportedAmount) => {
        if (retrievedTotalsReportedAmount.success) {
          //la proprietà total è l'importo rendicontato e totalRequestedAmount è il contributo richiesto
          //in statements ci sono gli importi rendicontati
          setTotals(retrievedTotalsReportedAmount.data);
        }
      }
    );
  }, [projectId]);

  useEffect(() => {
    refreshTotals();
  }, [refreshTotals]);

  useEffect(() => {
    if (refresh) {
      handleSetAllStatements();

      setRefresh(false);
    }
  }, [handleSetAllStatements, refresh]);

  const CheckBoxCellRenderer = useCallback((params) => {
    //console.log('params', params.data.cashedIn);

    return <>{params.data.cashedIn ? 
      <>
         <IcoCheck as={Col} style={{ fill: '#689689', marginRight: '5px' }} />
         <span as={Col} className='small'>{dateFormatter(params.data.cashedInDate)}</span>
      </> : null
   }
   </>;
  }, []);

  const statementsColumn = [
    {
      headerName: 'id',
      field: 'id',
      hide: true,
    },
    {
      headerName: 'Tipo',
      field: 'statementTypeCode',
      valueGetter: (params) => {
        const type = optionsStatementTypes
          .filter((optType) => optType.value === params.data.statementTypeCode)
          .map((type) => type.label)
          .pop();
        return params.data.statementTypeCode === 'SAL' ? params.data.name : type;
      },
      autoHeight: true,
      cellStyle: {
         height: '100%',
         display: 'flex',
         alignItems: 'center',
      },
    },
    {
      headerName: 'Contributo richiesto €',
      field: 'requestedAmount',
      type: 'rightAligned',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        return formatToDecimals(params.data.requestedAmount);
      },
      autoHeight: true,
      cellStyle: {
         height: '100%',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'end',
      },
    },
    {
      headerName: 'Importo rendicontato €',
      field: 'reportedAmount',
      type: 'rightAligned',
      filter: 'agTextColumnFilter',
      minWidth: 220,
      valueGetter: (params) => {
        const requestedAmount = totals.statements
          ?.filter((statement) => statement.statementId === params.data.id)
          .map((el) => el.total)
          .pop();
          
        return formatToDecimals(requestedAmount);
      },
      autoHeight: true,
      cellStyle: {
         height: '100%',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'end',
      },
    },
    {
      headerName: 'Data sottomissione',
      field: 'date',
      ...columnCentered(),
      valueGetter: (params) => {
        return dateFormatter(params.data.date);
      },
      autoHeight: true,
      cellStyle: {
         height: '100%',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
      },
    },
    {
      headerName: 'Contributo approvato €',
      field: 'approvedAmount',
      filter: 'agTextColumnFilter',
      type: 'rightAligned',
      resizable: true,
      valueGetter: (params) => {
        return params.data.approvedAmount ? formatToDecimals(params.data.approvedAmount) : 0;
      },
      autoHeight: true,
      minWidth: 220,
      cellStyle: {
         height: '100%',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'end',
      },
    },
    {
      headerName: 'Incassato',
      field: 'cashedIn',
      cellRenderer: CheckBoxCellRenderer,
      cellRendererParams: {
        field: 'cashedIn',
      },
      resizable: false,
      autoHeight: true,
      ...columnCentered('', {
         height: '100%',
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
      }),
    },
    // if is oe or userRole === 5 add the column for the action
    ...(isOe || userRole === 5
      ? [
          {
            headerName: '',
            minWidth: 60,
            maxWidth: 60,
            autoHeight: true,
            cellStyle: {
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center', 
              paddingBottom: '0.5rem',
            },
            cellRenderer: MakeReportingModal,
            cellRendererParams: {
              project: project,
              isOe: (isOe || userRole === 5),
              projectId: projectId,
              optionsStatementTypes: optionsStatementTypes,
              isEdit: false,
              setRefresh: setRefresh,
              setIsShowNew: setIsInCreate,
              refreshTotals: refreshTotals,
            },
          },
        ]
      : []),
  ];

  return (
    <>
      <div className='candidacy simple-box scenaritab'>
        <Row>
          <Nav
            variant="pills"
            activeKey={activeKey}
            onSelect={(k) => {
              setActiveKey(k);
              refreshTotals();
            }}>
            <Nav.Item>
              <Nav.Link eventKey='history' as={NavLink} to={`/project/${projectId}/${tab}/history`}>
                Storico rendiconti
              </Nav.Link>
            </Nav.Item>
            {(isOe || userRole === 5) && (
              <Nav.Item>
                <Nav.Link
                  eventKey='expense-report'
                  as={NavLink}
                  to={`/project/${projectId}/${tab}/expense-report`}>
                  Gestione bozze
                </Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item>
              <Nav.Link eventKey='dnsh' as={NavLink} to={`/project/${projectId}/${tab}/dnsh`}>
                DNSH
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Row>

        <Switch>
          <Route path={`/project/:projectId/:tab/history`}>
            {activeKey === 'history' && (
              <>
                <DataHeader
                  data={[
                    {
                      name: 'Importo rendicontato',
                      value: formatToDecimals(totals.total) + ' €',
                    },
                    {
                      name: 'Contributo richiesto',
                      value: formatToDecimals(totals.totalRequestedAmount) + ' €',
                    },
                    //DISABILITATO PER PRESENTAZIONE
                    /* {
                      name: 'Importo tagli',
                      value: formatToDecimals(validatedStatements.filter(el => el.cashedIn).reduce((acc, curr) => acc + curr.approvedAmount, 0)) + ' €',
                    }, */
                  ]}
                />

                {validatedStatements && (
                  <OETableAgGrid
                    column={statementsColumn}
                    data={validatedStatements}
                    dataMeta={validatedStatementsMeta}
                    hideSearchBar={true}
                    refTable='validatedStatements'
                  />
                )}
              </>
            )}
          </Route>
          {(isOe || userRole === 5) && (
            <Route path={`/project/:projectId/:tab/expense-report`}>
              {activeKey === 'expense-report' && (
                <MakeReporting
                  optionsStatementTypes={optionsStatementTypes}
                  draftStatements={draftStatements}
                  draftStatementsMeta={draftStatementsMeta}
                  statements={statements}
                  localLang={localLang}
                  handleSetAllStatements={handleSetAllStatements}
                  isInCreate={isInCreate}
                  setIsInCreate={setIsInCreate}
                />
              )}
            </Route>
          )}
          <Route path={`/project/:projectId/:tab/dnsh`}>
            {activeKey === 'dnsh' && (
              <>
                <Dnsh project={project} />
              </>
            )}
          </Route>
        </Switch>
      </div>
    </>
  );
};
export default Reporting;
