import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
   Button,
   OverlayTrigger,
   Tooltip,
   Modal,
   Form,
   Row,
   Col,
   Tab,
   Tabs,
   Spinner,
   Accordion,
} from 'react-bootstrap';
import { getEntity } from '@lib/api';
import { IcoEdit } from '@icons';
import UploadDocument from 'components/form/upload/uploadDocuments';
import CheckList from 'components/checklist/checkList';
import { formatToDecimals, dateFormatter } from '@lib/helpers/formatting';
import { LevelContext } from 'components/contexts/LevelContext';
import { columnCentered } from '@lib/helpers/agGridHelpers';
import OESimpleTable from 'components/oetable/OESimpleTable';
import { OETrans } from 'components/translation/OETrans';

const ProjectInvoiceModal = ({
   data,
   setRefresh,
   modalIsVisible,
   setModalIsVisible,
   isSummary,
   spinnerVisible,
   setModalData,
}) => {
   const { userRole } = useContext(LevelContext);
   const { projectId } = useParams();
   const [invoice, setInvoice] = useState({});
   const [showModalEdit, setShowModalEdit] = useState(false);
   const [activeSubKey, setActiveSubKey] = useState('checklist-invoices');
   const [isEdited, setIsEdited] = useState(false);
   const [rowData, setRowData] = useState([]);
   const [columnDef, setColumnDef] = useState([]);
   const [isOpenDetails, setIsOpenDetails] = useState(false);
   console.log('rowData', rowData);

   function transformData(positions) {
      const transformedData = [];

      if (positions && Array.isArray(positions)) {
         positions.forEach((position) => {
            if (position && position.quotas && Array.isArray(position.quotas)) {
               position.quotas.forEach((quota) => {
                  const newObject = { ...position };
                  delete newObject.quotas;

                  if (quota) {
                     Object.keys(quota).forEach((key) => {
                        newObject[key + 'Q'] = quota[key];
                     });

                     transformedData.push(newObject);
                  }
               });
            }
         });
      }

      setRowData(transformedData);
   }

   useEffect(() => {
      if ((modalIsVisible || (modalIsVisible === undefined && showModalEdit)) && projectId && data.id) {
         getEntity('invoices', projectId, data.id).then((retrievedInvoice) => {
            if (retrievedInvoice.success) {
               setInvoice(retrievedInvoice.data);
               transformData(retrievedInvoice.data.positions);
            }
         });
      }
   }, [data, modalIsVisible, projectId, showModalEdit]);

   const handleCloseModal = (setter) => {
      if (isEdited) {
         setRefresh(true);
         setIsEdited(false);
      }
      if (isSummary) {
         setModalData([]);
         setModalIsVisible(false);
      } else {
         setter(false);
      }
   };

   const handleShowModal = (setter) => {
      if (isSummary) {
         setModalIsVisible(true);
      } else {
         setter(true);
      }
   };

   /* const defaultColDef = {
     resizable: true,
     suppressMovable: true,
     cellStyle: { padding: '0 1rem' },
   }; */

   const OnlyChecklistCellRenderer = useCallback((params) => {
      //console.log('params', params.queryParam, params);

      const stoplightColor = {
         red: "#C94723",
         yellow: "#D4BE31",
         green: "#689689",
      };

      if (params.value) {
         let circleStyle;
         let message = "";

         circleStyle = {
            display: "inherit",
            width: "1.2em",
            height: "1.2em",
            borderRadius: "2em",
            MozBorderRadius: "2em",
            WebkitBorderRadius: "2em",
            backgroundColor: stoplightColor[params?.value.color],
            border: `2px solid ${stoplightColor[params?.value.color]}`,
         };

         switch (params?.value.color) {
            case "yellow":
               message = "Attenzione";
               break;
            case "green":
               message = "Conforme";
               break;
            case "red":
               message = "Non conforme";
               break;
            default:
               break;
         }

         return (
            <>
               <Row
                  className="align-items-center justify-content-center h-100 flex-nowrap"
                  //style={{ justifyContent: params.align ?? "justify-content-between"}}
               >
                  <Col className="col-auto">
                     <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{message}</Tooltip>}
                     >
                        <i style={{ ...circleStyle }} />
                     </OverlayTrigger>
                  </Col>
               </Row>
            </>
         );
      }
   }, []);

   const getValueFilterStoplight = (value) => {
      let string = value.color;

      switch (value) {
         case 'green':
            string = 'verde';
            break;
         case 'yellow':
            string = 'giallo';
            break;
         case 'red':
            string = 'rosso';
            break;
         case 'white':
            string = 'bianco';
            break;
         default:
            break;
      }

      return string;
   };

   useEffect(() => {
      setColumnDef([
         {
            headerName: 'Posizione',
            field: 'position',
            filter: 'agTextColumnFilter',
            minWidth: 150,
            cellStyle: { fontWeight: 'bold' },
         },
         {
            headerName: 'Classificazione interna',
            field: 'internalTaxonomy',
            tooltipField: 'internalTaxonomy',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return params.data.internalTaxonomy ?? 'N.D.';
            },
            minWidth: 300,
         },
         {
            headerName: 'Descrizione Posizione',
            field: 'positionDescription',
            tooltipField: 'positionDescription',
            filter: 'agTextColumnFilter',
            minWidth: 380,
         },
         {
            headerName: 'Matricola Materiale',
            field: 'code',
            filter: 'agTextColumnFilter',
            minWidth: 220,
            valueGetter: (params) => {
               return params.data.materialQ?.code ?? 'N.D.';
            },
         },
         {
            headerName: 'Etichettabile',
            field: 'labelableQ',
            filter: 'agTextColumnFilter',
            minWidth: 170,
         },
         {
            headerName: 'Q.tà Consuntivata',
            field: 'finalQuantity',
            filter: 'agTextColumnFilter',
            type: 'rightAligned',
            minWidth: 250,
            hide: true,
         },
         {
            headerName: 'Importo Consuntivato €',
            field: 'finalAmount',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return formatToDecimals(params.data.finalAmount);
            },
            type: 'rightAligned',
            minWidth: 280,
            hide: true,
         },

         {
            headerName: 'Quota',
            field: 'quotaNumberQ',
            filter: 'agTextColumnFilter',
            minWidth: 120,
            cellStyle: { fontWeight: 'bold' },
         },
         {
            headerName: 'Importo Quota €',
            field: 'amountQ',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return formatToDecimals(params.data.amountQ);
            },
            type: 'rightAligned',
            minWidth: 200,
         },
         {
            headerName: 'Data Quota',
            field: 'dueDateQ',
            valueGetter: (params) => {
               if (params.data.dueDateQ) {
                  return dateFormatter(params.data.dueDateQ);
               } else return;
            },
            filter: 'agTextColumnFilter',
            ...columnCentered(),
            minWidth: 200,
         },

         {
            headerName: 'Codice Impianto',
            field: 'plantCode',
            filter: 'agTextColumnFilter',
            minWidth: 220,
            valueGetter: (params) => {
               return params.data.plantQ?.plantCode ?? 'N.D.';
            },
            cellStyle: { fontWeight: 'bold' },
         },
         {
            headerName: 'Tipo Impianto',
            field: 'plantType',
            filter: 'agTextColumnFilter',
            minWidth: 220,
            valueGetter: (params) => {
               return params.data.plantQ?.plantType ?? 'N.D.';
            },
         },
         {
            headerName: 'Denominazione Impianto',
            field: 'plantName',
            filter: 'agTextColumnFilter',
            minWidth: 250,
            valueGetter: (params) => {
               return params.data.plantQ?.plantName ?? 'N.D.';
            },
         },
         {
            headerName: "Check Pubblicità",
            field: "advertisingQ",
            cellRenderer: OnlyChecklistCellRenderer,
            cellRendererParams: {
               queryParam: "advertisingQ",
            },
            minWidth: 190,
            maxWidth: 190,
            filter: "agTextColumnFilter",
            filterValueGetter: (params) => {
               return getValueFilterStoplight(params.data.advertisingQ);
            },
            comparator: (valueA, valueB) => {
               if (valueA.keyValue === valueB.keyValue) return 0;
               return valueA.keyValue > valueB.keyValue ? 1 : -1;
            },
            ...columnCentered(),
         },
         {
            headerName: "Check DNSH",
            field: "otplantQ.checkliststatus",
            cellRenderer: OnlyChecklistCellRenderer,
            cellRendererParams: {
               queryParam: "otplantQ.checkliststatus",
            },
            minWidth: 190,
            maxWidth: 190,
            filter: "agTextColumnFilter",
            filterValueGetter: (params) => {
               return getValueFilterStoplight(params.data.otplantQ.checkliststatus);
            },
            comparator: (valueA, valueB) => {
               if (valueA.keyValue === valueB.keyValue) return 0;
               return valueA.keyValue > valueB.keyValue ? 1 : -1;
            },
            ...columnCentered(),
         },

         {
            headerName: 'Centro di costo',
            field: 'otQ',
            filter: 'agTextColumnFilter',
            minWidth: 250,
            valueGetter: (params) => {
               return params.data.otQ?.code ?? 'N.D.';
            },
            cellStyle: { fontWeight: 'bold' },
         },
         /* {
            headerName: 'Matricola Bene ENEL',
            field: 'serialNumberEnelQ',
            filter: 'agTextColumnFilter',
            minWidth: 220,
         },
         {
            headerName: 'Descrizione Bene ENEL',
            field: 'descriptionEnelQ',
            filter: 'agTextColumnFilter',
            minWidth: 380,
         }, */
         /* {
            headerName: 'Codice Impianto Primario',
            field: 'plantCode',
            filter: 'agTextColumnFilter',
            minWidth: 250,
            valueGetter: (params) => {
               return params.data.parentPlant?.plantCode ?? 'N.D.'; //??
            },
         }, */
         /* {
            headerName: 'Tipo Impianto Primario',
            field: 'plantType',
            filter: 'agTextColumnFilter',
            minWidth: 240,
            valueGetter: (params) => {
               return params.data.parentPlant?.plantType ?? 'N.D.'; //??
            },
         }, */
         /* {
            headerName: 'Denominazione Impianto Primario',
            field: 'plantName',
            filter: 'agTextColumnFilter',
            minWidth: 320,
            valueGetter: (params) => {
               return params.data.parentPlant?.plantName ?? 'N.D.'; //??
            },
         }, */
         /* {
            headerName: 'Tracciabile',
            field: 'trackableQ',
            filter: 'agTextColumnFilter',
            minWidth: 150,
         }, */
         /* {
            headerName: 'QTA. POS. Acquistata',
            field: 'quantity',
            filter: 'agTextColumnFilter',
            type: 'rightAligned',
            minWidth: 220,
         }, */
         /* {
            headerName: 'Importo Quota Rendicontabile €',
            field: 'reportableAmountQ',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return formatToDecimals(params.data.reportableAmountQ);
            },
            type: 'rightAligned',
            minWidth: 300,
         }, */
      ]);
   }, [OnlyChecklistCellRenderer]);

   // Determine the modal visibility based on external or internal state
   const whichStateToUse = isSummary ? modalIsVisible : showModalEdit;

   const editTooltip = (props) => <Tooltip {...props}>Dettaglio</Tooltip>;

   return (
      <div>
         {!isSummary && (
            <OverlayTrigger placement='top' overlay={editTooltip}>
               <Button variant='link' className='me-3' onClick={() => handleShowModal(setShowModalEdit)}>
                  <IcoEdit className='second-color path ico-small edit' />
               </Button>
            </OverlayTrigger>
         )}

         {whichStateToUse && (
            <Modal
               fullscreen
               className='modal-with-nav'
               show={whichStateToUse}
               onHide={() => handleCloseModal(setShowModalEdit)}
               centered>
               <Modal.Header closeButton />

               <Modal.Body className='overflow-hidden'>
                  {spinnerVisible ? (
                     <Row>
                        <Col className='d-flex justify-content-center align-items-center'>
                           <Spinner animation='border' role='status' variant='dark'>
                              <span className='visually-hidden'>Loading...</span>
                           </Spinner>
                        </Col>
                     </Row>
                  ) : (invoice.id &&
                     <>
                        <div className='workpanel-container'>
                           <Accordion className='workpanel-top' id="detail-modal-accordion" flush>
                              <Accordion.Item eventKey='0' className='accordionBorder'>
                                 <Accordion.Header className='expanded-accordion title-fixed'>
                                    <h4 className='mb-0 me-2'>Fattura {Number(userRole) === 1 || Number(userRole) <= 30 ? invoice?.id : ''}</h4>
                                    <span 
                                       className="fake-link-detail ms-2"
                                       {...(isOpenDetails ? { style:{ color: 'white', fontWeight: 600} } : {})}
                                    >
                                       dettagli
                                    </span>
                                 </Accordion.Header>
                                 <Accordion.Body /* className="border-bottom" */ 
                                    onEnter={() => setIsOpenDetails(true)}
                                    onExit={() =>  setIsOpenDetails(false)}
                                 >
                                    <div /* className='overflow-auto' */>
                                       <Row className='mb-2rem mt-3'>
                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Nome Fornitore</Form.Label>
                                             <Form.Text as='p'>{invoice?.supplierName ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Codice Fornitore</Form.Label>
                                             <Form.Text as='p'>{invoice?.supplierCode ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>P.Iva Fornitore</Form.Label>
                                             <Form.Text as='p'>{invoice?.supplierVat ?? 'N.D.'}</Form.Text>
                                          </Form.Group>
                                          
                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Data Fattura</Form.Label>
                                             <Form.Text as='p'>
                                                {invoice?.date ? dateFormatter(invoice?.date) : 'N.D.'}
                                             </Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Nr. Fattura</Form.Label>
                                             <Form.Text as='p'>{invoice?.number ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>ID Fattura</Form.Label>
                                             <Form.Text as='p'>{invoice?.rpCode ?? 'N.D.'}</Form.Text>
                                          </Form.Group>
                                       </Row>

                                       <Row className='mb-2rem'>
                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className=''>Nr. Contratto Dedicato</Form.Label>
                                             <Form.Text as='p'>
                                                {invoice?.contract?.po === false ? invoice?.contract?.number : 'N.D.'}
                                             </Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Nr. Attingimento</Form.Label>
                                             <Form.Text as='p'>
                                                {invoice?.contract?.po === true ? invoice?.contract?.number : 'N.D.'}
                                             </Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>CUP Progetto</Form.Label>
                                             <Form.Text as='p'>{invoice?.project?.cup ?? (invoice?.stream?.project?.cup ?? 'N.D.')}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Nr. Procedura</Form.Label>
                                             <Form.Text as='p'>
                                                {invoice?.tenderCig ? invoice?.tenderCig?.tender?.tenderNumber : 'N.D.'}
                                             </Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>CIG</Form.Label>
                                             <Form.Text as='p'>{invoice?.tenderCig?.cig ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Nr. Contratto Quadro</Form.Label>
                                             <Form.Text as='p'>
                                                {invoice?.contract?.po === true && invoice?.contract?.frameworkContract
                                                   ? invoice?.contract?.frameworkContract.number
                                                   : 'N.D.'}
                                             </Form.Text>
                                          </Form.Group>
                                       </Row>

                                       <Row className='mb-2rem'>
                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Imponibile (€)</Form.Label>
                                             <Form.Text as='p'>
                                                {invoice?.taxableAmount
                                                   ? formatToDecimals(invoice?.taxableAmount)
                                                   : 'N.D.'}
                                             </Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Iva (€)</Form.Label>
                                             <Form.Text as='p'>
                                                {invoice?.vatAmount ? formatToDecimals(invoice?.vatAmount) : 'N.D.'}
                                             </Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Totale Fattura (€)</Form.Label>
                                             <Form.Text as='p'>
                                                {invoice?.totalAmount ? formatToDecimals(invoice?.totalAmount) : 'N.D.'}
                                             </Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Stream</Form.Label>
                                             <Form.Text as='p'>
                                                {invoice?.stream?.streamId
                                                   ? `${invoice?.stream?.streamId} - ${invoice?.stream?.name}`
                                                   : 'N.D.'}
                                             </Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Voce di Spesa</Form.Label>
                                             <Form.Text as='p'>
                                                {invoice?.projectExpenseItem?.noticeExpenseItem?.name ?? 'N.D.'}
                                             </Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Sottovoce di Spesa</Form.Label>
                                             <Form.Text as='p'>{invoice?.projectExpenseItem?.name ?? 'N.D.'}</Form.Text>
                                          </Form.Group>                                       
                                       </Row>

                                       <Row className='mb-2rem'>
                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Classifica Regis</Form.Label>
                                             <Form.Text as='p'>{invoice?.regisTaxonomy ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Classifica interna</Form.Label>
                                             <Form.Text as='p'>{invoice?.internalTaxonomy ?? 'N.D.'}</Form.Text>
                                          </Form.Group>


                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Fattispecie RSI</Form.Label>
                                             <Form.Text as='p'>{invoice?.rdiTypeCode ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Avvio Progetto</Form.Label>
                                             <Form.Text as='p'>{invoice?.project?.startDate ? dateFormatter(invoice?.project?.startDate) : 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'></Form.Group>
                                          <Form.Group as={Col} className='px-4'></Form.Group>
                                       </Row>

                                       {/* <Row className='mb-2rem'>
                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Ritenuta D'Acconto (€)</Form.Label>
                                             <Form.Text as='p'>
                                                {invoice?.withholding ? formatToDecimals(invoice?.withholding) : 'N.D.'}
                                             </Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Contributi Cassa (€)</Form.Label>
                                             <Form.Text as='p'>
                                                {invoice?.pensionFundContribution
                                                   ? formatToDecimals(invoice?.pensionFundContribution)
                                                   : 'N.D.'}
                                             </Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>Spese di Trasporto (€)</Form.Label>
                                             <Form.Text as='p'>
                                                {invoice?.shippingFee ? formatToDecimals(invoice?.shippingFee) : 'N.D.'}
                                             </Form.Text>
                                          </Form.Group>                                         

                                          <Form.Group as={Col} className='px-4'/>
                                          <Form.Group as={Col} className='px-4'/>
                                          <Form.Group as={Col} className='px-4'/>
                                       </Row> */}

                                       <Row className='mb-2rem'>
                                          <h2>Dettaglio Pagamento</h2>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>CUP Pagamento</Form.Label>
                                             <Form.Text as='p'>{invoice?.paymentCup ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>CIG Pagamento</Form.Label>
                                             <Form.Text as='p'>{invoice?.paymentCig ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='px-4'>
                                             <Form.Label className='mb-3'>CRO Pagamento</Form.Label>
                                             <Form.Text as='p'>{invoice?.payment?.cro ?? 'N.D.'}</Form.Text>
                                          </Form.Group>
                                       </Row>

                                       <Row>
                                          <h2>Dettaglio Posizioni</h2>
                                          {rowData && rowData.length > 0 ? (
                                             <OESimpleTable
                                                columnDefs={columnDef}
                                                rowData={rowData}
                                                buttonToolbarVisible={false}
                                                pageSizeId={'payment_invoice_quotas'}
                                                sortable={true}
                                                wrapText={false}
                                                autoHeight={false}
                                             />
                                          ) : (
                                             <Row className='text-center my-5'>
                                                <span>Non sono presenti posizioni.</span>
                                             </Row>
                                          )}
                                       </Row>
                                    </div>
                                 </Accordion.Body>
                              </Accordion.Item>
                           </Accordion>

                           <div className='workpanel-left border-tab right'>
                              {invoice.id && <UploadDocument entity='invoices' entityId={invoice.id} title='Documenti correlati' />}

                              {invoice?.paymentId &&
                                 <UploadDocument 
                                    entity='payments' 
                                    entityId={invoice?.paymentId} 
                                    disableDelete={true} 
                                    disableEdit={Number(userRole) > 40}
                                    title='Documentazione Giustificativa di pagamento' 
                                 />
                              }

                              {invoice?.contractId && <UploadDocument entity='contracts' entityId={invoice.contractId} disableEdit={Number(userRole) > 40 && isSummary} title='Documenti contratto' />}
                           </div>

                           <div className='workpanel-right pe-0'>
                              <Tabs
                                 activeKey={activeSubKey}
                                 onSelect={(k) => { console.log(k); setActiveSubKey(k) }}
                                 fill
                                 variant="pills"
                              >
                                 {/* CheckList Fatture */}
                                 <Tab eventKey='checklist-invoices' title={'Checklist Fatture'} className='px-2 pt-3'>
                                    {invoice.id && (
                                       <CheckList
                                          entityType='invoices'
                                          entityId={invoice.id}
                                          disabled={(invoice?.reported || invoice?.reporting) || Number(userRole) > 40}
                                       />
                                    )}
                                 </Tab>

                                 {/* CheckList Pagamenti */}
                                 <Tab eventKey='checklist-invoicePayments' title={'Checklist Pagamenti'} className='px-2 pt-3'>
                                    {invoice.payments?.length > 0 ? (
                                       <CheckList
                                          entityType='invoicePayments'
                                          entityId={invoice?.payments?.[0]?.pivot?.id}
                                          disabled={(invoice?.reported || invoice?.reporting) || Number(userRole) > 40}
                                       />
                                    ) : (
                                       <div className='text-center mt-4'><span>Nessun pagamento associato a questo elemento</span></div>
                                    )}
                                 </Tab>

                                 {/* RIATTIVARE CON LE QUIETANZE  */}
                                 {/* CheckList Quietanze */}
                                 {/* <Tab eventKey='checklist-invoiceReceipt' title={'Checklist Quietanze'} className='px-2 pt-3'>
                                    {invoice.receipts?.length > 0 ? (
                                       <CheckList
                                          entityType='invoiceReceipt'
                                          entityId={invoice?.receipts?.[0]?.pivot?.id}
                                          disabled={invoice?.reported || invoice?.reporting}
                                       />
                                    ) : (
                                       <div className='text-center mt-4'><span>Nessuna quietanza associata a questo elemento</span></div>
                                    )}
                                 </Tab> */}
                              </Tabs>
                           </div>
                        </div>
                     </>
                  )}
               </Modal.Body>

               <Modal.Footer>
                  <Button variant='secondary' onClick={() => handleCloseModal(setShowModalEdit)}>
                     Chiudi
                  </Button>
                  {!isSummary || /* isOe  || */ userRole < 50 ? (
                     <Button
                        type='submit'
                        form={activeSubKey}
                        onClick={() => setIsEdited(true)}
                        variant='primary'
                        disabled={invoice?.reported || invoice?.reporting}>
                        <OETrans code='common.edit' fallback={'Modifica'}/>
                     </Button>
                  ) : (
                     ''
                  )}
               </Modal.Footer>
            </Modal>
         )}
      </div>
   );
};

export default ProjectInvoiceModal;
