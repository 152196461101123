import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Button, Modal, Form, Row, Col, Tab, Tabs, Spinner } from 'react-bootstrap';
import CheckList from 'components/checklist/checkList';
import { LevelContext } from 'components/contexts/LevelContext';
import { getOTPlant } from '@lib/api/index';
import { OETrans } from 'components/translation/OETrans';

const DnshOTDetailModal = ({
   otId,
   setModalIsVisible,
   modalIsVisible,
   setOtId,
   setRefresh,
}) => {
   const { isOe, userRole } = useContext(LevelContext);
   const [detail, setDetail] = useState({});
   const [sbShow, setSbShow] = useState(<></>);
   const [activeKey, setActiveKey] = useState('registry');
   const [isEdited, setIsEdited] = useState(false);
   const [spinnerVisible, setSpinnerVisible] = useState(false);
   //console.log('detail', detail);

   useEffect(() => {
      setSpinnerVisible(true);

      if (otId) {
         getOTPlant(otId).then((retrievedOtPlant) => {
            if (retrievedOtPlant.success) {
               setDetail(retrievedOtPlant.data);
               setSpinnerVisible(false);
            }
         });
      }
   }, [otId]);

   const handleCloseModal = (setShowModal) => {
      if (isEdited) {
         setRefresh(true);
         setIsEdited(false);
      }
      setSbShow(<></>);
      setOtId(null);
      setShowModal(false);
   };

   return (
      <div>
         {modalIsVisible && (
            <Modal
               size='xl'
               className='modal-with-nav'
               show={modalIsVisible}
               onHide={() => handleCloseModal(setModalIsVisible)}
               centered>
               <Modal.Header closeButton>
                  <Modal.Title>Dettaglio OT {Number(userRole) === 1 || Number(userRole) === 5 ? detail?.id : ''}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  {spinnerVisible ? (
                     <Row>
                        <Col className='d-flex justify-content-center align-items-center'>
                           <Spinner animation='border' role='status' variant='dark'>
                              <span className='visually-hidden'>Loading...</span>
                           </Spinner>
                        </Col>
                     </Row>
                  ) : (
                     <Tabs
                        className='mb-4'
                        activeKey={activeKey}
                        onSelect={(k) => setActiveKey(k)}
                        fill
                        variant='pills'
                     >
                        {/* Anagrafica */}
                        <Tab
                           eventKey='registry'
                           title={'Dati OT'}
                        >
                           <Row style={{ marginBottom: '40px' }}>
                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>OT</Form.Label>
                                 <Form.Text as='p'>{detail.ot?.code ?? ''}</Form.Text>
                              </Form.Group>
                           </Row>

                           <Row style={{ marginBottom: '40px' }}>
                              <h2>Impianto associato</h2>

                              <Form.Group as={Col}>
                                 <Form.Label>Codice impianto</Form.Label>
                                 <Form.Text as='p'>
                                    {detail.plant?.plantCode ?? 'N.D.'}
                                 </Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}>
                                 <Form.Label>Descrizione impianto</Form.Label>
                                 <Form.Text as='p'>{detail.plant?.plantType ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}>
                                 <Form.Label>Tipo impianto</Form.Label>
                                 <Form.Text as='p'>{detail?.plant?.plantName ?? 'N.D.'}</Form.Text>
                              </Form.Group>
                           </Row>

                           <Row style={{ marginBottom: '40px' }}>
                              <Form.Group as={Col}>
                                 <Form.Label>Codice UT</Form.Label>
                                 <Form.Text as='p'>
                                    {detail.plant?.ut ?? 'N.D.'}
                                 </Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}>
                                 <Form.Label>Descrizione UT</Form.Label>
                                 <Form.Text as='p'>{detail.plant?.utDescription ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}></Form.Group>
                           </Row>
                         
                           {sbShow}
                        </Tab>

                        {/* CheckLists */}
                        {userRole < 70 && (
                           <Tab eventKey='checklist' title={'Checklist'}>
                              {detail.id && (
                                 <CheckList
                                    entityType='otplants'
                                    entityId={detail.id}
                                    disabled={Number(userRole) > 40}
                                 />
                              )}
                           </Tab>
                        )}
                     </Tabs>
                  )}
               </Modal.Body>

               <Modal.Footer>
                  <Button variant='secondary' onClick={() => handleCloseModal(setModalIsVisible)}>
                     Chiudi
                  </Button>
                  {activeKey === 'checklist' && (isOe || Number(userRole) < 40) ? (
                     <Button
                        type='submit'
                        form='checklist-otplants'
                        onClick={() => setIsEdited(true)}
                        variant='primary'
                        disabled={detail?.reported}>
                        <OETrans code='common.edit' fallback={'Modifica'} />
                     </Button>
                  ) : null}
               </Modal.Footer>
            </Modal>
         )}
      </div>
   );
};
export default DnshOTDetailModal;
