import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useContext,
} from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Form,
  Row,
  Col,
  Dropdown,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { IcoFilter } from "@icons";
import { getProjectResumeInvoices, getOEChecklistQuestions } from "@lib/api";
import Select from "react-select";
import { translate } from "components/translation/translation";
import { OETrans } from "components/translation/OETrans";
import { LevelContext } from "components/contexts/LevelContext";

const CustomMenu = ({
  selectedColumn,
  columns,
  onChange,
  setSelectedColumn,
  searchValue,
  selectedColor,
  optionsColors,
  setSelectedColor,
  optionsQuestions,
  selectedQuestion,
  setSelectedQuestion,
  optionsAnswers,
  selectedAnswer,
  setSelectedAnswer,
  optionsSearchType,
  selectedSearchType,
  setSelectedSearchType,
  optionsYesNoPartial,
  optionsOkWarning,
  selectedFirstDate,
  setSelectedFirstDate,
  selectedSecondDate,
  setSelectedSecondDate,
  selectedHasDocument,
  setSelectedHasDocument,
  isPendingReset,
  isPendingFilter,
  tableRef,
  handleReset,
  handleFilterSearch,
}) => {
  let options = [];

  switch (selectedColumn.value) {
    case "supplier_name":
    case "taxable_amount":
    case "total_cost":
    case "internal_taxonomy":
      options = optionsSearchType.filter((opt) => opt.value === 1);
      break;
    case "reported":
      options = optionsYesNoPartial;
      break;
    case "reportable":
      options = optionsYesNoPartial.filter((opt) => opt.value !== "partial");
      break;
    case "annullamento":
    case "fattura":
    case "inerenza":
    case "quietanza":
    case "ammissibilita":
      options = optionsSearchType.filter((opt) => opt.value === 2);
      break;
    case "pubblicita":
    case "dnsh":
      options = optionsOkWarning;
      break;
    case "date":
      options = optionsSearchType.filter((opt) => opt.value === 4);
      break;
    case "contract_number":
    case "rp_code":
      options = optionsSearchType.filter((opt) => opt.value !== 4);
      break;
    default:
      options = optionsSearchType.filter((opt) => opt.value !== 4 && opt.value !== 5);
      break;
  }

  return (
    <>
      <div ref={useRef()} style={{ minWidth: "50rem" }}>
        <div /* className='filter-box' */>
          <Form>
            {/* filter-box: stile per più filtri */}
            <Row className="mt-2">
              <Col
                className={
                  selectedSearchType.value === 1 ||
                  selectedSearchType.value === 2 ||
                  selectedSearchType.value === 5
                    ? "col-4"
                    : "col-7"
                }
              >
                <Form.Group as={Col} className="px-2">
                  <Form.Label className="mb-0"><OETrans code='common.column' fallback={'Colonna'}/></Form.Label>
                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="column"
                    placeholder={translate('common.placeholder.select', 'Seleziona...')}
                    value={selectedColumn}
                    options={columns}
                    onChange={(e) => onChange(e, "column", setSelectedColumn)}
                  />
                </Form.Group>
              </Col>
              <Col
                className={
                  selectedSearchType.value === 1 ||
                  selectedSearchType.value === 2 ||
                  selectedSearchType.value === 5
                    ? "col-4"
                    : "col-5"
                }
              >
                <Form.Group as={Col} className="px-2">
                  <Form.Label className="mb-0">Tipo di ricerca</Form.Label>
                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="searchType"
                    placeholder={translate('common.placeholder.select', 'Seleziona...')}
                    value={selectedSearchType}
                    options={options}
                    onChange={(e) =>
                      onChange(e, "searchType", setSelectedSearchType)
                    }
                    isDisabled={!selectedColumn.value}
                  />
                </Form.Group>
              </Col>

              {selectedSearchType.value === 1 && (
                <Col className="col-4">
                  <Form.Group as={Col} className="px-2">
                    <Form.Label className="mb-0">Valore</Form.Label>
                    <FormControl
                      name="value"
                      type={
                        selectedColumn.typeOf === "number" ? "number" : "text"
                      }
                      className="filter-text-box"
                      placeholder={translate('common.search', 'Cerca') + '...'}
                      value={searchValue}
                      onChange={(e) => onChange(e, "value")}
                      style={{ height: "2.375rem" }}
                      disabled={!selectedColumn.value}
                    />
                  </Form.Group>
                </Col>
              )}

              {selectedSearchType.value === 2 && (
                <Col className="col-4">
                  <Form.Group as={Col} className="px-2">
                    <Form.Label className="mb-0">Semaforo</Form.Label>
                    <Select
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="color"
                      placeholder={translate('common.placeholder.select', 'Seleziona...')}
                      value={selectedColor}
                      options={optionsColors}
                      onChange={(e) => onChange(e, "color", setSelectedColor)}
                      isDisabled={!selectedColumn.value}
                    />
                  </Form.Group>
                </Col>
              )}

               {selectedSearchType.value === 5 && (
                <Col className="col-4">
                  <Form.Group as={Col} className="px-2">
                    <Form.Label className="mb-0">Presente</Form.Label>
                    <Select
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="document"
                      placeholder={translate('common.placeholder.select', 'Seleziona...')}
                      options={optionsYesNoPartial.filter((opt) => opt.value !== "partial")}
                      value={selectedHasDocument}
                      onChange={(e) => onChange(e, "document", setSelectedHasDocument)}
                      isDisabled={!selectedColumn.value}
                    />
                  </Form.Group>
                </Col>
              )}
            </Row>

            {selectedSearchType.value === 3 && (
              <Row className="mt-3">
                <Col className="col-7">
                  <Form.Group as={Col} className="px-2">
                    <Form.Label className="mb-0">Domanda</Form.Label>
                    <Select
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="question"
                      placeholder={translate('common.placeholder.select', 'Seleziona...')}
                      value={selectedQuestion}
                      options={optionsQuestions}
                      onChange={(e) =>
                        onChange(e, "question", setSelectedQuestion)
                      }
                      isDisabled={!selectedColumn.value}
                    />
                  </Form.Group>
                </Col>
                <Col className="col-1 mt-4 align-self-center text-center mb-0 form-label">
                  con
                </Col>
                <Col className="col-4">
                  <Form.Group as={Col} className="px-2">
                    <Form.Label className="mb-0">Risposta</Form.Label>
                    <Select
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="answer"
                      placeholder={translate('common.placeholder.select', 'Seleziona...')}
                      value={selectedAnswer}
                      options={optionsAnswers}
                      onChange={(e) => onChange(e, "answer", setSelectedAnswer)}
                      isDisabled={!selectedColumn.value}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            {selectedSearchType.value === 4 && (
              <Row className="mt-3">
               <Col className="col-1 mt-4 align-self-center text-center mb-0 form-label">
                  Da
                </Col>
                <Col className="col-5">
                  <Form.Group as={Col} className="px-2">
                    <Form.Label className="mb-0"/>
                    <Form.Control
                        type='date'
                        value={selectedFirstDate}
                        onChange={(e) => onChange(e, 'date', setSelectedFirstDate)}
                        placeholder={translate('common.placeholder.select', 'Seleziona...')}
                        disabled={!selectedColumn.value}
                    />
                  </Form.Group>
                </Col>
                <Col className="col-1 mt-4 align-self-center text-center mb-0 form-label">
                  a
                </Col>
                <Col className="col-5">
                  <Form.Group as={Col} className="px-2">
                    <Form.Label className="mb-0"/>
                    <Form.Control
                        type='date'
                        value={selectedSecondDate}
                        onChange={(e) => onChange(e, 'date', setSelectedSecondDate)}
                        placeholder={translate('common.placeholder.select', 'Seleziona...')}
                        disabled={!selectedColumn.value}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
          </Form>
        </div>

        <Row className="justify-content-end px-2 mt-4 mb-2">
          <Col className="col-auto">
            <Button variant="secondary" onClick={() => handleReset()}>
              Azzera{" "}
              {isPendingReset && (
                <Spinner animation="border" role="status" size="sm"></Spinner>
              )}
            </Button>
          </Col>
          <Col className="col-auto">
            <Button
              variant="primary"
              className="w-100"
              onClick={(e) => handleFilterSearch(e, tableRef)}
            >
              Applica{" "}
              {isPendingFilter && (
                <Spinner animation="border" role="status" size="sm"></Spinner>
              )}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

const OEFilterExternalSupplies = forwardRef(
  (
    {
      children,
      columnDefs,
      projectId,
      setTable,
      setTableMeta,
      isFiltered,
      setIsFiltered,
      setFilteredTotal,
      handleClick,
      setFilterQueryParamsString,
      onFilterChanged,
      tableRef,
    },
    ref
  ) => {
    const { userRole} = useContext(LevelContext);
    const [columns, setColumns] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [selectedColumn, setSelectedColumn] = useState([]);
    const [optionsSearchType, setOptionsSearchType] = useState([]);
    const [selectedSearchType, setSelectedSearchType] = useState([]);
    const [selectedColor, setSelectedColor] = useState([]);
    const [optionsColors, setOptionsColors] = useState([]);
    const [optionsQuestions, setOptionsQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState([]);
    const [optionsAnswers, setOptionsAnswers] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState([]);
    const [optionsYesNoPartial, setOptionsYesNoPartial] = useState([]);
    const [optionsOkWarning, setOptionsOkWarning] = useState([]);
    const [selectedFirstDate, setSelectedFirstDate] = useState('');
    const [selectedSecondDate, setSelectedSecondDate] = useState('');
    const [selectedHasDocument, setSelectedHasDocument] = useState([]);
    const [, /* isOpen */ setIsOpen] = useState(false);
    const [isPendingReset, setIsPendingReset] = useState(false);
    const [isPendingFilter, setIsPendingFilter] = useState(false);

   const entityObj = {
      tender_number: {
        entityParam: "tenders",
        colorParam: "tenders_color",
        entityQuestParam: "tenders",
        questionParam: "tenders_question_code",
        queryParam: "entity_type",
      },
      framework_contract_number: {
        entityParam: "frameworkcontracts",
        colorParam: "frameworkcontracts_color",
        entityQuestParam: "frameworkContracts",
        questionParam: "frameworkcontracts_question_code",
        queryParam: "entity_type",
      },
      contract_number: {
        entityParam: "contracts",
        colorParam: "contracts_color",
        entityQuestParam: "contracts",
        questionParam: "contracts_question_code",
        queryParam: `${selectedSearchType?.value === 5 ? 'contract_has_document' : 'entity_type'}`,
      },
      rp_code: {
        entityParam: "invoices",
        colorParam: "invoices_color",
        entityQuestParam: "invoices",
        questionParam: "invoices_question_code",
        queryParam: `${selectedSearchType?.value === 5 ? 'invoice_has_document' : 'entity_type'}`,
      },
      payment_number: {
        entityParam: "payments",
        colorParam: "invoicepayments_color",
        entityQuestParam: "invoice_payment",
        questionParam: "invoicepayments_question_code",
        queryParam: "entity_type",
      },
      annullamento: {
        entityParam: "ANNULLAMENTO",
        colorParam: "section_color",
        queryParam: "section_code",
      },
      fattura: {
        entityParam: "FATTURA",
        colorParam: "section_color",
        queryParam: "section_code",
      },
      inerenza: {
        entityParam: "INERENZA",
        colorParam: "section_color",
        queryParam: "section_code",
      },
      quietanza: {
        entityParam: "QUIETANZA",
        colorParam: "section_color",
        queryParam: "section_code",
      },
      ammissibilita: {
        entityParam: "AMMISSIBILITA",
        colorParam: "section_color",
        queryParam: "section_code",
      },
      pubblicita: {
         queryParam: "advcheck",
      },
      dnsh: {
         queryParam: "dnshcheck",
      },
   };

    const renderChildren = useCallback((columns) => {
      if (Array.isArray(columns) && columns.length > 0) {
        columns.forEach((col) => {
          Array.isArray(col.children)
            ? renderChildren(col.children)
            : setColumns((prevState) => [
                ...prevState,
                ...[
                  {
                    value: col.cellRendererParams?.queryParam,
                    label:
                      col.field === "reported"
                        ? "Rendicontato"
                        : col.field === "reportable"
                        ? "Eleggibile"
                        : col.headerName,
                    typeOf: col.cellRendererParams?.typeOf,
                    colId: col.field,
                  },
                ],
              ]);
        });
      }
    }, []);

    useEffect(() => {
      setOptionsColors([
        {
          value: "green",
          label: "Verde",
        },
        {
          value: "green_white",
          label: "Verde Rec.",
        },
        {
         value: "green_white_settled",
         label: "Verde Rec. Sanato",
       },
        {
          value: "yellow",
          label: "Giallo",
        },
        {
          value: "red",
          label: "Rosso",
        },
        {
          value: "gray",
          label: "Grigio",
        },
        {
          value: "white",
          label: "Bianco",
        },
      ]);

      setOptionsAnswers([
        {
          value: "yes",
          label: translate('common.yes', 'Sì'),
        },
        {
          value: "no",
          label: translate('common.no', 'No'),
        },
        {
          value: "na",
          label: "N.A.",
        },
        {
          value: "nd",
          label: "N.D.",
        },
        {
          value: "rec",
          label: "Rec.",
        },
      ]);

      setOptionsSearchType([
         { value: 1, label: "testo" },
         ...(userRole < 70 ? [{ value: 2, label: "per semaforo" }] : []),
         ...(userRole < 70 ? [{ value: 3, label: "per controllo" }] : []),
         { value: 4, label: "per data" },
         { value: 5, label: "per documento" },
      ]);

      setOptionsYesNoPartial([
        { value: true, label: translate('common.yes', 'Sì') },
        { value: false, label: "No" },
        { value: "partial", label: "Parziale" },
      ]);

      setOptionsOkWarning([
         { value: false, label: 'OK' },
         { value: true, label: "Warning" },
       ]);
    }, [userRole]);

    /* useEffect(() => {
        if (!isOpen) {
           setSearchValue('');
           setSelectedColor([]);
           setSelectedQuestion([]);
           setSelectedAnswer([]);
        }
     }, [isOpen]); */

    useEffect(() => {
      renderChildren(columnDefs);
    }, [columnDefs, renderChildren]);

    const changeOptionsQuestions = useCallback(() => {
      if (
        selectedSearchType.value === 3 &&
        selectedColumn.value &&
        (selectedColumn.value === "tender_number" ||
          selectedColumn.value === "framework_contract_number" ||
          selectedColumn.value === "contract_number" ||
          selectedColumn.value === "rp_code" ||
          selectedColumn.value === "payment_number")
      ) {
        getOEChecklistQuestions(
          entityObj[selectedColumn.value].entityQuestParam
        ).then((retrievedQuestions) => {
          if (retrievedQuestions.success) {
            setOptionsQuestions(
              retrievedQuestions.data
                .sort(
                  (valueA, valueB) =>
                    valueA.sectionNumber - valueB.sectionNumber ||
                    valueA.number - valueB.number
                )
                .map((quest) => ({
                  value: quest.code,
                  label: `${quest.code} - ${quest.questions}`,
                }))
            );
          }
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedColumn.value, selectedSearchType.value]);

    useEffect(() => {
      changeOptionsQuestions();
    }, [changeOptionsQuestions, selectedColumn]);

    const onChange = (e, fieldName, setSelect) => {
      //console.log('e', e);
      let value;

      switch (fieldName) {
        case "column":
          value = e.value ?? [];

          setSelect(e);
          setSelectedSearchType([]);
          setSearchValue("");
          setSelectedHasDocument([]);
          break;
        case "searchType":
          value = e.value ?? [];

          setSelect(e);
          setSearchValue(e.value === true || e.value === false ? e.value : "");
          setSelectedHasDocument([]);
          break;
        case "value":
          value = e.target.value.trim();

          setSearchValue(value);
          if (selectedColor.value) setSelectedColor([]);
          if (selectedQuestion.value) setSelectedQuestion([]);
          if (selectedAnswer.value) setSelectedAnswer([]);
          if (selectedHasDocument.value) setSelectedHasDocument([]);
          break;
        case "color":
          value = e.value ?? [];

          setSelect(e);
          setSearchValue("");
          setSelectedQuestion([]);
          setSelectedAnswer([]);
          break;
        case "question":
        case "answer":
          value = e.value ?? [];

          setSelect(e);
          setSearchValue("");
          setSelectedColor([]);
          break;
        case "date":
          value = e.target.value;

          setSelect(value);
          break;
        case "document":
          value = e;

          setSelect(value);
          break;
        default:
          break;
      }
    };

    const handleFilterSearch = async (e) => {
      //e?.preventDefault();
      setIsPendingFilter(true);

      let queryParamsString;

      if (
        (selectedColumn.value !== "pubblicita" && selectedColumn.value !== "dnsh") &&
        (selectedSearchType.value === 1 ||
        selectedSearchType.value === true ||
        selectedSearchType.value === false)
      ) {
        //testo
        queryParamsString = `?${selectedColumn.value}=${searchValue}`;
      } else if (selectedSearchType.value === "partial") {
        //semaforo parziale
        queryParamsString = `?reporting=true`;
      } else if (selectedColumn.value === "pubblicita" || selectedColumn.value === "dnsh") {
         //pubblicita o dnsh
         queryParamsString = `?${entityObj[selectedColumn.value].queryParam}=${selectedSearchType.value === true ? 1 : 0}`;
       } else if (selectedSearchType.value === 2) {
        //semaforo
        queryParamsString = `?${entityObj[selectedColumn.value].queryParam}=${
          entityObj[selectedColumn.value].entityParam
        }&${entityObj[selectedColumn.value].colorParam}=${selectedColor.value}`;
      } else if (selectedSearchType.value === 3) {
        //domanda/risposta
        queryParamsString = `?${entityObj[selectedColumn.value].queryParam}=${
          entityObj[selectedColumn.value].entityParam
        }&${entityObj[selectedColumn.value].questionParam}=${
          selectedQuestion.value
        }&${entityObj[selectedColumn.value].questionParam}_${
          selectedAnswer.value
        }=1`;
      } else if (selectedSearchType.value === 4) {
         //data
         queryParamsString = `?${selectedFirstDate ? `${selectedColumn.value}To=${selectedFirstDate}` : ''}${selectedSecondDate ? `${selectedFirstDate ? '&' : ''}${selectedColumn.value}To=${selectedSecondDate}` : ''}`;
      } else if (selectedSearchType.value === 5) {
         //hasdocument
         queryParamsString = `?${entityObj[selectedColumn.value].queryParam}=${selectedHasDocument.value === true ? 1 : 0}`;
      }

      const result = await getProjectResumeInvoices(
        projectId,
        queryParamsString
      );

      if (result.data) {
        setTable(result.data);
        const { data, success, ...restResp } = result;
        setTableMeta(restResp);
        setFilteredTotal(result.totals);
        setIsFiltered(true);
        if (setFilterQueryParamsString)
          setFilterQueryParamsString(queryParamsString);
        if (onFilterChanged)
          onFilterChanged(tableRef ? tableRef.current : ref, true);
      }

      setIsPendingFilter(false);

      if (Object.keys(selectedColumn).length > 0) {
        const allHeaders = document.getElementsByClassName("ag-header-cell");

        Array.from(allHeaders).forEach((el) => {
          el.classList.remove("ag-header-cell-filtered");
        });

        /* const elementSelected = document.querySelector(`[col-id=${selectedColumn.colId}]`);
            elementSelected.classList.add("ag-header-cell-filtered"); */
      }
    };

    useImperativeHandle(ref, () => ({
      async handleReset(e) {
        e?.preventDefault();

        setIsPendingReset(true);
        setSelectedColumn([]);
        setSelectedSearchType([]);
        setSearchValue("");
        setSelectedColor([]);
        setSelectedQuestion([]);
        setSelectedAnswer([]);
        setSelectedHasDocument([]);

        const result = await getProjectResumeInvoices(projectId);

        if (result.data) {
          setTable(result.data);
          const { data, success, ...restResp } = result;
          setTableMeta(restResp);
          setIsFiltered(false);
          if (setFilterQueryParamsString) setFilterQueryParamsString("");
        }

        setIsPendingReset(false);

        const allHeaders = document.getElementsByClassName("ag-header-cell");

        Array.from(allHeaders).forEach((el) => {
          el.classList.remove("ag-header-cell-filtered");
        });
      },
    }));

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <>
        <OverlayTrigger placement="top" overlay={<Tooltip>Filtri</Tooltip>}>
          <Button
            style={{ padding: "0.2rem" }}
            variant={isFiltered ? "info" : "primary"}
            onClick={(e) => {
              onClick(e);
            }}
          >
            <IcoFilter />
          </Button>
        </OverlayTrigger>

        {/* <a href="/" ref={ref} onClick={(e) => {e.preventDefault(); onClick(e);}}>
            {children}
         </a> */}
      </>
    ));

    return (
      <Dropdown
        className="me-2"
        align="end"
        autoClose="outside"
        onToggle={(e) => setIsOpen(e)}
      >
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {/* children */}
        </Dropdown.Toggle>

        <Dropdown.Menu
          /* as={CustomMenu} */ className="filter-table to-left p-3"
        >
          <CustomMenu
            columns={columns}
            selectedColumn={selectedColumn}
            setSelectedColumn={setSelectedColumn}
            searchValue={searchValue}
            optionsColors={optionsColors}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            optionsQuestions={optionsQuestions}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            optionsAnswers={optionsAnswers}
            selectedAnswer={selectedAnswer}
            setSelectedAnswer={setSelectedAnswer}
            optionsSearchType={optionsSearchType}
            selectedSearchType={selectedSearchType}
            setSelectedSearchType={setSelectedSearchType}
            optionsYesNoPartial={optionsYesNoPartial}
            optionsOkWarning={optionsOkWarning}
            selectedFirstDate={selectedFirstDate}
            setSelectedFirstDate={setSelectedFirstDate}
            selectedSecondDate={selectedSecondDate}
            setSelectedSecondDate={setSelectedSecondDate}
            selectedHasDocument={selectedHasDocument}
            setSelectedHasDocument={setSelectedHasDocument}
            isPendingReset={isPendingReset}
            isPendingFilter={isPendingFilter}
            onChange={onChange}
            handleReset={handleClick}
            handleFilterSearch={handleFilterSearch}
            tableRef={tableRef}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);

export default OEFilterExternalSupplies;
